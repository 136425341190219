export const formatUniMMR = (input: string): string => {
  const isAllNines = /^9{6,}$/.test(input)

  if (isAllNines) {
    return '--'
  }

  const numberValue = parseFloat(input)

  if (isNaN(numberValue)) {
    return '0'
  }

  return numberValue.toFixed(2).replace('.', ',')
}

export const formatUpnlValue = (value: number | undefined | string) => {
  const formattedValue = Number(value ?? 0).toFixed(2)
  return formattedValue === '-0.00' ? '0.00' : formattedValue
}
