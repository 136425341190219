import { TypeUNION } from '@/backend/api/leaderboard.api'
import { FieldErrorType } from '@/types'

export enum TypeTrade {
  SPOT = 'Spot',
  FEATURE = 'Feature',
  PNL = 'Pnl',
}

export enum TypePeriod {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
}

export type PeriodUNION = TypePeriod.TODAY | TypePeriod.WEEK | TypePeriod.MONTH

export type InitialState = {
  spotLeaders: any
  pnlLeaders: any
  futureLeaders: any
  isLoading: boolean
  period: PeriodUNION
  typeResult: TypeUNION
  errors: any
}
