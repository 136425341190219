import {
  E_TEAM_USER_MODE,
  TManagerMemberListEntity,
  TManagerRequestEntity,
  TTeamInfo,
  TTeamManagerInfo,
  TTeamUserInfo,
  TeamMembersCashbackEditPayload,
} from '@/redux/team/team.types'
import { axiosMainService } from '@/services/axios'
import { ITeamFormSubscription } from '@/ui/molecules/TeamForm/TeamForm.Types'
import { ErrorCode } from '../models/ErrorCodesDTO'
import { enqueueSnackbar } from 'notistack'
import { FieldErrorType } from '@/types'

const cashbackAPIURL = 'cashback/protected/api'

export const CashbackAPI = {
  async getCashback() {
    return await axiosMainService.get<CashbackType>(`${cashbackAPIURL}/v1/cashback`)
  },
  async getCashbackTermsApi() {
    return await axiosMainService.get<CashbackTermsType[]>(`${cashbackAPIURL}/v1/cashback/terms`)
  },
  async getCashbackAllTermsApi() {
    return await axiosMainService.get<CashbackTermsType[]>(`${cashbackAPIURL}/v1/cashback/terms/all`)
  },
  async getCashbackCalculationTimeApi() {
    return await axiosMainService.get<CashbackCalculatedTimeDTO>(`${cashbackAPIURL}/v1/cashback/calculated`)
  },
  async getAllocations() {
    return await axiosMainService.get<ICashbackAllocationsDTO>(`${cashbackAPIURL}/v1/cashback/allocated`)
  },
  async getRequestedTeam(teamCode: string) {
    return await axiosMainService.get<TTeamInfo>(`${cashbackAPIURL}/v1/team/${teamCode}/info`)
  },
  async getUserTeamInfo() {
    return await axiosMainService.get<TTeamUserInfoDTO>(`${cashbackAPIURL}/v1/team/info`)
  },

  async getManagerRequests({ page, size }: TeamPayload) {
    return await axiosMainService.get<TManagerRequestEntity[]>(
      `${cashbackAPIURL}/v1/team/managing/requests?page=${page}&size=${size}`
    )
  },

  async getManagerMembers({ page, size, search }: TeamPayload) {
    return await axiosMainService.get<TManagerMemberListEntity[]>(
      `${cashbackAPIURL}/v1/team/managing/members?page=${page}&size=${size}&search=${search ?? ''}`
    )
  },

  async sendManagerRequest({ requestId, action }: TTeamManagerActionDTO) {
    return await axiosMainService.post<TManagerMemberListEntity[]>(
      `${cashbackAPIURL}/v1/team/managing/requests/${requestId}`,
      {
        action,
      }
    )
  },
  async postTeamJoin(payload: TTeamJoinDTO) {
    return await axiosMainService.post(`${cashbackAPIURL}/v1/team/join`, payload)
  },
  async deleteTeamJoin() {
    return await axiosMainService.delete(`${cashbackAPIURL}/v1/team/request`)
  },
  async changeMemberCashback({
    userId,
    binanceCashbackPercent,
    bybitCashbackPercent,
    okxCashbackPercent,
    label,
  }: TeamMembersCashbackEditPayload) {
    const sentData = {
      binanceCashbackPercent,
      bybitCashbackPercent,
      okxCashbackPercent,
      label,
    }
    try {
      return await axiosMainService.put(`${cashbackAPIURL}/v1/team/managing/members/${userId}`, {
        ...sentData,
      })
    } catch (e: any) {
      const { code, status, details }: { code: ErrorCode; status: number; details: FieldErrorType[] } = e.response.data
      const CASHBACK_PERCENT_ERROR_CODE = '40208'
      const isErrorWithConstantCode = details.some(error => error.code === CASHBACK_PERCENT_ERROR_CODE)
      if (!isErrorWithConstantCode && (code || status)) {
        enqueueSnackbar(`Error ${code || status}`, { variant: 'error' })
        throw e
      } else {
        throw e
      }
    }
  },
  async postTeamLeave() {
    return await axiosMainService.post(`${cashbackAPIURL}/v1/team/leave`)
  },

  async removeMember(userId: string) {
    try {
      return await axiosMainService.delete(`${cashbackAPIURL}/v1/team/managing/members/${userId}`)
    } catch (e: any) {
      const { code, status }: { code: ErrorCode; status: number } = e.response.data
      if (code || status) {
        enqueueSnackbar(`Error ${code || status}`, { variant: 'error' })
        throw e
      } else {
        enqueueSnackbar(`Error`, { variant: 'error' })
        throw e
      }
    }
  },
  async getMemberCashback(userId: string) {
    try {
      return await axiosMainService.get(`${cashbackAPIURL}/v1/team/managing/members/${userId}/cashback`)
    } catch (e: any) {
      const { code, status }: { code: ErrorCode; status: number } = e.response.data
      if (code || status) {
        enqueueSnackbar(`Error ${code || status}`, { variant: 'error' })
        throw e
      } else {
        enqueueSnackbar(`Error`, { variant: 'error' })
        throw e
      }
    }
  },

  async changeTeamManagerDefaultCashback(payload: TeamMembersCashbackEditPayload) {
    try {
      return await axiosMainService.put(`${cashbackAPIURL}/v1/team/managing/cashback`, {
        ...payload,
      })
    } catch (e: any) {
      const { code, status, details }: { code: ErrorCode; status: number; details: FieldErrorType[] } = e.response.data
      const CASHBACK_PERCENT_ERROR_CODE = '40208'
      const isErrorWithConstantCode = details?.some(error => error.code === CASHBACK_PERCENT_ERROR_CODE)
      if (isErrorWithConstantCode && (code || status)) {
        enqueueSnackbar(`Error ${code || status}`, { variant: 'error' })
        throw e
      } else {
        enqueueSnackbar(`Error`, { variant: 'error' })
        throw e
      }
    }
  },
}

export enum ECashbackCalculationRule {
  'priorityReferralCode' = 'priorityReferralCode',
  'tradeVolume' = 'tradeVolume',
  'allocationBalance' = 'allocationBalance',
  'customReferralCode' = 'customReferralCode',
  'promocode' = 'promocode',
  'teamMembership' = 'teamMembership',
  'userReferralCode' = 'userReferralCode',
  'bybitPro' = 'bybitPro',
  'tigerXFixed' = 'tigerXFixed',
}

export enum ERequestHandle {
  APPROVE = 'approve',
  REJECT = 'reject',
}

export type CashbackType = {
  calculationRule: ECashbackCalculationRule
  cashbackPercentToday: number
  cashbackToday: number
  commissionToday: number
  nextCashbackPercent?: number
  nextVolume?: number
  volumeLast30Days: number
  volumeToday: number
  expirationDate?: string
}
export type CashbackTermsType = {
  binanceCashbackPercent: string
  cashbackPercent: string
  fromInclusive: string
  toExclusive?: string
  calculationRule: ECashbackCalculationRule
}

export type CashbackCalculatedTimeDTO = {
  nextCalculationTime: string
  prevCalculationTime: string
}

export interface ICashbackAllocationsDTO {
  totalBalance: number
}

export type TTeamUserInfoDTO = {
  statisticId: number
  mode: E_TEAM_USER_MODE
  data: TTeamUserInfo | TTeamManagerInfo
}

export type TTeamManagerMembersDTO = {
  members: TManagerMemberListEntity[]
  page: number
  total: number
  size: number
  managerDataReachedEnd: boolean
}

export type TTeamManagerRequestsDTO = {
  requests: TManagerRequestEntity[]
  page: number
  total: number
  size: number
  managerDataReachedEnd: boolean
}

export type TTeamManagerActionDTO = {
  requestId: string
  action: ERequestHandle
}

export type TeamManagerRequestReturnedDTO = {
  requestId: string
  actionApplied: ERequestHandle
}

export type TTeamJoinDTO = ITeamFormSubscription

export type TeamPayload = {
  page: number
  size: number
  search?: string
}
