import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v1 } from 'uuid'
import { SVG } from '@/assets/svg'
import { TransactionAssets } from '@/backend/api'
import { transactionsAsyncActions } from '@/redux/transactions/transactions.actions'
import { transactionsActions } from '@/redux/transactions/transactions.slice'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import {
  instrumentNames,
  checkIsDecimalsQuantityCorrect,
  formatNumber,
  getCompoundTransactionAmount,
  useActions,
  useAppDispatch,
  useAppSelector,
  useMobileSizeDetect,
} from '@/utils'
import {
  Button,
  ButtonSize,
  ButtonVariant,
  ErrorView,
  Input,
  InputSize,
  InteractiveModal,
  InteractiveModalParts,
  Select,
  SelectOption,
} from '@/ui/kit'
import { NoCoins } from '@/ui/molecules'
import clsx from 'clsx'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { NetworkSelectOption } from '@/ui/atoms/NetworkSelectOption'
import { CoinSelectOption } from '@/ui/atoms/CoinSelectOption'
import { urls } from '@/router/urls'
import { useScrollToError } from '@/utils/hooks'
import { ETigerXAccount, ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { useExchageType } from '@/utils/hooks/useExchangeType'

const accountToExchange = {
  TIGER_X_BINANCE: 'BINANCE',
  TIGER_X_OKX: 'OKX',
}

export const TransferTransactionTigerX = memo(() => {
  const [isMobile] = useMobileSizeDetect()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { assetsForTransactions, transactionSendResult, assetsForWithdrawAndDeposit, coinBalance, transferData } =
    useAppSelector(state => state.transactions)
  const { tigerTradeId } = useAppSelector(state => state.profile)
  const scrollToError = useScrollToError()
  const exchangeType = useExchageType()

  const [accountFrom, setAccountFrom] = useState<ETigerXAccount>(ETigerXAccount.TIGER_X_BINANCE)
  const [accountTo, setAccountTo] = useState<ETigerXAccount>(ETigerXAccount.TIGER_X_OKX)
  const [transactionId, setTransactionId] = useState('')
  const [isTransferAttemptDetected, setIsTransferAttemptDetected] = useState(false)

  const roundDown = (num: number, decimals: number) => {
    const factor = Math.pow(10, decimals)
    return Math.floor(num * factor) / factor
  }

  const accountConfig = useMemo<AccountConfig>(() => {
    // Получаем список активов, разрешенных для вывода
    const allowedAssets = Object.keys(assetsForWithdrawAndDeposit.assets)

    // Функция для получения данных о комиссии и минимальном переводе из transfersData
    const getTransferData = (coin: string, exchange: string) => {
      const transferInfo = transferData.find(item => item.coin === coin && item.subExchangeType === exchange)

      if (!transferInfo || !transferInfo.transfers.length) return { transferFee: null, transferMin: null }

      // Берём первый элемент из transfers (или можно выбрать логику, если там несколько)
      const { transferFee, transferMin, transferIntegerMultiple } = transferInfo.transfers[0]

      return {
        transferFee: transferFee ?? null,
        transferMin: transferMin ?? null,
        transferIntegerMultiple: transferIntegerMultiple ?? null,
      }
    }

    // Фильтруем и форматируем балансы для каждой биржи
    const formatAssets = (exchange: string) =>
      (coinBalance.balanceAssets as BalanceAsset[])
        .filter(asset => asset.subExchange === exchange && allowedAssets.includes(asset.asset)) // Фильтруем только разрешенные ассеты
        .map(asset => {
          const transferData = getTransferData(asset.asset, exchange)

          const fixedDecimal = transferData?.transferIntegerMultiple ? transferData?.transferIntegerMultiple : 6

          return {
            name: asset.asset,
            balance: roundDown(asset.balance, fixedDecimal),
            balanceFormatted: roundDown(asset.balance, fixedDecimal).toString(),
            transferFee: transferData?.transferFee ?? null,
            transferMin: transferData?.transferMin ?? null,
            transferIntegerMultiple: transferData?.transferIntegerMultiple ?? null,
          }
        })
    // Получаем активы для Binance и OKX
    const binanceAssets = formatAssets('BINANCE')
    const okxAssets = formatAssets('OKX')

    // Вычисляем сумму активов для каждого аккаунта
    const getTotalBalance = (assets: { balance: number }[], decimals: number = 6): string =>
      roundDown(
        assets.reduce((sum, asset) => sum + asset.balance, 0),
        decimals
      ).toFixed(decimals)

    const binanceTotalBalance = getTotalBalance(binanceAssets)
    const okxTotalBalance = getTotalBalance(okxAssets)

    return {
      TIGER_X_BINANCE: {
        name: instrumentNames[ETigerXInstruments.BINANCE_X].name,
        assetsOptions: binanceAssets,
        prefix: binanceTotalBalance,
        allowedAccountsToTransfer: [ETigerXAccount.TIGER_X_BINANCE],
      },
      TIGER_X_OKX: {
        name: instrumentNames[ETigerXInstruments.OKX_X].name,
        assetsOptions: okxAssets,
        prefix: okxTotalBalance,
        allowedAccountsToTransfer: [ETigerXAccount.TIGER_X_OKX],
      },
    } as AccountConfig
  }, [assetsForTransactions])

  const options = useMemo<SelectOption<ETigerXAccount>[]>(() => {
    return Object.entries(accountConfig)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => {
        const typedKey = key as ETigerXAccount
        const item = value as AccountItem

        return {
          value: typedKey,
          label: <NetworkSelectOption symbol={item.prefix} name={item.name} />,
          selected: item.name,
        }
      })
  }, [accountConfig])

  const optionsForAccountTo = useMemo(() => {
    if (!isExchangeBinance(exchangeType)) {
      return options
    }

    return getOptionsForAccountTo(options, accountConfig, accountFrom)
  }, [accountFrom, accountConfig, options])

  const [amount, setAmount] = useState<string>('')
  const [selectedAsset, setSelectedAsset] = useState<null | Asset | any>(null)

  const changeSelectedAsset = useCallback(
    (value: string) => {
      const selectedAssetNew = accountConfig[accountFrom]?.assetsOptions.find(item => item.name === value)

      if (selectedAssetNew) {
        setSelectedAsset({
          asset: selectedAssetNew.name,
          balance: selectedAssetNew.balance,
          balanceFormatted: selectedAssetNew.balanceFormatted,
          transferFee: selectedAssetNew.transferFee ?? null,
          transferMin: selectedAssetNew.transferMin ?? null,
          transferIntegerMultiple: selectedAssetNew.transferIntegerMultiple ?? null,
        })
        setAmount('')
      }
    },
    [accountConfig, accountFrom]
  )

  const [isModalOpen, setIsOpenModal] = useState(false)

  const { TransferAssetCryptoTC } = useActions(transactionsAsyncActions)

  const toggleAccounts = useCallback(() => {
    setAccountFrom(accountTo)
    setAccountTo(accountFrom)
    setAmount('')
    setSelectedAsset(null)
  }, [accountFrom, accountTo])

  const getErrorMessage = () => {
    if (accountFrom === accountTo) {
      return t({ message: `Source and destination accounts are equal`, id: 'transferTransaction.messages.source' })
    }

    if (!selectedAsset?.asset) {
      return t({ message: `No coin selected`, id: 'transferTransaction.messages.noCoinsSelected' })
    }

    if (amount === '') {
      return t({ message: 'Enter amount', id: 'transferTransaction.messages.noCoinsSelect' })
    }

    if (Number(amount) <= 0) {
      return t({ message: `Incorrect amount`, id: 'transferTransaction.messages.inccorectAmount' })
    }

    if (Number(amount) > selectedAsset.balance) {
      return t({ message: 'Insufficient funds in the account', id: 'transferTransaction.messages.insufFunds' })
    }

    if (!checkIsDecimalsQuantityCorrect(amount, maximumDecimalsQuantity)) {
      return `${t({
        message: 'Only',
        id: 'transferTransaction.messages.onlyDec-1',
      })} ${maximumDecimalsQuantity} ${t({
        message: 'decimal places are allowed',
        id: 'transferTransaction.messages.onlyDec-2',
      })}`
    }

    return ''
  }

  const errorMessage = getErrorMessage()
  const isTransferAvailable = errorMessage === ''

  const transferAsset = useCallback(() => {
    setIsTransferAttemptDetected(true)

    if (!isTransferAvailable || selectedAsset === null) {
      scrollToError()
      return
    }

    const transactionId = v1()
    dispatch(transactionsActions.initTransaction(transactionId))
    setTransactionId(transactionId)

    // Обрезаем amount до 6 знаков после запятой
    const formattedAmount = roundDown(Number(amount), 6)

    TransferAssetCryptoTC({
      fromSubExchange: accountToExchange[accountFrom],
      toSubExchange: accountToExchange[accountTo],
      amount: formattedAmount,
      coin: selectedAsset?.asset || '',
      transactionId,
    })

    setIsOpenModal(true)
    scrollToError()
  }, [
    TransferAssetCryptoTC,
    accountFrom,
    accountTo,
    amount,
    dispatch,
    isTransferAvailable,
    selectedAsset,
    scrollToError,
  ])

  const fetchingError = assetsForTransactions.assets.errors?.[0]?.code

  const { status = 'loading', date } = transactionSendResult?.[transactionId] || {}

  const allowCoins = useMemo(() => {
    const coins: AssetOption[] = []

    // Получаем список активов, разрешенных для вывода
    const allowedAssets = Object.keys(assetsForWithdrawAndDeposit.assets)

    // Фильтруем балансы, оставляя только активы, которые есть в списке разрешенных
    const filteredBalances = coinBalance.balanceAssets.filter(
      asset => allowedAssets.includes(asset.asset) && asset.subExchange === accountToExchange[accountFrom]
    )

    filteredBalances.forEach(asset => {
      const fixedDecimal = asset?.transferIntegerMultiple ? asset?.transferIntegerMultiple : 6

      const balance = roundDown(Number(asset.balance), fixedDecimal)

      // Создаем объект для Select
      const selectOption: AssetOption = {
        value: asset.asset,
        name: asset.asset,
        label: <CoinSelectOption asset={asset.asset} balance={balance} />,
        selected: <CoinSelectOption asset={asset.asset} large />,
        balance: balance,
        balanceFormatted: roundDown(asset.balance, fixedDecimal).toString(),
        transferFee: asset.transferFee ?? null,
        transferMin: asset.transferMin ?? null,
        transferIntegerMultiple: asset.transferIntegerMultiple ?? null,
      }

      coins.push(selectOption)
    })

    return coins
  }, [accountConfig, accountFrom, accountTo])

  const handleSetMax = useCallback(() => {
    if (!selectedAsset) return

    const maxAmount = selectedAsset.balance

    setAmount(String(maxAmount > 0 ? maxAmount : 0))
  }, [selectedAsset])

  if (fetchingError) {
    return <ErrorView code={fetchingError} />
  }

  const isDisabledTransfer =
    selectedAsset === null ||
    selectedAsset.transferFee === null ||
    selectedAsset.transferMin === null ||
    amount < selectedAsset.transferMin

  const handleAccountFromChange = (newAccountFrom: ETigerXAccount) => {
    setAccountFrom(prev => {
      if (newAccountFrom === accountTo) {
        setAccountTo(prev)
        setSelectedAsset(null)
        return accountTo
      }
      return newAccountFrom
    })
  }

  const handleAccountToChange = (newAccountTo: ETigerXAccount) => {
    setAccountTo(prev => {
      if (newAccountTo === accountFrom) {
        setAccountFrom(prev)
        setSelectedAsset(null)
        return accountFrom
      }
      return newAccountTo
    })
  }

  return (
    <>
      <div className={clsx(isMobile && style.mobile, style.transfer)}>
        <div className={style.top}>
          <Select<ETigerXAccount>
            size={isMobile ? Select.Size.Medium : Select.Size.Large}
            options={options}
            label={t({ message: 'From', id: 'core.transaction.from' })}
            value={accountFrom}
            onChange={handleAccountFromChange}
            dataTestId={DataTestIds.TransferAccountTypeSectionFrom}
          />

          <Button
            variant={isMobile ? Button.Variant.Primary : Button.Variant.Clean}
            onClick={toggleAccounts}
            className={style.buttonWrap}
            leftIcon={<SVG.Actions.Transfer />}
            dataTestId={DataTestIds.TransferSwitchButton}
          />
          <Select<ETigerXAccount>
            size={isMobile ? Select.Size.Medium : Select.Size.Large}
            options={optionsForAccountTo}
            label={t({ message: 'To', id: 'core.transaction.to' })}
            value={accountTo}
            onChange={handleAccountToChange}
            dataTestId={DataTestIds.TransferAccountTypeSectionTo}
          />
        </div>

        <div className={clsx(style.coins)}>
          <Input
            size={isMobile ? InputSize.Medium : InputSize.Large}
            setValue={setAmount}
            value={amount}
            placeholder="0"
            dataTestId={DataTestIds.AmountInput}
            info={
              selectedAsset && (
                <>
                  <div className={style.helpText}>
                    {t({ message: 'Available', id: 'core.transaction.available' })}
                    <span className={style.available} data-testid={DataTestIds.AmountAvailableField}>
                      {' '}
                      {selectedAsset.balanceFormatted} {selectedAsset.asset}
                    </span>
                  </div>
                  <div className={style.helpText}>
                    {t({ message: 'Transfer Min', id: 'core.transaction.transferMin' })}
                    <span className={style.available} data-testid={DataTestIds.AmountAvailableField}>
                      {' '}
                      {selectedAsset.transferMin ? selectedAsset.transferMin : ''} {selectedAsset.asset}
                    </span>
                  </div>
                </>
              )
            }
            errorMessage={isTransferAttemptDetected ? errorMessage : ''}
            inputAttrs={{ type: 'number' }}
            containerClassName={style.input}
          >
            {allowCoins.length ? (
              <div className={style.inputAside}>
                {selectedAsset && (
                  <Button.Primary
                    label="Max"
                    className={style.maxBtn}
                    onClick={handleSetMax}
                    dataTestId={DataTestIds.AmountMaxButton}
                  />
                )}
                <Select
                  size={Select.Size.Medium}
                  onChange={changeSelectedAsset}
                  value={selectedAsset?.asset || null}
                  className={style.coinSelect}
                  options={allowCoins}
                  variant={Select.Variant.Raw}
                  dataTestId={DataTestIds.CoinSelector}
                />
              </div>
            ) : (
              <NoCoins
                fromAccount={accountConfig[accountFrom]?.name ?? ''}
                toAccount={accountConfig[accountTo]?.name ?? ''}
              />
            )}
          </Input>
        </div>
        <Button.Accent
          size={isMobile ? ButtonSize.Medium : ButtonSize.Large}
          onClick={transferAsset}
          label={t({ message: 'Transfer', id: 'core.transfer' })}
          description={
            t({ id: 'core.transaction.includingFee', message: `Including network fee:` }) +
            ` ${selectedAsset?.transferFee ?? '-'}`
          }
          disabled={isDisabledTransfer}
          dataTestId={DataTestIds.TransferConfirmButton}
          className={style.actionButton}
        />
      </div>

      <InteractiveModal isOpen={isModalOpen} className={clsx(isMobile && style.mobile)}>
        <InteractiveModalParts.Icon icon="transfer" />
        <InteractiveModalParts.Header text="Transfer" />
        <InteractiveModalParts.MainInfo
          text={getCompoundTransactionAmount(amount, selectedAsset?.asset)}
          className={style.mainInfo}
        />
        {selectedAsset?.transferFee >= 0 && (
          <InteractiveModalParts.Explanation
            text={
              t({ id: 'core.transaction.includingFee', comment: 'Including network fee: ' }) +
              ` ${selectedAsset.transferFee}`
            }
            className={style.explanation}
          />
        )}
        <InteractiveModalParts.Status
          status={status === 'loading' || status === 'succeeded' ? 'loading' : 'failed'}
          date={date}
        />
        <InteractiveModalParts.Table
          left={t({ message: 'From', id: 'core.transaction.from' })}
          right={accountConfig[accountFrom]?.name ?? ''}
          dataTestId={DataTestIds.HistoryModalFrom}
        />
        <InteractiveModalParts.Table
          left={t({ message: 'To', id: 'core.transaction.to' })}
          right={accountConfig[accountTo]?.name ?? ''}
          dataTestId={DataTestIds.HistoryModalAddrTo}
        />
        <InteractiveModalParts.TableCopy
          left={t({ id: 'core.modal.id', message: `Tiger.Trade ID` })}
          right={tigerTradeId || ''}
          isShortenView
          dataTestId={DataTestIds.ModalTigerId}
        />
        <InteractiveModalParts.Button
          text={t({ message: 'Go back to main page', id: 'core.gobackMainPage' })}
          variant={ButtonVariant.White}
          onClick={() => navigate(urls.root)}
          dataTestId={DataTestIds.ModalReturnButton}
        />
      </InteractiveModal>
    </>
  )
})

type Assets = TransactionAssets['coinAccountBalance']['assets']
type Asset = Assets[number] & {
  balanceFormatted: string
  transferFee: string
  transferMin: number
}

type AssetOption = SelectOption & {
  name: string
  balance: number
  balanceFormatted: string
  transferFee: string | null
  transferMin: string | null
  transferIntegerMultiple: number | null
}

type AccountItem = {
  name: string
  prefix: string
  assetsOptions: Array<AssetOption>
  allowedAccountsToTransfer: Array<ETigerXAccount>
}

type AccountConfig = Partial<Record<ETigerXAccount, AccountItem>>

const maximumDecimalsQuantity = 6

const getOptionsForAccountTo = (
  options: SelectOption<ETigerXAccount>[],
  accountConfig: AccountConfig,
  accountTo: ETigerXAccount
) => {
  const allowedAccounts = accountConfig[accountTo]?.allowedAccountsToTransfer || []

  // Фильтруем только те опции, которые разрешены
  return options.filter(option => allowedAccounts.includes(option.value))
}

type BalanceAsset = {
  asset: string
  balance: number
  subExchange: string
}
