import { FC } from 'react'
import { ICashbackWidgetProps, ICashbackWidgetContentProps } from '../CashbackWidget.types'
import { useCashbackWidget } from '../CashbackWidget.hooks'
import { useNavigate } from 'react-router-dom'
import { CardWrapper } from '@/ui/kit/CardWrapper'
import clsx from 'clsx'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { Button } from '@/ui/kit'
import style from '../style.module.scss'
import { useStatuses } from '@/utils/hooks/useStatuses'
import { Skeleton } from './Skeleton'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { EXCHANGES_TYPE } from '@/core/constants'

const CashbackWidgetTemplate: FC<ICashbackWidgetContentProps> = props => {
  const navigate = useNavigate()
  const { className, heading, subheading, items, theme = 'multiple', button, icon } = props

  return (
    <CardWrapper className={clsx(className, style[theme])}>
      {icon}
      <div className={style.heading} data-testid={DataTestIds.AccountInfoCashbackPercent}>
        {heading.text}
        {heading.label && <span className={style.headingLabel}>{heading.label}</span>}
      </div>
      {subheading && <div className={style.subheading}>{subheading}</div>}
      {items.map((item, index) => {
        return (
          <div className={style.item} key={index}>
            {item.heading && (
              <div className={style.itemHeading} data-testid={item.dataTestId}>
                <span className={style.itemHeadingNumber}>{index + 1}</span>
                {item.heading.text}
                {item.heading.label && <span className={style.itemHeadingLabel}>{item.heading.label}</span>}
              </div>
            )}
            {item.text}
          </div>
        )
      })}
      {button && (
        <Button.AccentDark
          onClick={() => navigate(button.link)}
          className={style.button}
          label={button.text}
          size={Button.Size.Medium}
        />
      )}
    </CardWrapper>
  )
}

export const CashbackWidget: FC<ICashbackWidgetProps> = props => {
  const { customContent: customTexts, customOptions = {} } = props
  const { termsLimitsCashbackStatus } = useStatuses()
  const { exchangeType } = useExchageType()

  const comissionInfoProps = useCashbackWidget({ customContent: customTexts })

  const { isCommissionEnabled } = useExchangesDisabled()

  //  Temrorary disabled okx affiliate on this component
  if (exchangeType === EXCHANGES_TYPE.OKX_AFFILIATE) return null

  if (!isCommissionEnabled) return null

  if (termsLimitsCashbackStatus !== 'succeeded' && exchangeType !== 'BINANCE_VIP' && exchangeType !== 'TIGER_X')
    return <Skeleton />

  return (
    <div className={style.cashbackBlock}>
      <CashbackWidgetTemplate {...comissionInfoProps} {...customOptions} />
    </div>
  )
}
