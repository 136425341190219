import { FC, useCallback, useEffect, useState } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import clsx from 'clsx'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import { i18n } from '@lingui/core'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { Button, ButtonSize, ButtonVariant } from '@/ui/kit'
import {
  calculateDateInSevenDays,
  getLastPeriodDateRange,
  isEndTimeBeforeStartTime,
  getDateWithTimeString,
  calculateDateInNinetyDays,
} from './utils'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'
import style from './style.module.scss'
import TimePicker from './TimePicker'
import { FILTER_INITIAL_TIME, FILTER_INITIAL_TIME_END } from '@/core/constants'
import { DAY_IN_MILLISECONDS } from '@/utils/lib/constants'

interface DatePickerProps {
  className?: string
  startDate: Date
  endDate: Date
  onClose: () => void
  onApply: (start: string, end: string, startTime?: string, endTime?: string) => void
  timePickerData?: {
    start: string
    end: string
  }
  isBybitHistoryFilter?: boolean
  isOkxHistoryFilter?: boolean
  isTigerXHistoryFilter?: boolean
}
const DatePicker: FC<DatePickerProps> = props => {
  const {
    className,
    startDate,
    endDate,
    onClose,
    onApply,
    isBybitHistoryFilter,
    timePickerData,
    isOkxHistoryFilter,
    isTigerXHistoryFilter,
  } = props

  const [dateStart, setDateStart] = useState(startDate)
  const [dateEnd, setDateEnd] = useState<Date>(endDate)
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [isInValid, setIsInValid] = useState(false)
  const utcDateStart = new Date(dateStart)
  const utcDateEnd = new Date(dateEnd)
  const equalDates = utcDateStart.getTime() === utcDateEnd.getTime()

  const handleChange = (dates: [Date, Date]) => {
    const [start, end] = dates
    setDateStart(start)
    setDateEnd(end)
  }

  useEffect(() => {
    if (timePickerData) {
      setStartTime(timePickerData.start)
      setEndTime(timePickerData.end)
    }
  }, [timePickerData])

  const handleChangeRange = (period: 'week' | 'month' | 'year') => {
    const [start, end] = getLastPeriodDateRange(period)
    setDateStart(new Date(start))
    setDateEnd(new Date(end))
  }
  const ninetyDaysAgo = new Date(Date.now() - 90 * DAY_IN_MILLISECONDS)

  const isRangeBigThanNewDate = calculateDateInSevenDays(dateStart) > new Date()
  const isRangeBigThaneNinetyDays = calculateDateInNinetyDays(dateStart) > new Date()

  const renderCustomHeader: ReactDatePickerProps['renderCustomHeader'] = ({
    date,
    decreaseMonth,
    increaseMonth,
  }: any) => (
    <div>
      <div className={style.headerActions}>
        {isBybitHistoryFilter || isTigerXHistoryFilter ? (
          <div className={style.headerActionsButtonWrapper}>
            <Button
              onClick={() => handleChangeRange('week')}
              className={style.headerActionsButton}
              variant={ButtonVariant.AccentDark}
            >
              {t({
                id: 'core.datePicker.lastWeek',
                message: 'Last week',
              })}
            </Button>
          </div>
        ) : isOkxHistoryFilter ? (
          <>
            <Button
              onClick={() => handleChangeRange('week')}
              className={style.headerActionsButton}
              variant={ButtonVariant.AccentDark}
            >
              {t({
                id: 'core.datePicker.lastWeek',
                message: 'Last week',
              })}
            </Button>
            <Button
              className={style.headerActionsButton}
              onClick={() => handleChangeRange('month')}
              variant={ButtonVariant.AccentDark}
            >
              {t({
                id: 'core.datePicker.lastMonth',
                message: 'Last month',
              })}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => handleChangeRange('week')}
              className={style.headerActionsButton}
              variant={ButtonVariant.AccentDark}
            >
              {t({
                id: 'core.datePicker.lastWeek',
                message: 'Last week',
              })}
            </Button>
            <Button
              className={style.headerActionsButton}
              onClick={() => handleChangeRange('month')}
              variant={ButtonVariant.AccentDark}
            >
              {t({
                id: 'core.datePicker.lastMonth',
                message: 'Last month',
              })}
            </Button>
            <Button
              className={style.headerActionsButton}
              onClick={() => handleChangeRange('year')}
              variant={ButtonVariant.AccentDark}
            >
              {t({
                id: 'core.datePicker.lastYear',
                message: 'Last year',
              })}
            </Button>
          </>
        )}
      </div>
      <div className={style.headerMonths}>
        <Button variant={ButtonVariant.Primary} className={style.arrowIcon} onClick={decreaseMonth}>
          <SVG.Arrows.ArrowLeft />
        </Button>
        <span>{formatDate(date, DATE_FORMAT.MONTH_FULL_AND_YEAR)}</span>
        <Button variant={ButtonVariant.Primary} className={style.arrowIcon} onClick={increaseMonth}>
          <SVG.Arrows.ArrowRight className={style.linkArrow} />
        </Button>
      </div>
    </div>
  )

  const renderDayContents: ReactDatePickerProps['renderDayContents'] = (day, date) => {
    return <span>{formatDate(date ?? new Date(), DATE_FORMAT.DAY)}</span>
  }
  const handleApply = useCallback(() => {
    if (timePickerData) {
      const datStartWithTimeString = getDateWithTimeString(dateStart, startTime)
      const dateEndWithTimeString = getDateWithTimeString(
        dateEnd ?? new Date(),
        endTime !== FILTER_INITIAL_TIME ? endTime : FILTER_INITIAL_TIME_END
      )
      if (isBybitHistoryFilter) {
        if (endTime !== FILTER_INITIAL_TIME) {
          onApply(
            formatDate(datStartWithTimeString, DATE_FORMAT.WITH_TIME),
            formatDate(
              dateEndWithTimeString ?? calculateDateInSevenDays(datStartWithTimeString),
              DATE_FORMAT.WITH_TIME
            ),
            startTime,
            endTime
          )
        } else {
          onApply(
            formatDate(dateStart, DATE_FORMAT.WITH_TIME),
            formatDate(dateEnd ?? calculateDateInSevenDays(dateStart), DATE_FORMAT.FORMATTED)
          )
        }
      } else {
        onApply(
          formatDate(datStartWithTimeString, DATE_FORMAT.WITH_TIME),
          formatDate(dateEndWithTimeString, DATE_FORMAT.WITH_TIME),
          startTime,
          endTime
        )
      }
    } else {
      if (isBybitHistoryFilter) {
        onApply(
          formatDate(dateStart, DATE_FORMAT.FORMATTED),
          formatDate(dateEnd ?? calculateDateInSevenDays(dateStart), DATE_FORMAT.FORMATTED)
        )
      } else {
        onApply(formatDate(dateStart, DATE_FORMAT.FORMATTED), formatDate(dateEnd ?? new Date(), DATE_FORMAT.FORMATTED))
      }
    }
  }, [timePickerData, dateStart, startTime, dateEnd, endTime, isBybitHistoryFilter, onApply])

  const handleChangeStartTime = (time: string) => {
    const isStartMoreThanEnd = isEndTimeBeforeStartTime(time, endTime)
    if (equalDates && isStartMoreThanEnd) {
      setIsInValid(true)
      setStartTime(time)
    } else {
      setIsInValid(false)
      setStartTime(time)
    }
  }

  const handleChangeEndTime = (time: string) => {
    const isStartMoreThanEnd = isEndTimeBeforeStartTime(startTime, time)

    if (equalDates && isStartMoreThanEnd) {
      setIsInValid(true)
      setEndTime(time)
    } else {
      setIsInValid(false)
      setEndTime(time)
    }
  }

  return (
    <div className={clsx(style.root, className)}>
      <ReactDatePicker
        selected={endDate}
        onChange={handleChange}
        startDate={dateStart}
        endDate={dateEnd}
        selectsRange
        minDate={isTigerXHistoryFilter ? ninetyDaysAgo : undefined}
        calendarStartDay={1}
        locale={i18n.locale}
        maxDate={
          isBybitHistoryFilter
            ? !isRangeBigThanNewDate
              ? calculateDateInSevenDays(dateStart)
              : new Date()
            : isTigerXHistoryFilter
            ? !isRangeBigThaneNinetyDays
              ? calculateDateInNinetyDays(dateStart)
              : new Date()
            : new Date()
        }
        open
        renderCustomHeader={renderCustomHeader}
        renderDayContents={renderDayContents}
        inline
      >
        <div className={style.headerStartEndDates}>
          <div className={style.headerDateBlock}>
            <p>{formatDate(dateStart, DATE_FORMAT.DAY_AND_YEAR_SEMI)}</p>
          </div>
          <span className={style.line}></span>
          <div className={style.headerDateBlock}>
            {isBybitHistoryFilter ? (
              <p>{formatDate(dateEnd ?? calculateDateInSevenDays(dateStart), DATE_FORMAT.DAY_AND_YEAR_SEMI)}</p>
            ) : (
              <p>{formatDate(dateEnd ?? new Date(), DATE_FORMAT.DAY_AND_YEAR_SEMI)}</p>
            )}
          </div>
        </div>
        {timePickerData && (
          <>
            <div className={style.headerStartEndDates}>
              <div>
                <div className={style.headerDateBlock}>
                  <TimePicker onChange={handleChangeStartTime} initialValue={startTime} />
                </div>
                <div className={style.dateMsg}>{t({ id: 'core.range.startDate', message: 'Start time' })}</div>
              </div>
              <div>
                <div className={style.headerDateBlock}>
                  <TimePicker onChange={handleChangeEndTime} initialValue={endTime} />
                </div>
                <div className={style.dateMsg}>{t({ id: 'core.range.endDate', message: 'End time' })}</div>
              </div>
            </div>
            {isInValid && (
              <div className={style.invalidRange}>{t({ id: 'core.range.inValid', message: 'Range is invalid' })}</div>
            )}
          </>
        )}
        <div className={style.actions}>
          <Button
            onClick={onClose}
            className={style.actionCancel}
            size={ButtonSize.Medium}
            variant={ButtonVariant.Secondary}
          >
            {t({
              id: 'core.cancel',
              message: 'Cancel',
            })}
          </Button>
          <Button size={ButtonSize.Medium} onClick={handleApply} variant={ButtonVariant.Accent} disabled={!!isInValid}>
            {t({
              id: 'core.button.apply',
              message: 'Apply',
            })}
          </Button>
        </div>
      </ReactDatePicker>
    </div>
  )
}

export { DatePicker }
