import { SVG } from '@/assets/svg'
import style from '../../style.module.scss'
import { t } from '@lingui/macro'
import { Button, Dropdown } from '@/ui/kit'
import { useExchangesSource } from '@/utils/hooks/useExchangesSource'
import { useActions, useAppSelector } from '@/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TOptionExchange } from '../ExchangeType'
import { tradesActions } from '@/redux/trades/trades.slice'
import { TPollingConditionalFunc, usePolling } from '@/utils/hooks/usePolling'
import { JOURNAL_REPAIR_INTERVAL } from '@/core/config/profile'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { checkExchangeStatuses, formatDateLineToDot } from './utils'
import { TExchangesAvailable } from '@/core/constants'

const TIGER_X_LABEL = 'Tiger X'

export const ApiKeyRepair = () => {
  const options = useExchangesSource(false).filter(el => el.label !== TIGER_X_LABEL)
  const {
    metadata: { exchangeType },
  } = useAppSelector(state => state.profile)
  const { getAvailableExchangeKeys, repairApiKeyStatus, getExchangeAvailableStatus } = useAppSelector(
    state => state.trades
  )
  const { RepairApiKeyTC, GetTradesAvailableExchangeTC } = useActions(tradesActions)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [selectedExchangeValues, setSelectedExchangeValues] = useState<TExchangesAvailable[]>([])
  const [selectedOption, setSelectedOption] = useState<TOptionExchange>({
    label: options[0].label,
    value: options[0].value,
  })
  const selectedExchange = useMemo(() => {
    return getAvailableExchangeKeys?.exchangeRepairStatuses?.find(
      status => status?.exchangeType === selectedOption.value
    )
  }, [getAvailableExchangeKeys?.exchangeRepairStatuses, selectedOption.value])

  const buttonDisabled =
    !isButtonEnabled ||
    !selectedExchange?.exchangeType ||
    getExchangeAvailableStatus === 'loading' ||
    repairApiKeyStatus === 'loading'

  const descriptionText =
    getExchangeAvailableStatus !== 'loading' && !selectedExchange?.exchangeType
      ? t({
          id: 'profile.apiRepair.withoutExchange',
          comment: 'without exchange',
        })
      : !selectedExchange?.enabled
      ? t({
          id: 'profile.apiRepair.dataInProgress',
          comment: 'data in Progress',
        })
      : selectedExchange?.updatedAt || (selectedExchange?.updatedAt && selectedExchange?.enabled)
      ? `${t({
          id: 'profile.apiRepair.updated',
          comment: 'updated',
        })} ${formatDateLineToDot(formatDate(selectedExchange.updatedAt, DATE_FORMAT.DAY_HOUR_WITHOUT_SECONDS))} `
      : isButtonEnabled &&
        t({
          id: 'profile.apiRepair.updateData',
          comment: 'updateData',
        })

  const labelText =
    getExchangeAvailableStatus !== 'loading' && !selectedExchange?.exchangeType
      ? t({
          id: 'profile.apiRepair.labelWithoutExchange',
          comment: 'without exchange',
        })
      : !selectedExchange?.enabled
      ? t({
          id: 'profile.apiRepair.labelDataInProgress',
          comment: 'data in Progress',
        })
      : selectedExchange?.updatedAt && selectedExchange?.enabled
      ? t({
          id: 'profile.apiRepair.labelUpdateData',
          comment: 'label updated',
        })
      : isButtonEnabled || (selectedExchange?.updatedAt && selectedExchange?.enabled)
      ? t({
          id: 'profile.apiRepair.labelUpdateData',
          comment: 'updateData',
        })
      : selectedExchange?.updatedAt &&
        t({
          id: 'profile.apiRepair.LabelUpdated',
          comment: 'label updated',
        })

  const apiPollingCheckFunction = useCallback<TPollingConditionalFunc>(() => {
    const isPolling = checkExchangeStatuses(selectedExchangeValues, getAvailableExchangeKeys?.exchangeRepairStatuses)
    if (!isPolling) return false
    return true
  }, [getAvailableExchangeKeys?.exchangeRepairStatuses, selectedExchangeValues])

  const { startPolling } = usePolling(GetTradesAvailableExchangeTC, apiPollingCheckFunction, JOURNAL_REPAIR_INTERVAL)
  useEffect(() => {
    GetTradesAvailableExchangeTC()
  }, [])

  useEffect(() => {
    if (repairApiKeyStatus === 'succeeded') {
      GetTradesAvailableExchangeTC()
      startPolling()
    }
  }, [repairApiKeyStatus, startPolling])

  useEffect(() => {
    if (selectedExchange && selectedExchange.enabled) {
      setIsButtonEnabled(true)
    } else {
      setIsButtonEnabled(false)
    }
  }, [getAvailableExchangeKeys, selectedOption])

  const setSelectedCallback = (value: TOptionExchange) => {
    if (value.value === selectedOption?.value) return
    if (!exchangeType) return

    setSelectedOption({
      label: value.label,
      value: value.value,
    })
  }
  const updateHandler = () => {
    RepairApiKeyTC(selectedOption.value)
    setSelectedExchangeValues(prev => [...prev, selectedOption.value])
  }

  return (
    <div className={style.setting}>
      <SVG.Settings.ApiRepair className={style.sectionIcon} />
      <div>
        <div className={style.settingTitle}>{labelText}</div>
        <div className={style.settingStatus}>{descriptionText}</div>
      </div>
      <div className={style.settingActions}>
        <Dropdown
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedCallback}
          className={style.exchangeSelect}
        />
        {
          <Button.Primary
            label={
              getExchangeAvailableStatus !== 'loading' && !selectedExchange?.enabled
                ? t({
                    id: 'profile.apiRepair.updateDataBtn',
                  })
                : !selectedExchange?.enabled
                ? t({
                    id: 'profile.apiRepair.updateDataButtonProgress',
                  })
                : t({
                    id: 'profile.apiRepair.updateDataBtn',
                  })
            }
            onClick={updateHandler}
            disabled={buttonDisabled}
          />
        }
      </div>
    </div>
  )
}
