import { ApiKeyInfoBlocksItem } from '../ApiKeyInfoBlocksItem'
import { SVG } from '@/assets/svg'

import styles from './styles.module.scss'
import { Trans, t } from '@lingui/macro'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'
import { TigerXConfig } from '@/exchanges/tigerx/tigerx.config'

export const ApiKeyInfoBlocks = () => {
  const { exchangeType } = useExchageType()
  const BLOCKS = [
    {
      id: 1,
      icon: <SVG.Api.Key />,
      text: (
        <Trans
          id="apiPage.infoBlock.upTo"
          values={{
            availableKeys: exchangeType === EXCHANGES_TYPE.TIGER_X ? TigerXConfig.pages.api.availableKeys : 19,
          }}
        />
      ),
    },
    {
      id: 2,
      icon: <SVG.Api.Lock />,
      text: t({
        id: 'apiPage.infoBlock.security',
        message: 'You can increase the security of your account by linking IP address to API key',
      }),
    },
    {
      id: 3,
      icon: <SVG.Api.Scan />,
      text: t({
        id: 'apiPage.infoBlock.exposed',
        message: 'Be aware that if you log in to a third-party platform, your API key may be exposed',
      }),
    },
    {
      id: 4,
      icon: <SVG.Api.User />,
      text: t({
        id: 'apiPage.infoBlock.kyc',
        message: 'API key creation will only be available after you complete KYC',
      }),
    },
  ]
  return (
    <div className={styles.root}>
      {BLOCKS.map(block => (
        <ApiKeyInfoBlocksItem key={block.id} icon={block.icon} text={block.text} />
      ))}
    </div>
  )
}
