import React, { FC, memo, useMemo } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { getMessageByExchange } from './HowItWorks.translations'

const HowItWorks: FC = () => {
  const { exchangeType } = useExchageType()

  const tradeText = useMemo(() => {
    if (exchangeType === undefined) return ''
    const messages = getMessageByExchange()
    return messages[exchangeType]
  }, [exchangeType])

  return (
    <div className={style.howItWorks}>
      <div className={style.title}>
        {t({
          id: 'apiPage.howItWorks.title',
          message: 'How it works?',
        })}
      </div>
      <div className={style.itemWrap}>
        <div className={style.number}>1</div>
        <div className={style.text}>
          {t({
            id: 'apiPage.howItWorks.create',
            message: 'Create an API key and name it',
          })}
        </div>
      </div>
      <div className={style.itemWrap}>
        <div className={style.number}>2</div>
        <div className={style.text}>
          {t({
            id: 'apiPage.howItWorks.copy.msg-1',
            message: 'Log in to the trading platform using your',
          })}{' '}
          <a
            className={style.textLink}
            href={t({
              id: 'apiPage.howItWorks.copy.linkHref',
              message: 'https://www.tiger.trade/terminal',
            })}
            target="_blank"
            rel="noreferrer"
          >
            {t({
              id: 'core.TigerTrade',
              message: 'Tiger.Trade',
            })}
          </a>{' '}
          {/* {t({
            id: 'apiPage.howItWorks.copy.msg-2',
            message: 'account credentials',
          })} */}
        </div>
      </div>
      <div className={style.itemWrap}>
        <div className={style.number}>3</div>
        <div className={style.text}>{tradeText}</div>
      </div>
    </div>
  )
}

export default memo(HowItWorks)
