import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EAccountType } from '@tigertrade/binance-ts'
import BigNumber from 'bignumber.js'
import { AccountOverviewPositionsView, OverviewOpenOrder } from '@/backend/models/OverviewDTO'
import { Status } from '@/backend/types'
import { STATISTICS_ALLOWED_TYPES } from '@/core/config/overview'
import { TExchangesAvailable } from '@/core/constants'
import { OVERVIEW_DELETION_PROCESSING_ITERATIONS, OVERVIEW_MAX_SMALL_ASSETS_IN_USDT } from '@/core/constants/overview'
import { TAccountType, TInstrumentType } from '@/core/types/overview'
import { EBybitInstruments } from '@/services/bybit'
import { storage } from '@/utils/lib/storage'
import overviewAsyncActions from './overview.actions'
import { OVERVIEW_ACCOUNT_SYMBOLS_MAP } from './overview.defaults'
import { INCOME_DEFAULT } from './overview.defaults'
import initialState from './overview.state'
import {
  AccountInfoV3,
  InitialState,
  TabIds,
  TBalanceUpdateTuple,
  TDeletingEntitySelector,
  TIncomeStatistics,
  TMarketDataInternal,
  TOrderUpdateTuple,
  TOverviewSortingField,
  TPositionsUpdateTuple,
  TStatisticsHistoryItem,
} from './overview.types'
import {
  calcAssets,
  calcKnownAssetsSum,
  createUsdmHistoryUid,
  getAccountTypeForInstrument,
  isAllBalancesLoaded,
  isAllOrdersLoaded,
  isAllPositionsLoaded,
  seedCoinsNames,
  /* setDataParamsToOverview */
} from './overview.utils'
import { isExchangeBybit } from '@/utils/lib/exchange'
import { SpotInstrumentInfoV5 } from 'bybit-api'
import { Instrument } from 'okx-api'
import { BN_ZERO } from '@/core/constants/common'

export const slice = createSlice({
  name: `[Tiger Trade Account Overview]`,
  initialState,
  reducers: {
    setInstrumentType(state, action: PayloadAction<TInstrumentType>) {
      state.instrumentType = action.payload
      const accountTypeForInstrument = getAccountTypeForInstrument(action.payload, state.exchangeType)
      state.accountTypeForInstrument = accountTypeForInstrument
      state.accountLowBalanceLimit = `${OVERVIEW_MAX_SMALL_ASSETS_IN_USDT} ${
        OVERVIEW_ACCOUNT_SYMBOLS_MAP[accountTypeForInstrument ?? 'BINANCE_SPOT'].symbol
      }`
    },
    setAccountTypeForInstrument(state, action: PayloadAction<TAccountType>) {
      state.accountTypeForInstrument = action.payload
    },
    setAccountType(state, action: PayloadAction<TAccountType>) {
      state.accountType = action.payload
    },
    setTabId(state, action: PayloadAction<TabIds>) {
      state.tabId = action.payload
    },
    resetIsolatedMargin(state) {
      state.isolatedMargin = {
        isMarginSet: false,
        isMarginSuccess: false,
      }
    },
    resetBinanceConvert(state) {
      state.binanceConvert.convertReq.isConvertReq = false
      state.binanceConvert.convertReq.isConvertSuccess = false
      state.binanceConvert.previewReq.isPreviewReq = false
      state.binanceConvert.previewReq.isPreviewSuccess = false
    },
    setBinanceConvert(state, action: PayloadAction<boolean>) {
      state.isBinanceConvert = action.payload
    },
    setOverviewStatus(state, action: PayloadAction<Status>) {
      // state.status = action.payload
      state.statusOrders = action.payload
      state.statusPositions = action.payload
      state.statusBalances = action.payload

      if (action.payload === 'loading') {
        state.exchangeInstruments.forEach(instrumentKey => {
          state.positions[instrumentKey].isDefault = true
          state.openOrders[instrumentKey].isDefault = true
        })
      }
    },
    sortOrdersPositions(state, { payload }: PayloadAction<{ type: string; isAscending: boolean }>) {
      state.exchangeInstruments.forEach(instrumentKey => {
        state.positions[instrumentKey].positions = sortItems<AccountOverviewPositionsView>(
          state.positions[instrumentKey].positions,
          payload.type,
          payload.isAscending
        )

        state.openOrders[instrumentKey].orders = sortItems<OverviewOpenOrder>(
          state.openOrders[instrumentKey].orders,
          payload.type,
          payload.isAscending
        )
      })
      state.isAscending = payload.isAscending
      state.sortingField = payload.type as TOverviewSortingField
    },
    setOverviewReset(
      state,
      action: PayloadAction<{
        exchangeType: TExchangesAvailable
        exchangeInstruments: TInstrumentType[]
        exchangeAccounts: TAccountType[]
        isRewriteDefaultInstrument?: boolean
      }>
    ) {
      const incomeStatistics = state.incomeStatistics
      state = Object.assign(state, initialState)
      state.incomeStatistics = incomeStatistics // save pre-initialized statistics from profile
      state.exchangeInstruments = action.payload.exchangeInstruments
      state.exchangeAccounts = action.payload.exchangeAccounts
      state.exchangeType = action.payload.exchangeType
      state.balanceOutputConfig = {
        showMargin: true,
      }
      if (action.payload.isRewriteDefaultInstrument) state.instrumentType = action.payload.exchangeInstruments[0]
    },
    setBalanceOutputConfig(state, action: PayloadAction<Partial<InitialState['balanceOutputConfig']>>) {
      state.balanceOutputConfig = {
        ...state.balanceOutputConfig,
        ...action.payload,
      }
    },
    setOrdersUpdate(state, action: PayloadAction<TOrderUpdateTuple[]>) {
      action.payload.forEach(dataRecord => {
        const [type, data] = dataRecord

        const orderIdsInUpdate: Record<string, boolean> = data.ordersArray.reduce((acc, order) => {
          return {
            ...acc,
            [order.orderId]: true,
          }
        }, {})

        state.openOrders[type].orders = sortItems<OverviewOpenOrder>(
          data.ordersArray.map(order => {
            return {
              ...order,
              accountType: type,
            }
          }),
          state.sortingField,
          state.isAscending
        )
        state.openOrders[type].isDefault = false

        // state.deletingOrders[type] = {}
        const deletingOrders = { ...state.deletingOrders[type] }
        Object.keys(deletingOrders).forEach(deletingOrderId => {
          if (
            orderIdsInUpdate[deletingOrderId] === undefined || // if no order in the list
            deletingOrders[deletingOrderId].processingIterations === OVERVIEW_DELETION_PROCESSING_ITERATIONS
          ) {
            // or reached  deletion waiting iterations limit
            delete deletingOrders[deletingOrderId]
          } else {
            deletingOrders[deletingOrderId].processingIterations += 1
          }
        })

        state.deletingOrders[type] = deletingOrders

        if (type === EAccountType.SPOT || type === EBybitInstruments.spot) {
          state.positions[type].isDefault = false
        }
      })

      if (isAllOrdersLoaded(state)) {
        state.statusOrders = 'succeeded'
      }
    },
    setInstrumentInfoByBit(state, action: PayloadAction<SpotInstrumentInfoV5[]>) {
      state.byBitInstruments = action.payload
    },
    setInstrumentInfoOkx(state, action: PayloadAction<Instrument[]>) {
      state.okxInstruments = action.payload
    },
    setBalanceUpdate(
      state,
      action: PayloadAction<{
        blancesData: TBalanceUpdateTuple[]
        skipUnknownAssets?: boolean
      }>
    ) {
      // array for multiple account types
      action.payload.blancesData.forEach(dataRecord => {
        const [instumentType, data] = dataRecord
        const accountType = getAccountTypeForInstrument(instumentType, state.exchangeType)
        if (accountType === undefined) return

        const selectorOutput = OVERVIEW_ACCOUNT_SYMBOLS_MAP[accountType].selectorOutput

        const { totalAssetsOutput, assetsDataArray } = data

        state.balances[accountType].accountBalance = totalAssetsOutput[selectorOutput]?.toString() || ''

        const skipUnknownAssets = action.payload.skipUnknownAssets && totalAssetsOutput[selectorOutput] === undefined

        const totalAssetsOutputFallback = skipUnknownAssets
          ? calcKnownAssetsSum(accountType, assetsDataArray)
          : {
              [selectorOutput]: undefined,
            }
        if (skipUnknownAssets)
          state.balances[accountType].accountBalance = totalAssetsOutputFallback[selectorOutput]?.toString() || ''

        state.balances[accountType].assets = calcAssets(accountType, state.allCoins, assetsDataArray, totalAssetsOutput)
        state.balances[accountType].accountBalanceByQuotes = skipUnknownAssets
          ? totalAssetsOutputFallback
          : totalAssetsOutput
        state.balances[accountType].isDefault = state.balances[accountType].accountBalance === '' ? true : false

        // sum of account balances
        // const totalBalanceUsdt = (Object.keys(EAccountType) as Array<keyof typeof EAccountType>).reduce(
        const totalBalanceUsdt: number = state.exchangeAccounts
          .reduce((acc, account) => {
            if (account === accountType) {
              // TODO: save selectors somewhere
              return acc.plus(data.totalAssetsOutput.USDT || data.totalAssetsOutput.USD || 0)
            }
            return acc.plus(
              state.balances[account].accountBalanceByQuotes.USDT ||
                state.balances[account].accountBalanceByQuotes.USD ||
                0
            )
          }, BN_ZERO)
          .toNumber()

        state.totalBalance = String(totalBalanceUsdt)
      })

      if (isAllBalancesLoaded(state)) {
        state.statusBalances = 'succeeded'
      }
    },
    setWalletBalance(state, action: any) {
      state.walletBalanceBybit = action.payload
    },
    setPositionsUpdate(state, action: PayloadAction<TPositionsUpdateTuple[]>) {
      action.payload.forEach(dataRecord => {
        const [type, data] = dataRecord

        const positionsInUpdate: Record<string, boolean> =
          data.positionsArray?.reduce((acc, position) => {
            return {
              ...acc,
              [position.uid]: true,
            }
          }, {}) || {}

        let positions: AccountOverviewPositionsView[] | undefined = data.positionsArray?.map(position => {
          return {
            ...position,
            instrumentType: type,
          }
        })

        if (positions)
          positions = sortItems<AccountOverviewPositionsView>(positions, state.sortingField, state.isAscending)

        state.positions[type] = {
          ...state.positions[type],
          ...(positions ? { positions } : {}),
          isDefault: false,
          ...data,
        }

        // state.deletingPositions[type] = {}

        const deletingPositions = { ...state.deletingPositions[type] }
        Object.keys(deletingPositions).forEach(deletingPosId => {
          if (
            positionsInUpdate[deletingPosId] === undefined || // if no position in the list
            deletingPositions[deletingPosId].processingIterations === OVERVIEW_DELETION_PROCESSING_ITERATIONS
          ) {
            // or reached  deletion waiting iterations limit
            delete deletingPositions[deletingPosId]
          } else {
            deletingPositions[deletingPosId].processingIterations += 1
          }
        })

        state.deletingPositions[type] = deletingPositions
      })

      if (isAllPositionsLoaded(state)) {
        const defaultSpot: TInstrumentType = isExchangeBybit(state.exchangeType)
          ? EBybitInstruments.spot
          : EAccountType.SPOT

        if (state.instrumentType === undefined) {
          //TODO: set deault tab for different account types
          const defaultInstrument: TInstrumentType =
            state.exchangeInstruments.length > 1
              ? state.openOrders[state.exchangeInstruments[1]].orders.length > 0 ||
                state.positions[state.exchangeInstruments[1]].positions.length > 0
                ? state.exchangeInstruments[1]
                : defaultSpot
              : defaultSpot

          state.instrumentType = defaultInstrument
          const accountTypeForInstrument = getAccountTypeForInstrument(defaultInstrument, state.exchangeType)
          state.accountTypeForInstrument = accountTypeForInstrument
          state.accountLowBalanceLimit = `${OVERVIEW_MAX_SMALL_ASSETS_IN_USDT} ${
            OVERVIEW_ACCOUNT_SYMBOLS_MAP[accountTypeForInstrument ?? 'BINANCE_SPOT'].symbol
          }`
          state.accountType = accountTypeForInstrument

          state.tabId = state.positions[state.instrumentType].positions.length > 0 ? 2 : 1
        }

        state.statusPositions = 'succeeded'
      }
    },
    resetIncomeStatistics(state, action: PayloadAction<[TExchangesAvailable, string]>) {
      const [exchangeType, startMoment] = action.payload

      state.incomeStatistics = {
        ...state.incomeStatistics,
        [exchangeType]: {
          ...state.incomeStatistics[exchangeType],
          BINANCE_FUTURE: {
            ...INCOME_DEFAULT,
            startMoment,
          },
        },
      }

      storage.setStatisticsCache([], exchangeType)
    },
    setIncomeStatistics(state, action: PayloadAction<[TExchangesAvailable, TAccountType, Partial<TIncomeStatistics>]>) {
      const [exchangeType, accountType, statistics] = action.payload

      state.incomeStatistics = {
        ...state.incomeStatistics,
        [exchangeType]: {
          ...state.incomeStatistics[exchangeType],
          [accountType]: {
            ...statistics,
          },
        },
      }
    },
    setMarketData(state, action: PayloadAction<[TInstrumentType, TMarketDataInternal]>) {
      const [key, value] = action.payload
      state.marketData[key] = value
    },
    setExchangeInfo(state, action) {
      state.exchangeInfo = action.payload
    },
    setExchangeInfoUsd(state, action) {
      state.exchangeInfoUsdm = action.payload
    },
    setLinearInstruments(state, action) {
      state.linearInstruments = action.payload
    },
    setSellAssetStatusReset: state => {
      state.statusSellAsset = 'idle'
      state.sellAssetErrorCode = undefined
    },
    setIsHiddenSmallAssets(state, action: PayloadAction<boolean>) {
      state.isHiddenSmallAssets = action.payload
    },
  },

  extraReducers: builder => {
    builder
      .addCase(overviewAsyncActions.GetAllCointsTC.fulfilled, (state, action) => {
        if (action.payload) {
          state.allCoins = action.payload
          state.exchangeAccounts.forEach(account => {
            state.balances[account].assets = seedCoinsNames(state.balances[account].assets, state.allCoins)
          })
        }
      })
      .addCase(overviewAsyncActions.CancelOverviewOpenOrderTC.pending, (state, action) => {
        const { orderId, instrumentType } = action.meta.arg
        state.deletingOrders[instrumentType][orderId] = {
          isDeleting: true,
          processingIterations: 0,
        }
      })
      .addCase(overviewAsyncActions.CancelOverviewOpenOrderTC.fulfilled, (state, action) => {
        const { instrumentType, orderId, success, msg } = action.payload

        if (!success) {
          const deletingOrders = state.deletingOrders[instrumentType]
          delete deletingOrders[orderId]
          state.deletingOrders[instrumentType] = deletingOrders
          state.errors = [{ code: 0, msg }]
        }
      })
      .addCase(overviewAsyncActions.CancelAllOverviewOpenOrdersTC.pending, (state, action) => {
        const { orders, instrumentType } = action.meta.arg
        const symbols = orders.map(({ symbol }) => symbol)

        state.deletingOrders[instrumentType] = state.openOrders[instrumentType].orders.reduce((acc, order) => {
          if (!symbols.includes(order.symbol)) return acc
          else
            return {
              ...acc,
              [order.orderId]: {
                isDeleting: true,
                processingIterations: 0,
              },
            }
        }, {} as TDeletingEntitySelector)
      })
      .addCase(overviewAsyncActions.CancelAllOverviewOpenOrdersTC.fulfilled, (state, action) => {
        const { orders } = action.meta.arg
        const { success, msg } = action.payload
        const symbols = orders.map(({ symbol }) => symbol)

        state.exchangeInstruments.forEach(type => {
          if (!success) {
            state.deletingOrders[type] = state.openOrders[type].orders.reduce((acc, order) => {
              const deletingOrders = { ...acc }

              if (symbols.includes(order.symbol)) {
                delete deletingOrders[order.orderId]
                return deletingOrders
              }

              return acc
            }, state.deletingOrders[type])
          }
        })

        state.errors = [{ code: 0, msg }]
      })
      .addCase(overviewAsyncActions.CloseOverviewPositionTC.pending, (state, action) => {
        const { instrumentType, positionId } = action.meta.arg
        state.deletingPositions[instrumentType][positionId] = {
          isDeleting: true,
          processingIterations: 0,
        }
      })
      .addCase(overviewAsyncActions.CloseOverviewPositionTC.fulfilled, (state, action) => {
        const { error, instrumentType, positionId, success } = action.payload

        if (!success) {
          const deletingPositions = { ...state.deletingPositions[instrumentType] }
          delete deletingPositions[positionId]

          state.deletingPositions[instrumentType] = deletingPositions
          state.errors = [{ code: 0, msg: error }]
        }
      })
      .addCase(overviewAsyncActions.CloseOverviewPositionTC.rejected, (state, action) => {
        const { instrumentType, positionId } = action.meta.arg

        const deletingPositions = { ...state.deletingPositions[instrumentType] }
        delete deletingPositions[positionId]

        state.deletingPositions[instrumentType] = deletingPositions
        state.errors = [{ code: 0, msg: action.payload?.errors?.[0] || '' }]
      })
      .addCase(overviewAsyncActions.CloseAllOverviewPositionsTC.pending, (state, action) => {
        const { instrumentType } = action.meta.arg

        state.deletingPositions[instrumentType] = state.positions[instrumentType].positions.reduce((acc, position) => {
          return {
            ...acc,
            [position.uid]: {
              isDeleting: true,
              processingIterations: 0,
            },
          }
        }, state.deletingPositions[instrumentType])
      })
      .addCase(overviewAsyncActions.CloseAllOverviewPositionsTC.fulfilled, (state, action) => {
        const { error, success, instrumentType } = action.payload

        if (!success) {
          state.deletingPositions[instrumentType] = state.positions[instrumentType].positions.reduce(
            (acc, position) => {
              const deletingPositions = { ...acc }
              delete deletingPositions[position.uid]
              return deletingPositions
            },
            state.deletingPositions[instrumentType]
          )
          state.errors = [{ code: 0, msg: error }]
        }
      })
      .addCase(overviewAsyncActions.CloseAllOverviewPositionsTC.rejected, (state, action) => {
        const { instrumentType } = action.meta.arg

        state.deletingPositions[instrumentType] = state.positions[instrumentType].positions.reduce((acc, position) => {
          const deletingPositions = { ...acc }
          delete deletingPositions[position.uid]
          return deletingPositions
        }, state.deletingPositions[instrumentType])
      })
      .addCase(overviewAsyncActions.GetStatisticsSinceMomentTC.pending, (state, action) => {
        state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.status = 'loading'
      })
      .addCase(overviewAsyncActions.GetStatisticsSinceMomentTC.rejected, (state, action) => {
        state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.status = 'failed'
        state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.dailyIncome = '0'
      })
      .addCase(overviewAsyncActions.GetStatisticsSinceMomentTC.fulfilled, (state, action) => {
        state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.status = 'succeeded'

        const payloadFilteredByType = action.payload.filter(incmeTx => {
          return STATISTICS_ALLOWED_TYPES.includes(incmeTx.incomeType)
        })

        const historySavedMoment = new Date().getTime().toString()

        const [incomeTotal, history] = payloadFilteredByType.reduce(
          (acc, item) => {
            const marketAsset = state.marketData.USDT_FUTURE[item.asset]
            const incomeCalculated = new BigNumber(item.income).multipliedBy(marketAsset?.quotes.USDT || 1)
            const uid = createUsdmHistoryUid(item)
            const historyItem: TStatisticsHistoryItem = {
              delta: incomeCalculated.toString(),
              time: item.time.toString(),
              uid: uid,
              saved: historySavedMoment,
            }
            return [acc[0].plus(incomeCalculated), [...acc[1], historyItem]]
          },
          [new BigNumber(state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.dailyIncome || 0), []] as [
            BigNumber,
            TStatisticsHistoryItem[]
          ]
        )

        if (history.length > 0) {
          state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.dailyIncome = incomeTotal.toString()
          const historyNew: TStatisticsHistoryItem[] = []
          if (state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.history) {
            historyNew.push(...state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.history)
          }
          historyNew.push(...history)

          state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.history = [...historyNew]
        } else if (state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.dailyIncome === undefined) {
          state.incomeStatistics[state.exchangeType].BINANCE_FUTURE.dailyIncome = '0'
        }
      })
      .addCase(overviewAsyncActions.SellAsset.rejected, (state, action) => {
        state.statusSellAsset = 'failed'
        state.sellAssetErrorCode = action.payload?.codes[0]
      })
      .addCase(overviewAsyncActions.SellAsset.pending, (state, action) => {
        state.statusSellAsset = 'loading'
      })
      .addCase(overviewAsyncActions.SellAsset.fulfilled, (state, action) => {
        state.statusSellAsset = 'succeeded'
      })

      .addCase(overviewAsyncActions.GetAccountInformationV3TC.pending, (state, action) => {
        state.isLoadingMargin = true
      })
      .addCase(overviewAsyncActions.GetAccountInformationV3TC.rejected, (state, action) => {
        state.isLoadingMargin = false
      })
      .addCase(overviewAsyncActions.GetAccountInformationV3TC.fulfilled, (state, action) => {
        state.isLoadingMargin = false
        state.accountV3 = action.payload as AccountInfoV3
      })

      .addCase(overviewAsyncActions.SetPositionMarginTC.pending, (state, action) => {
        state.isLoadingMargin = true
      })
      .addCase(overviewAsyncActions.SetPositionMarginTC.rejected, (state, action) => {
        state.isLoadingMargin = false
        state.isolatedMargin = {
          isMarginSet: true,
          isMarginSuccess: false,
        }
      })
      .addCase(overviewAsyncActions.SetPositionMarginTC.fulfilled, (state, action) => {
        state.isLoadingMargin = false

        if (action.payload.accountType === 'BYBIT_UNIFIED' && action.payload.retCode !== 0) {
          state.isolatedMargin = {
            isMarginSet: true,
            isMarginSuccess: false,
          }

          return
        }

        state.isolatedMargin = {
          isMarginSet: true,
          isMarginSuccess: true,
        }
      })

      .addCase(overviewAsyncActions.GetBinanceConvertExchangeInfoTC.pending, (state, action) => {
        state.binanceConvert.isLoadingCoins = true
      })
      .addCase(overviewAsyncActions.GetBinanceConvertExchangeInfoTC.rejected, (state, action) => {
        state.binanceConvert.isLoadingCoins = false
      })
      .addCase(overviewAsyncActions.GetBinanceConvertExchangeInfoTC.fulfilled, (state, action) => {
        state.binanceConvert.isLoadingCoins = false
        state.binanceConvert.coins = action.payload
      })

      .addCase(overviewAsyncActions.GetConvertQuoteTC.pending, (state, action) => {
        state.binanceConvert.isLoadingPreview = true
      })
      .addCase(overviewAsyncActions.GetConvertQuoteTC.rejected, (state, action) => {
        state.binanceConvert.isLoadingPreview = false
        state.binanceConvert.previewReq.isPreviewReq = true
        state.binanceConvert.previewReq.isPreviewSuccess = false
        const error = action.payload?.fieldsErrors ? action.payload?.fieldsErrors[0].message : 'error'
        state.binanceConvert.previewReq.error = error
      })
      .addCase(overviewAsyncActions.GetConvertQuoteTC.fulfilled, (state, action) => {
        state.binanceConvert.isLoadingPreview = false
        state.binanceConvert.previewReq.isPreviewReq = true
        state.binanceConvert.previewReq.isPreviewSuccess = true
        state.binanceConvert.previewInfo = action.payload
      })

      .addCase(overviewAsyncActions.PostConvertTC.pending, (state, action) => {
        state.binanceConvert.isLoadingConvert = true
      })
      .addCase(overviewAsyncActions.PostConvertTC.rejected, (state, action) => {
        state.binanceConvert.isLoadingConvert = false
        state.binanceConvert.convertReq.isConvertReq = true
        const error = action.payload?.fieldsErrors ? action.payload?.fieldsErrors[0].message : 'error'
        state.binanceConvert.convertReq.error = error
      })
      .addCase(overviewAsyncActions.PostConvertTC.fulfilled, (state, action) => {
        state.binanceConvert.isLoadingConvert = false
        state.binanceConvert.convertReq.isConvertReq = true
        state.binanceConvert.convertReq.isConvertSuccess = true
      })
  },
})

export const overviewActions = {
  ...slice.actions,
  ...overviewAsyncActions,
}

export const overviewReducer = slice.reducer

const sortItems = <T extends Record<string, string | number | boolean>>(
  positions: T[] = [],
  sortingField: string,
  isAscending: boolean
) => {
  const sorted = positions.sort((a, b) => {
    const itemA = a[sortingField]
    const itemB = b[sortingField]

    if (isAscending) {
      return itemA > itemB ? -1 : 1
    }

    return itemA > itemB ? 1 : -1
  })

  if (sortingField === 'status') {
    return sorted.sort((a, b) => {
      const statusA = a['status']
      const statusB = b['status']

      if (!checkIsStatusKey(statusA) || !checkIsStatusKey(statusB)) {
        return 0
      }

      const itemA = statusSortingOrder[statusA]
      const itemB = statusSortingOrder[statusB]

      if (isAscending) {
        return itemA > itemB ? -1 : 1
      }

      return itemA > itemB ? 1 : -1
    })
  }

  return sorted
}

/** should be reversed order to display correctly */
const statusSortingOrder: Record<OverviewOpenOrder['status'], number> = {
  NEW: 2,
  PARTIALLY_FILLED: 1,
  FILLED: 0,
  CANCELED: 3,
  EXPIRED: 3,
  EXPIRED_IN_MATCH: 3,
  PENDING_CANCEL: 3,
  REJECTED: 3,
}

const checkIsStatusKey = (key: string | number | boolean): key is OverviewOpenOrder['status'] =>
  typeof key === 'string' && key in statusSortingOrder
