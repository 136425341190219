import { AccountInfo, tigerxRestService } from '@/services/tigerx'
import { useActions, useAppSelector } from '@/utils'
import { overviewActions } from '@/redux/overview/overview.slice'
import { useEffect, useRef } from 'react'
import { TOrderUpdateTuple, TPositionsUpdateTuple } from '@/redux/overview/overview.types'
import { tigerxDataMappers } from '@/services/tigerx/mappers'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { tigerXActions } from '@/redux/tigerX/tigerX.slice'
import { TIGER_X_POLLING_INTERVAL } from './useWalletPageService'
import { IExchangeProviderDataPoller } from '../types/types'

export const useOrdersPageService = (
  instrument: ETigerXInstruments.BINANCE_X | ETigerXInstruments.OKX_X
): IExchangeProviderDataPoller => {
  const { setPositionsUpdate, setOrdersUpdate } = useActions(overviewActions)
  const pollingInterval = useRef<ReturnType<typeof setInterval> | null>(null)
  const { setOrdersStatus, setPositionsStatus } = useActions(tigerXActions)
  const intrumentType = instrument === ETigerXInstruments.BINANCE_X ? 'BINANCE_X' : 'OKX_X'
  const { symData } = useAppSelector(state => state.tigerX)
  const { account, isTokenLoaded } = useAppSelector(state => state.tigerX)
  const accountRef = useRef<AccountInfo[] | null>()
  accountRef.current = account

  const loadOrdersData = async () => {
    try {
      const results = await Promise.allSettled([
        tigerxRestService.getOrdersRecursive({ page: 1 }, intrumentType),
        tigerxRestService.getAlgoOrdersRecursive({ page: 1 }, intrumentType),
      ])

      const orders = results[0].status === 'fulfilled' ? results[0].value : []
      const algoOrders = results[1].status === 'fulfilled' ? results[1].value : []

      const updatedOrders: TOrderUpdateTuple[] = [
        [
          instrument,
          {
            ordersArray: [
              ...orders?.map(sourceOrder => {
                const sym = symData && symData[sourceOrder.sym as any].originalSymbol

                return tigerxDataMappers.orderMapper(sourceOrder, instrument, sym as any)
              }),
              ...algoOrders?.map(sourceOrder => {
                const sym = symData && symData[sourceOrder.sym as any].originalSymbol

                return tigerxDataMappers.orderMapper(sourceOrder, instrument, sym as any)
              }),
            ],
          },
        ],
      ]
      setOrdersUpdate(updatedOrders)
      setOrdersStatus('succeeded')
    } catch (error) {
      setOrdersStatus('failed')
    }
  }

  const loadPositions = async () => {
    try {
      const positionsData = await tigerxRestService.getPositionsRecursive({})
      const positions =
        instrument === ETigerXInstruments.BINANCE_X
          ? positionsData.filter(position => position.sym.includes('BINANCE'))
          : positionsData.filter(position => position.sym.includes('OKX'))

      let accountBinance: AccountInfo | undefined, accountOkx: AccountInfo | undefined

      if (accountRef.current) {
        accountBinance = accountRef.current.find(account => {
          return account.exchangeType === 'BINANCE'
        })
        accountOkx = accountRef.current.find(account => {
          return account.exchangeType === 'OKX'
        })
      }

      const totalMarginBalance: string =
        instrument === ETigerXInstruments.BINANCE_X
          ? accountBinance?.availableMargin || ''
          : accountOkx?.availableMargin || ''
      const unrealizedPnl: string =
        instrument === ETigerXInstruments.BINANCE_X ? accountBinance?.upnl || '' : accountOkx?.upnl || ''

      const positionsPayloadTuple: TPositionsUpdateTuple[] = [
        [
          instrument,
          {
            positionsArray: positions.map(positionSource => {
              const sum = symData && symData[positionSource.sym as any].originalSymbol
              return tigerxDataMappers.positionMapper(positionSource, instrument, sum as any)
            }) as any,
            totalMarginBalance: totalMarginBalance,
            unrealizedPnl: unrealizedPnl,
          },
        ],
      ]

      setPositionsUpdate(positionsPayloadTuple)
      setPositionsStatus('succeeded')
    } catch (err) {
      setPositionsStatus('failed')
    }
  }

  const loadData = async () => {
    try {
      loadOrdersData()
      loadPositions()
    } catch (error) {}
  }

  const startLoadDataPolling = () => {
    if (pollingInterval.current) {
      return
    }
    loadData()
    pollingInterval.current = setInterval(() => {
      loadData()
    }, TIGER_X_POLLING_INTERVAL)
  }

  const stopLoadDataPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current)
      pollingInterval.current = null
    }
  }
  useEffect(() => {
    return () => stopLoadDataPolling()
  }, [])

  return { loadData, startDataPolling: startLoadDataPolling, stopDataPolling: stopLoadDataPolling }
}
