import { BYBIT_POLLING_INTERVAL, EXCHANGE_ACCOUNTS_MAPPING } from '@/core/constants'
import { appActions } from '@/redux/app/app.slice'
import { overviewActions } from '@/redux/overview/overview.slice'
import { TOrderUpdateTuple, TPositionsUpdateTuple } from '@/redux/overview/overview.types'
import { EBybitInstruments, bybitRestService } from '@/services/bybit'
import { BybitInstruments } from '@/services/bybit/instruments'
import { bybitDataMappers } from '@/services/bybit/mappers'
import { getWalletBalanceWrap } from '@/services/bybit/utils'
import { useActions } from '@/utils'
import { setBalancesTimeout } from '@/utils/lib/setBalancesTimeout'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NEED_POLING_ROUTES } from '@/core/config/common'
import { useLocation } from 'react-router-dom'
import { emptyWalletBalance } from '@/providers/BybitProvider/constants'

export const BybitProvider: FC = ({ children }) => {
  const location = useLocation()
  const {
    setPositionsUpdate,
    setBalanceUpdate,
    setInstrumentInfoByBit,
    setOrdersUpdate,
    setLinearInstruments,
    setWalletBalance,
  } = useActions(overviewActions)
  const { setIsExchangeProviderInitialized, setExchangeStatus, setRequestStatus } = useActions(appActions)

  const [isInstrumentsLoaded, setIsInstrumentsLoaded] = useState(false)
  const pollingInterval = useRef<ReturnType<typeof setInterval>>()
  const balancesLoadRef = useRef<boolean>(false)
  const isFirstApiCall = useRef(true)
  const bybitInstrumentProvider = new BybitInstruments(async (params: { category: EBybitInstruments }) => {
    const res = await bybitRestService.getInstrumentsInfo(params)
    return res.data
  })
  const shouldPausePoling = useMemo(() => {
    return !NEED_POLING_ROUTES.includes(location.pathname)
  }, [location.pathname])

  const bybitLoadingPromise = useRef<Promise<any[]>>()

  useEffect(() => {
    const load = async () => {
      try {
        bybitLoadingPromise.current = Promise.all([bybitInstrumentProvider.loadInstruments()])

        bybitLoadingPromise.current?.then(res => {
          setInstrumentInfoByBit(bybitInstrumentProvider.instrumentsByType.spot)
          setLinearInstruments(bybitInstrumentProvider.instrumentsByType.linear)
          setIsInstrumentsLoaded(true)
        })
      } catch {
        setRequestStatus('failed')
      }
    }

    load()
  }, [])

  const apiCall = useCallback(async () => {
    let spotOrders,
      linearOrdersUsdt,
      linearOrdersUsdc,
      linearPositionsUsdtSource,
      linearPositionsUsdcSource,
      walletBalance

    const results = await Promise.allSettled([
      bybitRestService.getOrdersRecursive({ category: EBybitInstruments.spot }),
      bybitRestService.getOrdersRecursive({ category: EBybitInstruments.linear, settleCoin: 'USDT' }),
      bybitRestService.getOrdersRecursive({ category: EBybitInstruments.linear, settleCoin: 'USDC' }),
      bybitRestService.getPositionsRecursive({ category: EBybitInstruments.linear, settleCoin: 'USDT' }),
      bybitRestService.getPositionsRecursive({ category: EBybitInstruments.linear, settleCoin: 'USDC' }),
      getWalletBalanceWrap({ accountType: EXCHANGE_ACCOUNTS_MAPPING['BYBIT_UNIFIED'] || '' }),
    ])

    spotOrders = results[0].status === 'fulfilled' ? results[0].value : []
    linearOrdersUsdt = results[1].status === 'fulfilled' ? results[1].value : []
    linearOrdersUsdc = results[2].status === 'fulfilled' ? results[2].value : []
    linearPositionsUsdtSource = results[3].status === 'fulfilled' ? results[3].value : []
    linearPositionsUsdcSource = results[4].status === 'fulfilled' ? results[4].value : []
    walletBalance = results[5].status === 'fulfilled' ? results[5].value : emptyWalletBalance

    const shouldUpdateLinear =
      isFirstApiCall.current || results[1].status === 'fulfilled' || results[2].status === 'fulfilled'

    const shouldUpdateSpot = isFirstApiCall.current || results[0].status === 'fulfilled'

    if (walletBalance.status === 200) setExchangeStatus('succeeded')

    const linearPositionsUsdt = linearPositionsUsdtSource || []
    const linearPositionsUsdc = linearPositionsUsdcSource || []

    const unifiedBalance = walletBalance.response.result.list?.find(balance => {
      return balance.accountType === EXCHANGE_ACCOUNTS_MAPPING.BYBIT_UNIFIED
    })

    setWalletBalance(unifiedBalance)

    const totalMarginBalance = unifiedBalance?.coin
      ? bybitDataMappers.calculateMargin(unifiedBalance.coin, unifiedBalance)
      : ''

    setBalanceUpdate({
      blancesData: [
        [
          EBybitInstruments.spot,
          {
            assetsDataArray: unifiedBalance?.coin.map(bybitDataMappers.balanceMapper) || [],
            totalAssetsOutput: {
              USD: Number(unifiedBalance?.totalEquity || '0'),
            },
          },
        ],
      ],
      skipUnknownAssets: balancesLoadRef.current,
    })

    const positionsPayloadTuple: TPositionsUpdateTuple[] = [
      [
        EBybitInstruments.linear,
        {
          positionsArray: [...linearPositionsUsdc, ...linearPositionsUsdt].map(positionSource => {
            return bybitDataMappers.positionMapper(positionSource, bybitInstrumentProvider.instrumentsById.linear)
          }),
          totalMarginBalance: totalMarginBalance,
          unrealizedPnl: unifiedBalance?.totalPerpUPL,
        },
      ],
    ]

    if (bybitInstrumentProvider.instrumentsByType.linear.length > 0) {
      // map and update positions
      setPositionsUpdate(positionsPayloadTuple)
    } else {
      bybitLoadingPromise.current?.then(() => {
        setPositionsUpdate(positionsPayloadTuple)
        setIsExchangeProviderInitialized(true)
      })
    }

    const updatedOrders: TOrderUpdateTuple[] = []

    if (shouldUpdateSpot) {
      updatedOrders.push([
        EBybitInstruments.spot,
        {
          ordersArray:
            spotOrders?.map(sourceOrder => {
              return bybitDataMappers.orderMapper(sourceOrder, EBybitInstruments.spot)
            }) || [],
        },
      ])
    }

    if (shouldUpdateLinear) {
      updatedOrders.push([
        EBybitInstruments.linear,
        {
          ordersArray: [...(linearOrdersUsdt || []), ...(linearOrdersUsdc || [])].map(sourceOrder => {
            return bybitDataMappers.orderMapper(sourceOrder, EBybitInstruments.linear)
          }),
        },
      ])
    }

    setOrdersUpdate(updatedOrders)

    isFirstApiCall.current = false
  }, [])

  const start = () => {
    apiCall()
    pollingInterval.current = setInterval(() => {
      apiCall()
    }, BYBIT_POLLING_INTERVAL)
  }

  const pause = () => {
    clearInterval(pollingInterval.current)
  }

  useEffect(() => {
    if (shouldPausePoling) {
      pause()
      setIsExchangeProviderInitialized(true)
      return
    }
    if ((pollingInterval.current === undefined || !shouldPausePoling) && isInstrumentsLoaded) {
      start()
    }

    const balancesTimeout = setBalancesTimeout(balancesLoadRef)

    return () => {
      clearInterval(pollingInterval.current)
      clearTimeout(balancesTimeout)
    }
  }, [apiCall, shouldPausePoling, isInstrumentsLoaded])

  return <>{children}</>
}
