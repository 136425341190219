import style from './style.module.scss'
import { FC, useEffect, useMemo } from 'react'
import { AccountInfo } from '@/services/tigerx'
import { instrumentNames, useActions, useAppSelector } from '@/utils'
import { TabBar } from '@/ui/molecules'
import { OVERVIEW_INSTRUMENTS } from '@/core/constants/overview'
import { TInstrumentType } from '@/core/types/overview'
import { TabBarPropsType } from '@/ui/molecules/TabBar'
import { overviewActions } from '@/redux/overview/overview.slice'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'

type Props = {
  data?: AccountInfo | null
}

const OrdersTigerXTabs: FC<Props> = () => {
  const { instrumentType, exchangeInstruments } = useAppSelector(state => state.overview)

  const { setInstrumentType } = useActions(overviewActions)
  const tabs = useMemo<TabBarPropsType['tabs']>(
    () =>
      exchangeInstruments.map(instrument => {
        return {
          id: instrument,
          text: instrumentNames[instrument].name,
        }
      }),
    [exchangeInstruments]
  )
  const handleTab = (tab: string) => {
    if (OVERVIEW_INSTRUMENTS.includes(tab as TInstrumentType)) {
      setInstrumentType(tab as TInstrumentType)
    }
  }

  useEffect(() => {
    setInstrumentType(ETigerXInstruments.BINANCE_X)
  }, [])

  return (
    <div className={style.tabContainer}>
      <TabBar tabs={tabs} activeTab={instrumentType} setActiveTab={handleTab} className={style.tab} />
    </div>
  )
}

export { OrdersTigerXTabs }
