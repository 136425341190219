import clsx from 'clsx'
import { t } from '@lingui/macro'
import { FC, Fragment, MouseEventHandler, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { UserAvatar } from '@/ui/atoms/UserAvatar'
import { SVG } from '@/assets/svg'
import { partnerLinkCreator, urls } from '@/router/urls'
import { useAppSelector } from '@/utils'
import style from './style.module.scss'
import { useLogout } from '@/utils/hooks/useLogout'
import { getPartnerTranslations } from '@/translations/partner'
import { PARTNERS_CONTENT } from '@/core/config/partner'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { E_TEAM_USER_MODE } from '@/redux/team/team.types'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { isExchangeOkx } from '@/utils/lib/exchange'
import { OkxSuspensionWidget } from '../OkxSuspensionWidget'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { EXCHANGES_TYPE } from '@/core/constants'
import { EPartners } from '@/redux/partner/partner.types'
import { useLanguageContext } from '@/providers/LanguageProvider'

interface IHeaderUserPopupProps {
  className?: string
}

const HeaderUserPopup: FC<IHeaderUserPopupProps> = props => {
  const { className } = props
  const { userEmail } = useAppSelector(state => state.profile)
  const { partners: partnersArray } = useAppSelector(state => state.partner)
  const { availableUsersTrading } = useAppSelector(state => state.trades)
  const { isRmAvailable, rmAvailableStatus } = useAppSelector(state => state.riskManager)
  const { exchangeType } = useExchageType()
  const { isFunctionalNavEnabled } = useExchangesDisabled()
  const { locale } = useLanguageContext()

  const riskManagerAvailable = isRmAvailable && rmAvailableStatus === 'succeeded'

  const { userData } = useTeamData()
  const partnersTranslation = useMemo(() => {
    if (partnersArray.length === 0) return
    return getPartnerTranslations()
  }, [partnersArray])
  const { userKYC } = useAppSelector(state => state.kyc)

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)
  const isBlockedKycTier = userKYC.isBlocked || userKYC.isUsaMode

  const logoutWrapper = useLogout()

  const handleLogout: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    setLogoutModalOpen(true)
  }

  const logoutUser = () => {
    logoutWrapper({ logoutParams: { returnTo: window.location.origin } })
  }

  const isTeamAllowed = (teamCode: string) => {
    const restrictedTeams = ['volkov'] // Список запрещённых команд
    return !restrictedTeams.includes(teamCode)
  }

  return (
    <div className={clsx(style.root, className)}>
      <div className={style.wrap}>
        <div className={style.info}>
          <UserAvatar size={40} />
          <div className={style.infoText}>
            <div className={style.title}>{t({ message: 'Email', id: 'core.emailMessage' })}</div>
            <div className={style.email}>{userEmail}</div>
          </div>
        </div>

        <div className={style.actions}>
          {isExchangeOkx(exchangeType) && <OkxSuspensionWidget classNameRoot={style.okxWidget} />}

          {!isBlockedKycTier && (
            <Fragment>
              <Link to={urls.profile} className={style.action}>
                <SVG.Profile.User />
                {t({ id: 'core.settings', message: `Account Settings` })}
              </Link>

              {isFunctionalNavEnabled && (
                <>
                  {/* Temprorary disabled for okx affiliate */}
                  {exchangeType !== EXCHANGES_TYPE.OKX_AFFILIATE && (
                    <>
                      {riskManagerAvailable && exchangeType !== EXCHANGES_TYPE.TIGER_X && (
                        <Link to={urls.riskManager} className={style.action}>
                          <SVG.OtherIcons.ShieldWhite className={style.shieldIcon} />
                          {t({ id: 'core.riskManager', comment: 'Risk Manager' })}
                        </Link>
                      )}

                      {availableUsersTrading?.isStatisticAvailable && exchangeType !== EXCHANGES_TYPE.TIGER_X && (
                        <Link
                          to={urls.trades}
                          className={style.action}
                          state={{
                            isProfileRedirect: true,
                          }}
                        >
                          <span className={style.icon}>
                            <SVG.Profile.Trades />
                          </span>
                          {t({ id: 'core.myTrade', message: `My Trade` })}
                        </Link>
                      )}
                      <Link to={urls.tigerToken} className={style.action}>
                        <span className={style.icon}>
                          <SVG.OtherIcons.Tiger />
                        </span>
                        {t({ id: 'core.tigerToken', message: `TIGR token` })}
                      </Link>
                      {exchangeType !== EXCHANGES_TYPE.OKX_BROKER && (
                        <Link to={urls.referrals} className={style.action}>
                          <SVG.Profile.Brilliant />
                          {t({ id: 'core.referral', message: `Referral program` })}
                        </Link>
                      )}
                    </>
                  )}

                  {exchangeType === EXCHANGES_TYPE.TIGER_X && (
                    <a
                      href={locale === 'ru' ? urls.tigerXDocs : urls.tigerXDocsEng}
                      className={style.action}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SVG.Profile.Book />
                      {t({ id: 'core.docsData', message: `Data Dock` })}
                    </a>
                  )}

                  {userData.userMode === E_TEAM_USER_MODE.manager && (
                    <Link to={urls.teamMembers} className={style.action}>
                      <SVG.Profile.Team />
                      {t({ id: 'core.teamMembers', message: `Team` })}
                    </Link>
                  )}

                  {(userData.teamManagerInfo.statisticId || userData.teamUserInfo.statisticId) &&
                    isTeamAllowed(userData.teamManagerInfo?.teamCode) && (
                      <Link to={urls.leaderBoard} className={style.action}>
                        <SVG.Profile.Cup />
                        {t({ id: 'core.leaderBoard', message: `LeaderBoard` })}
                      </Link>
                    )}

                  {/* Temprorary disabled for okx affiliate */}
                  {exchangeType !== EXCHANGES_TYPE.OKX_AFFILIATE && (
                    <Link to={urls.voucher} className={style.action}>
                      <SVG.Profile.Voucher />
                      {t({ id: 'core.voucher', message: `Voucher` })}
                    </Link>
                  )}

                  {partnersArray.map(partner => {
                    const { partnerKey: primaryKey } = partner

                    if (!PARTNERS_CONTENT[primaryKey]) {
                      return null
                    }

                    return (
                      <Link to={partnerLinkCreator(primaryKey)} className={style.action} key={primaryKey}>
                        <span className={clsx(style.icon, style.iconEnlarged)}>
                          {PARTNERS_CONTENT[primaryKey].png ? (
                            <img src={PARTNERS_CONTENT[primaryKey].png} className={style.img} alt="logo" />
                          ) : PARTNERS_CONTENT[primaryKey].icon ? (
                            PARTNERS_CONTENT[primaryKey].icon
                          ) : null}
                        </span>
                        {partnersTranslation?.brands[primaryKey]?.title
                          ? partnersTranslation?.brands[primaryKey].title
                          : null}
                      </Link>
                    )
                  })}
                </>
              )}

              <div className={style.separator} />
            </Fragment>
          )}

          <button className={style.action} onClick={handleLogout}>
            <SVG.Profile.Logout />
            {t({ id: 'core.logout', message: `Log out` })}
          </button>
        </div>
      </div>

      <InteractiveModal isOpen={isLogoutModalOpen}>
        <div className={style.logoutPopup}>
          <InteractiveModalParts.SubHeader
            text={t({ id: 'core.modal.logoutTitle', message: 'Are you sure you want to log out?' })}
          />
          <InteractiveModalParts.Button
            text={t({ id: 'core.button.logout', message: `Log out` })}
            variant={ButtonVariant.White}
            onClick={logoutUser}
          />
          <InteractiveModalParts.Button
            text={t({ id: 'core.button.cancel', message: `Cancel` })}
            variant={ButtonVariant.Primary}
            onClick={() => {
              setLogoutModalOpen(false)
            }}
          />
        </div>
      </InteractiveModal>
    </div>
  )
}

export { HeaderUserPopup }
