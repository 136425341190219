import React, { FC } from 'react'

export const TigerSmall: FC = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5695 5.34892L4.67456 0H21.6415L13.7461 5.34893C13.3905 5.58986 12.9251 5.58986 12.5695 5.34892Z"
        fill="#FFD700"
      />
      <path
        d="M26.3239 13.0911V8.47155L22.7709 10.8795C22.1901 11.2731 21.8419 11.9314 21.8419 12.6358V17.2553L25.3949 14.8474C25.9757 14.4537 26.3239 13.7955 26.3239 13.0911Z"
        fill="#FFD700"
      />
      <path
        d="M13.9065 18.9247L18.9532 24H7.3707L12.4174 18.9247C12.8286 18.5112 13.4953 18.5112 13.9065 18.9247Z"
        fill="#FFD700"
      />
      <path
        d="M4.47301 17.2498V12.6302C4.47301 11.9259 4.12481 11.2676 3.544 10.874L0 8.47211V13.0917C0 13.796 0.348207 14.4543 0.929017 14.8479L4.47301 17.2498Z"
        fill="#FFD700"
      />
      <path
        d="M26.3239 4.62007V0.000517048L15.9874 7.00585C15.0436 7.6455 14.4778 8.71517 14.4778 9.85975V14.3861C14.4778 14.9478 14.6996 15.4864 15.0946 15.8836L19.2161 20.0284V19.442H19.2144V11.9677C19.2144 11.0889 19.6502 10.2673 20.3779 9.77452L25.3949 6.37632C25.9757 5.98269 26.3239 5.32442 26.3239 4.62007Z"
        fill="#FFD700"
      />
      <path
        d="M11.8454 14.3869C11.8454 14.9486 11.6235 15.4873 11.2285 15.8844L7.10705 20.0293V14.1485L7.10052 14.1441V13.2843L7.10705 13.2887V11.9686C7.10705 11.0901 6.67147 10.2687 5.94421 9.7758L0.929017 6.37688C0.348207 5.98325 0 5.32498 0 4.62063V0.00107647L10.3357 7.00585C11.2795 7.6455 11.8454 8.71517 11.8454 9.85975V14.3869Z"
        fill="#FFD700"
      />
    </svg>
  )
}
