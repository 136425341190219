import { useEffect, useRef, useState } from 'react'
import style from './style.module.scss'
import { PersonalResults } from './components/PersonalResults/PersonalResults'
import { TotalLeaders } from './components/TotalLeaders/TotalLeaders'
import { LeaderList } from './components/LeaderList/LeaderList'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { t } from '@lingui/macro'
import { SocialLink } from './components/SocialLink/SocialLink'
import { Button, Dropdown, InteractiveModal } from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { leaderboardActions } from '@/redux/leaderboard/leaderboard.slice'
import { TypePeriod, TypeTrade } from '@/redux/leaderboard/leaderboard.types'
import { find5UsersByPosition, findById, findTop3Users } from './helpers'
import { EmptyBoard } from './components/EmptyBoard/EmptyBoard'
import { Loader } from '@/ui/kit/Loader'
import { SVG } from '@/assets/svg'
import { Share } from './components/Share/Share'
import { toPng } from 'html-to-image'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { E_TEAM_USER_MODE } from '@/redux/team/team.types'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { TypeUNION } from '@/backend/api/leaderboard.api'

export type TypePeriodOption = {
  value: TypePeriod
  label: string
}

export type TypeResultOption = {
  value: TypeUNION
  label: string
}

export const LeaderBoard = () => {
  const [personalResultPng, setPersonalResultPng] = useState<any>(null)
  const [isSharePopup, setIsSharePopup] = useState(false)
  const { userData } = useTeamData()
  const navigate = useNavigate()

  const { userKYC } = useAppSelector(state => state.kyc)
  const { spotLeaders, pnlLeaders, futureLeaders, period, isLoading, typeResult } = useAppSelector(
    state => state.leaderboard
  )
  const { GetFuturesLeaderboardTC, GetPnlLeaderboardTC, GetSpotLeaderboardTC, updatePeriod, updateType } =
    useActions(leaderboardActions)

  const personalResultRef = useRef(null)

  // Define the period
  const TYPE_PERIOD_OPTIONS: TypePeriodOption[] = [
    {
      value: TypePeriod.TODAY,
      label: t`leaderBoard.leaderList.interval.day`,
    },
    {
      value: TypePeriod.WEEK,
      label: t`leaderBoard.leaderList.interval.week`,
    },
    {
      value: TypePeriod.MONTH,
      label: t`leaderBoard.leaderList.interval.month`,
    },
  ]

  const TYPE_RESULTS_OPTIONS: TypeResultOption[] = [
    {
      value: 'percent',
      label: 'Pnl (%)',
    },
    {
      value: 'pnl',
      label: 'Pnl (USDT)',
    },
  ]

  const defaultPeriod = TYPE_PERIOD_OPTIONS.find(option => option.value === period) || TYPE_PERIOD_OPTIONS[1]
  const [selectedInterval, setSelectedInterval] = useState(defaultPeriod)
  const [selectedType, setSelectedType] = useState(TYPE_RESULTS_OPTIONS[0])

  const defaultId = userKYC.userId

  const restrictedTeams = ['volkov']
  const isManager = userData.userMode === E_TEAM_USER_MODE.manager
  const teamData = isManager ? userData.teamManagerInfo : userData.teamUserInfo

  useEffect(() => {
    if (teamData?.teamCode && restrictedTeams.includes(teamData.teamCode)) {
      navigate(urls.profile)
    }
  }, [teamData?.teamCode, navigate])

  useEffect(() => {
    if (teamData.statisticId) {
      GetFuturesLeaderboardTC({ teamId: teamData.statisticId, period, type: typeResult })
      GetPnlLeaderboardTC({ teamId: teamData.statisticId, period, type: typeResult })
      GetSpotLeaderboardTC({ teamId: teamData.statisticId, period, type: typeResult })
    }
  }, [period, teamData.statisticId, typeResult])

  const handleChangePeriod = (option: { value: TypePeriod; label: string }) => {
    updatePeriod(option.value)
    setSelectedInterval(option)
  }

  const handleChangeType = (option: { value: TypeUNION; label: string }) => {
    updateType(option.value)
    setSelectedType(option)
  }

  const openSharePopup = async () => {
    if (personalResultRef.current === null) {
      console.error('error openSharePopup LeaderBoard')
      return
    }
    try {
      const png = await toPng(personalResultRef.current)
      setPersonalResultPng(png)
      setIsSharePopup(true)
    } catch (error) {
      console.error('Ошибка при генерации изображения:', error)
    }
  }

  function closeSharePopup() {
    setIsSharePopup(false)
  }

  const title = t({
    id: 'leaderBoard.page.title',
    comment: 'Team Leaderboard',
  })

  const teamTitle = `${title} ${teamData.teamTitle}`

  if (!spotLeaders && !pnlLeaders && !futureLeaders) {
    return <div className={style.emptyWrapper}>{isLoading ? <Loader /> : <EmptyBoard title={teamTitle} />}</div>
  }

  const personalResults = {
    [TypeTrade.SPOT]: findById(spotLeaders, defaultId),
    [TypeTrade.FEATURE]: findById(futureLeaders, defaultId),
    [TypeTrade.PNL]: findById(pnlLeaders, defaultId),
  }

  const totalLeaders = findTop3Users(pnlLeaders)
  const spotLeaderList = find5UsersByPosition(spotLeaders, defaultId)
  const pnlLeaderList = find5UsersByPosition(pnlLeaders, defaultId)
  const featureLeaderList = find5UsersByPosition(futureLeaders, defaultId)

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.headerWrapp}>
          <div className={style.header}>
            <TitleH3 label={teamTitle} />
            <span className={style.header__description}>
              {t({
                id: 'leaderBoard.page.description',
                comment: 'The results are displayed for the currently selected exchanges',
              })}
            </span>
          </div>
          <div className={style.filter}>
            {t({
              id: 'leaderBoard.page.filter.showResults',
              comment: 'Show Results',
            })}
            <Dropdown
              options={TYPE_PERIOD_OPTIONS}
              selectedOption={selectedInterval}
              setSelectedOption={handleChangePeriod}
              classNameToggle={style.dropdown}
              variant="dark"
              size="medium"
            />
            <Dropdown
              options={TYPE_RESULTS_OPTIONS}
              selectedOption={selectedType}
              setSelectedOption={handleChangeType}
              classNameToggle={style.dropdown}
              variant="dark"
              size="medium"
            />
            {userData.userMode !== E_TEAM_USER_MODE.manager && (
              <Button.Primary onClick={openSharePopup} className={style.share} rightIcon={<SVG.Actions.Share />} />
            )}
          </div>
        </div>
        <div className={style.content}>
          <div className={style.resultWrapp}>
            <div ref={personalResultRef}>
              <PersonalResults
                personalResults={personalResults}
                interval={selectedInterval.value}
                isManager={isManager}
                type={selectedType.label}
              />
            </div>
            <TotalLeaders
              totalLeaders={totalLeaders}
              userId={defaultId}
              interval={selectedInterval.value}
              type={selectedType.label}
            />
          </div>
          <div className={style.leaderLists}>
            <LeaderList
              title="Spot"
              leaderList={spotLeaderList}
              interval={selectedInterval.label}
              type={selectedType.label}
            />
            <LeaderList
              title="Futures"
              leaderList={featureLeaderList}
              interval={selectedInterval.label}
              type={selectedType.label}
            />
            <LeaderList
              title="PnL"
              leaderList={pnlLeaderList}
              interval={selectedInterval.label}
              type={selectedType.label}
            />
          </div>
        </div>
        <div className={style.linkWrapp}>
          <SocialLink />
        </div>
      </div>
      <InteractiveModal isOpen={isSharePopup}>
        <Share onClose={closeSharePopup} img={personalResultPng} />
      </InteractiveModal>
    </>
  )
}
