import clsx from 'clsx'
import styles from './style.module.scss'
import { SVG } from '@/assets/svg'
import { PersonalResultUser, formatNumberWithCommas } from '@/pages/LeaderBoard/helpers'
import { FormatNumberDotWithTooltip } from '@/ui/molecules/FormatNumberDotWithTooltip/FormatNumberWithTooltip'
import { CopyIcon } from '@/ui/kit'

type Props = {
  item: PersonalResultUser
  isActive?: boolean
}

export const LeaderItem = ({ item, isActive }: Props) => {
  const getPosition = (position: number) => {
    switch (position) {
      case 1:
        return <SVG.LeaderBoard.FirstPlace />
      case 2:
        return <SVG.LeaderBoard.SecondPlace />
      case 3:
        return <SVG.LeaderBoard.ThirdPlace />
      default:
        return position
    }
  }

  return (
    <div
      className={clsx(styles.leaderItem, {
        [styles.active]: isActive,
        [styles.firstPlace]: item.position === 1,
        [styles.secondPlace]: item.position === 2,
        [styles.thirdPlace]: item.position === 3,
      })}
    >
      <div className={styles.place}>{getPosition(item.position)}</div>
      <div className={styles.user}>
        <FormatNumberDotWithTooltip value={item.name} qtyChars={4} />
        <div className={styles.copy}>
          <CopyIcon text={item.name} />
        </div>
      </div>
      <div className={styles.result}>{formatNumberWithCommas(Number(item.value))}</div>
    </div>
  )
}
