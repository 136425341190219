import { TigerXConfig } from './tigerx/tigerx.config'

export const exchangeRegistry: Record<string, any> = {
  tigerx: {
    ...TigerXConfig,
  },
}

// Извлекаем типы доступных бирж из фабрики и добавляем их в список
export const typeExchanges = Object.values(exchangeRegistry)
  .filter(config => config.isAvailable) // Проверяем доступность
  .map(config => config.type) // Извлекаем типы
