import { EOkxInstruments } from '@/services/okx'
import { EAccountType } from '@tigertrade/binance-ts'
import { SVG } from '@/assets/svg'
import { TAccountType, TInstrumentType } from '@/core/types/overview'
import { EBybitInstruments } from '@/services/bybit/types'
import { exchangeRegistry, typeExchanges } from '@/exchanges/exchangeFactory'

export enum EXCHANGES_TYPE {
  BINANCE_BROKER_FUTURE = 'BINANCE_BROKER_FUTURE',
  OKX_BROKER = 'OKX_BROKER',
  BYBIT_BROKER = 'BYBIT_BROKER',
  BINANCE_VIP = 'BINANCE_VIP',
  OKX_AFFILIATE = 'OKX_AFFILIATE',
  TIGER_X = 'TIGER_X',
}

export const EXCHANGES_AVAILABLE = [
  'BINANCE_BROKER_FUTURE',
  'BINANCE_VIP',
  'OKX_BROKER',
  'BYBIT_BROKER',
  'OKX_AFFILIATE',
  ...typeExchanges,
] as const

export type TExchangesAvailable = (typeof EXCHANGES_AVAILABLE)[number]

export const EXCHANGE_NAME: Record<TExchangesAvailable, string> = {
  BINANCE_BROKER_FUTURE: 'Binance',
  OKX_BROKER: 'OKX',
  BYBIT_BROKER: 'Bybit',
  BINANCE_VIP: 'Binance VIP',
  OKX_AFFILIATE: 'OKX Affiliate',
}

export const EXCHANGE_ICON: Record<TExchangesAvailable, any> = {
  BINANCE_BROKER_FUTURE: SVG.Settings.Binance,
  OKX_BROKER: SVG.Settings.Okx,
  BYBIT_BROKER: SVG.Settings.Bybit,
  BINANCE_VIP: SVG.Settings.Binance,
  OKX_AFFILIATE: SVG.Settings.Okx,
}

export const EXCHANGE_SMALL_ICON: Record<TExchangesAvailable, any> = {
  BINANCE_BROKER_FUTURE: SVG.Settings.BinanceSmall,
  OKX_BROKER: SVG.Settings.OkxSmall,
  BYBIT_BROKER: SVG.Settings.BybitSmall,
  BINANCE_VIP: SVG.Settings.BinanceSmall,
  OKX_AFFILIATE: SVG.Settings.OkxSmall,
}

export const INSTRUMENTS_BY_EXCHANGE: Record<TExchangesAvailable, TInstrumentType[]> = {
  BINANCE_BROKER_FUTURE: [EAccountType.SPOT, EAccountType.USDT_FUTURE, EAccountType.COIN_FUTURE],
  OKX_BROKER: [EAccountType.SPOT, EOkxInstruments.SWAP],
  BYBIT_BROKER: [EBybitInstruments.spot, EBybitInstruments.linear],
  BINANCE_VIP: [EAccountType.SPOT, EAccountType.USDT_FUTURE, EAccountType.COIN_FUTURE],
  OKX_AFFILIATE: [EAccountType.SPOT, EOkxInstruments.SWAP],
}

export const ACCOUNTS_BY_EXCHANGE: Record<TExchangesAvailable, TAccountType[]> = {
  BINANCE_BROKER_FUTURE: ['BINANCE_SPOT', 'BINANCE_FUTURE', 'BINANCE_FUTURE_COIN'],
  OKX_BROKER: ['OKX_TRADING'],
  BYBIT_BROKER: ['BYBIT_UNIFIED'],
  BINANCE_VIP: ['BINANCE_SPOT', 'BINANCE_FUTURE', 'BINANCE_FUTURE_COIN'],
  OKX_AFFILIATE: ['OKX_TRADING'],
}

export const EXCHANGE_ACCOUNTS_MAPPING: Partial<Record<TAccountType, string>> = {
  BYBIT_UNIFIED: 'UNIFIED',
}

// Динамическое обновление перечисления констант из фабрики
Object.keys(exchangeRegistry).forEach(key => {
  const config = exchangeRegistry[key]

  // Обновляем EXCHANGE_NAME
  if (!(config.type in EXCHANGE_NAME)) {
    EXCHANGE_NAME[config.type as TExchangesAvailable] = config.name
  }

  // Дополняем EXCHANGE_ICON
  if (!(config.type in EXCHANGE_ICON)) {
    EXCHANGE_ICON[config.type as TExchangesAvailable] = config.icon
  }

  // Дополняем EXCHANGE_SMALL_ICON
  if (!(config.type in EXCHANGE_SMALL_ICON)) {
    EXCHANGE_SMALL_ICON[config.type as TExchangesAvailable] = config.smallIcon
  }

  // Дополняем INSTRUMENTS_BY_EXCHANGE
  if (!(config.type in INSTRUMENTS_BY_EXCHANGE)) {
    INSTRUMENTS_BY_EXCHANGE[config.type as TExchangesAvailable] = config.instruments
  }

  // Дополняем ACCOUNTS_BY_EXCHANGE
  if (!(config.type in ACCOUNTS_BY_EXCHANGE)) {
    ACCOUNTS_BY_EXCHANGE[config.type as TExchangesAvailable] = config.accounts
  }
})

export const EXCHANGE_REST_HEADER_NAME = 'X-Exchange-Type'

export const OKX_POLLING_INTERVAL = 3000

export const EXCHANGE_TYPE_SELECTOR = 'exchangeType'

export const BYBIT_POLLING_INTERVAL = 3000

export const BINANCE_AUTH_HEADER_NAME = 'X-MBX-APIKEY'

export const BINANCE_PARAMS_FOR_SIGNATURE = ['timestamp', 'recvWindow', 'signature'] // binance requires correct params order

export const FILTER_HISTORY_DATE_TO = new Date()
export const FILTER_HISTORY_DATE_FROM = new Date(new Date().setMonth(new Date().getMonth() - 3))
export const FILTER_HISTORY_DATE_FROM_SEVEN_DAYS = new Date(new Date().setDate(new Date().getDate() - 7))

export const FILTER_INITIAL_TIME = '00:00:00'
export const FILTER_INITIAL_TIME_END = '23:59:59'

export const OKX_DISABLE_DATE = new Date(Date.UTC(2024, 6))
