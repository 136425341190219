import { TAccountType, TInstrumentType } from '@/core/types/overview'
import { ETigerXAccountOriginal, ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { EBybitInstruments } from '@/services/bybit/types'
import { EOkxInstruments } from '@/services/okx'
import { EAccountType } from '@tigertrade/binance-ts'

/** Helper to have all names of accounts in one place */
export const instrumentNames: Record<TInstrumentType | ETigerXAccountOriginal, { name: string; id: TInstrumentType }> =
  {
    USDT_FUTURE: {
      id: EAccountType.USDT_FUTURE,
      name: 'Futures USDⓈ-M',
    },
    COIN_FUTURE: {
      id: EAccountType.COIN_FUTURE,
      name: 'Futures COIN-M',
    },
    SPOT: {
      id: EAccountType.SPOT,
      name: 'Spot',
    },
    SWAP: {
      id: EOkxInstruments.SWAP,
      name: 'Swap',
    },
    spot: {
      id: EBybitInstruments.spot,
      name: 'Spot',
    },
    linear: {
      id: EBybitInstruments.linear,
      name: 'Perpetual USDⓈ',
    },
    OKX_X: {
      id: ETigerXInstruments.OKX_X,
      name: ' X OKX',
    },
    BINANCE_X: {
      id: ETigerXInstruments.BINANCE_X,
      name: 'X Binance',
    },
    BINANCE: {
      id: ETigerXInstruments.BINANCE_X,
      name: 'X Binance',
    },
    OKX: {
      id: ETigerXInstruments.OKX_X,
      name: ' X OKX',
    },
  }

export const accountNames: Record<TAccountType, { name: string; id: TAccountType }> = {
  BINANCE_SPOT: {
    id: 'BINANCE_SPOT',
    name: 'Spot',
  },
  BINANCE_FUTURE: {
    id: 'BINANCE_FUTURE',
    name: 'Futures USDⓈ-M',
  },
  BINANCE_FUTURE_COIN: {
    id: 'BINANCE_FUTURE_COIN',
    name: 'Futures COIN-M',
  },
  OKX_TRADING: {
    id: 'OKX_TRADING',
    name: 'Trading',
  },
  BYBIT_UNIFIED: {
    id: 'BYBIT_UNIFIED',
    name: 'Unified Trading Account',
  },
  TIGER_X_BINANCE: {
    id: 'TIGER_X_BINANCE',
    name: 'X Binance',
  },
  TIGER_X_OKX: {
    id: 'TIGER_X_OKX',
    name: 'X OKX',
  },
}
