import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from '@/redux/tigerX/tigerX.state'
import { tigerXAsyncActions } from './tigerX.actions'
import { Status } from '@/backend/types'
import { AccountInfo, ApiResponse } from '@/services/tigerx'

export const slice = createSlice({
  name: `[Tiger Trade Account TigerX]`,
  initialState,
  reducers: {
    setOrdersStatus(state, action: PayloadAction<Status>) {
      state.accountStatus = action.payload
    },
    setPositionsStatus(state, action: PayloadAction<Status>) {
      state.accountStatus = action.payload
    },
    setIsTokenLoaded(state, action: PayloadAction<boolean>) {
      state.isTokenLoaded = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        tigerXAsyncActions.GetTigerXAccount.fulfilled,
        (state, action: PayloadAction<ApiResponse<AccountInfo[]>>) => {
          state.accountStatus = 'succeeded'
          state.account = action.payload.data
        }
      )
      .addCase(tigerXAsyncActions.GetTigerXAccount.pending, (state, action) => {
        state.accountStatus = 'loading'
      })
      .addCase(tigerXAsyncActions.GetTigerXAccount.rejected, (state, action) => {
        state.accountStatus = 'failed'
      })
      .addCase(tigerXAsyncActions.GetSym.fulfilled, (state, action: any) => {
        state.symInfoStatus = 'succeeded'
        state.symData = action.payload?.data
      })
      .addCase(tigerXAsyncActions.GetSym.pending, (state, action) => {
        state.symInfoStatus = 'loading'
      })
      .addCase(tigerXAsyncActions.GetSym.rejected, (state, action) => {
        state.symInfoStatus = 'failed'
      })
  },
})

export const tigerXActions = {
  ...slice.actions,
  ...tigerXAsyncActions,
}

export const tigerXReducer = slice.reducer
