import { FC, memo, useEffect, useMemo } from 'react'
import { t } from '@lingui/macro'
import { useNavigate } from 'react-router-dom'
import { CashbackType } from '@/backend/api'
import { Button } from '@/ui/kit'
import { cashbackActions } from '@/redux/cashback/cashback.slice'
import { formatNumber, useActions, useAppSelector } from '@/utils'
import { formatDate } from '@/utils/lib/formatDate'
import { urls } from '@/router/urls'
import style from './style.module.scss'
import { useCashbackData } from '@/utils/hooks/data/useCashbackData'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { isExchangeBinance, isExchangeOkx } from '@/utils/lib/exchange'
import { EXCHANGES_TYPE } from '@/core/constants'

type CashbackRightBarHeaderProps = {
  cashback: CashbackType
}

const CashbackRightBarHeader: FC<CashbackRightBarHeaderProps> = props => {
  const { cashback } = props
  const cashbackData = useCashbackData()

  const navigate = useNavigate()
  const { GetCashbackTC, GetCashbackCalculatedTimeTC } = useActions(cashbackActions)
  const { nextCalculationTime, prevCalculationTime } = useAppSelector(state => state.cashback)
  const { exchangeType } = useExchageType()

  const hasCashback = useMemo(() => {
    return Number(Number(cashback.volumeLast30Days).toFixed(0)) > 0
  }, [cashback.volumeLast30Days])

  useEffect(() => {
    if (!hasCashback) return

    if (nextCalculationTime && new Date(nextCalculationTime) < new Date()) {
      GetCashbackTC()
    }

    if (!nextCalculationTime || new Date(nextCalculationTime) < new Date()) {
      GetCashbackCalculatedTimeTC()
    }
  }, [hasCashback, nextCalculationTime, GetCashbackTC, GetCashbackCalculatedTimeTC])

  if (hasCashback) {
    return (
      <div className={style.cashbackRightBarHeader}>
        <div className={style.top}>
          <div className={style.left}>
            {isExchangeBinance(exchangeType) && (
              <>
                <span>{t({ id: 'cashbackPage.title', message: 'Saved today:' })} </span>
                <span className={style.noWrap}>
                  <strong data-testid={DataTestIds.CashbackToday}>{cashbackData.cashbackTodayValueRounded}</strong>
                  <span className={style.symbol}>&nbsp;USDT</span>
                </span>
              </>
            )}
            {!isExchangeBinance(exchangeType) && (
              <>
                <span>{t({ id: 'cashbackPage.titleNoCommission', message: 'Saved commision' })} </span>
              </>
            )}
          </div>
          <div className={style.right}>
            <div className={style.lastCalculated}>
              <div>
                {isExchangeBinance(exchangeType) &&
                  t({ id: 'cashbackPage.calculatedEveryHour', message: 'Data is updated every hour' })}
                {isExchangeOkx(exchangeType) &&
                  t({
                    id: 'cashbackPage.calculatedEveryDat',
                    message: 'Data is updated once a day for the previous day',
                  })}
              </div>
              {isExchangeBinance(exchangeType) && prevCalculationTime && (
                <div>
                  <span className={style.accent}>{t({ id: 'cashbackPage.lastUpdate', message: 'Last update:' })} </span>
                  {formatDate(prevCalculationTime || '')}
                </div>
              )}
            </div>
            <Button.Primary
              onClick={() => navigate(urls.how)}
              className={style.work}
              label={t({ id: 'core.howItWorks', message: 'How it works?' })}
            />
          </div>
        </div>
        <span className={style.description}>
          {t({ id: 'cashbackPage.description.message-1', message: "You'll be automatically rewarded" })}{' '}
          {formatNumber(cashback.cashbackPercentToday)}%{' '}
          {t({ id: 'cashbackPage.description.message-2', message: 'from trading commission by the end of the day' })}
        </span>
      </div>
    )
  }
  return (
    <div className={style.cashbackRightBarHeader}>
      <div className={style.top}>
        <div className={style.left}>
          <span>{t({ message: 'Trade & reduce commissions', id: 'core.tradeReduce' })}</span>
        </div>
        {/* Disabled button P2-283 */}
        {exchangeType === EXCHANGES_TYPE.TIGER_X && (
          <Button.Primary
            onClick={() => navigate(urls.how)}
            className={style.work}
            label={t({ message: `How it works?`, id: 'core.howItWorks' })}
          />
        )}
      </div>
    </div>
  )
}

export default memo(CashbackRightBarHeader)
