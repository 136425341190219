import { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

interface IProps {
  icon: ReactNode
  text: string | React.ReactNode
}

const ApiKeyInfoBlocksItem: FC<IProps> = props => {
  const { icon, text } = props

  return (
    <div className={styles.root}>
      {icon}
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export { ApiKeyInfoBlocksItem }
