import { TOkxOrderSource } from '@/core/types/overview'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { EBybitInstruments } from '@/services/bybit/types'
import { EOkxInstruments, EOkxOrderType } from '@/services/okx'
import { EAccountType } from '@tigertrade/binance-ts'

export const OVERVIEW_INSTRUMENTS = [
  EAccountType.SPOT,
  EAccountType.COIN_FUTURE,
  EAccountType.USDT_FUTURE,
  EOkxInstruments.SWAP,
  EBybitInstruments.spot,
  EBybitInstruments.linear,
  ETigerXInstruments.BINANCE_X,
  ETigerXInstruments.OKX_X,
] as const

export const OVERVIEW_ACCOUNTS = [
  'BINANCE_SPOT',
  'BINANCE_FUTURE',
  'BINANCE_FUTURE_COIN',
  'OKX_TRADING',
  'BYBIT_UNIFIED',
  'TIGER_X_BINANCE',
  'TIGER_X_OKX',
] as const

export const OVERVIEW_OKX_ORDER_SOURCE = ['SIMPLE', 'ALGO', 'ADVANCED_ALGO'] as const

export const OVERVIEW_OKX_ORDER_SOURCE_MAP: Record<EOkxOrderType, TOkxOrderSource> = {
  conditional: 'ALGO',
  oco: 'ALGO',
  move_order_stop: 'ALGO',
  iceberg: 'ADVANCED_ALGO',
  trigger: 'ADVANCED_ALGO',
  twap: 'ADVANCED_ALGO',
  fok: 'SIMPLE',
  ioc: 'SIMPLE',
  limit: 'SIMPLE',
  market: 'SIMPLE',
  mmp: 'SIMPLE',
  mmp_and_post_only: 'SIMPLE',
  optimal_limit_ioc: 'SIMPLE',
  post_only: 'SIMPLE',
}

export const OVERVIEW_TIGERX_EXCHANGE_TYPE = ['BINANCE', 'OKX'] as const

export const OVERVIEW_DELETION_PROCESSING_ITERATIONS = 3
export const OVERVIEW_BALANCES_LOAD_TIMEOUT = 8000

export const OVERVIEW_MAX_SMALL_ASSETS_IN_USDT = 1
