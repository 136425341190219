import { FC } from 'react'

type FeaturesProps = {
  className?: string
}

export const Margin: FC<FeaturesProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={20} fill="none" className={className}>
    <g clipPath="url(#a)">
      <path
        fill="#FAFAFA"
        d="M12.434 5.601v8.4H11.33V7.437l-2.748 4.572h-.144L5.69 7.449v6.552H4.574v-8.4h1.26l2.676 4.44 2.664-4.44h1.26Z"
      />
    </g>
    <rect width={16} height={19} x={0.5} y={0.5} stroke="#37363E" rx={3.5} />
    <defs>
      <clipPath id="a">
        <rect width={17} height={20} fill="#fff" rx={4} />
      </clipPath>
    </defs>
  </svg>
)
