import { FC, useEffect, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { Order } from '@/ui/organisms/Order'
import { Loader } from '@/ui/kit/Loader'
import { useAppSelector } from '@/utils'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { isExchangeTigerX } from '@/utils/lib/exchange'
import { useExchageType } from '@/utils/hooks/useExchangeType'

interface IOrdersTableProps {}

const OrdersTable: FC<IOrdersTableProps> = () => {
  const { statusOrders } = useAppSelector(state => state.overview)
  const [isInstrumentTypeChanging, setIsInstrumentTypeChanging] = useState(false)
  const [previousInstrumentType, setPreviousInstrumentType] = useState<ETigerXInstruments | null>(null)
  const { allOpenOrders, instrumentType } = useOverview()
  const { exchangeType } = useExchageType()

  const isTigerX = isExchangeTigerX(exchangeType)
  useEffect(() => {
    if (isTigerX) {
      if (previousInstrumentType && previousInstrumentType !== instrumentType) {
        setIsInstrumentTypeChanging(true)
        const timeout = setTimeout(() => {
          setIsInstrumentTypeChanging(false)
        }, 500)
        return () => clearTimeout(timeout)
      }
      setPreviousInstrumentType(instrumentType as ETigerXInstruments)
    }
  }, [instrumentType, previousInstrumentType])

  const HEADERS = useMemo(
    () => [
      t({ message: 'Ticker', id: 'core.ticker' }),
      t({ message: 'Price', id: 'core.price' }),
      t({ message: 'Size', id: 'core.size' }),
      t({ message: 'Status', id: 'core.status' }),
      '',
    ],
    []
  )

  if (statusOrders === 'loading' || isInstrumentTypeChanging) {
    return <Loader className={s.loader} dataTestId={DataTestIds.LoaderOrders} />
  }

  if (!allOpenOrders.length) {
    return (
      <div>
        <div className={s.empty} data-testid={DataTestIds.OPEmptyOrders}>
          {t({ message: 'You have no submitted orders', id: 'orders.noOrders' })}
        </div>
      </div>
    )
  }

  return (
    <div className={s.root}>
      <div className={s.header}>
        {HEADERS.map(header => (
          <div key={header}>{header}</div>
        ))}
      </div>

      {allOpenOrders?.map(order => (
        <Order.Table key={order.orderId} {...order} />
      ))}
    </div>
  )
}

export { OrdersTable }
