import { TUserPositionSideMode } from '@tigertrade/binance-ts'
import { Status } from '@/backend/types'
import { TLanguage } from '@/core/constants'
import { FieldErrorType } from '@/types'
import { IMetadata, TReset2FA } from '@/backend/models/ProfileDTO'

export type InitialState = {
  _metaProfileLocale: TLanguage | null
  userEmail?: string
  tigerTradeId?: string
  language: TLanguage
  bnbBurnStatus: Status
  spotBNBBurn?: boolean
  multiAssetsMarginStatus: Status
  multiAssetsMargin?: boolean
  bnbDustStatus: Status
  loginTwoFaToggleStatus: Status
  verifiedWithTwoFaStatus: Status
  bnbDustEnabled?: boolean
  status: Status // handle profile fetch
  initialStatus: Status // handle profile fetch for the first time
  google: {
    enabled: boolean | undefined
    secret?: string
  }
  binanceHedge: {
    ['BINANCE_FUTURE']: TUserPositionSideMode | undefined
    ['BINANCE_COIN']: TUserPositionSideMode | undefined
  }
  loginTwoFa: boolean
  resetStateTwoFa: TReset2FA | null
  resetMailStatus: {
    isResetLoaded: boolean
    isResetSuccess: boolean
  }
  vipAccess: boolean
  errors: FieldErrorType[]
  metadata: IMetadata
  tigerXAccess: boolean
}

export enum E_AmplitudeEventName {
  VisitAccountTigerCom = 'visit accoun.tiger.com',
  ExchangeSelectionScreenViewed = 'exchange selection screen viewed',
  ExchangeSelected = 'exchange selected',
  VerificationInviteScreenViewed = 'verification invite screen viewed',
  QuestionnaireViewed = 'questionnaire viewed',
  SumsubScreenViewed = 'sumsub screen viewed',
  SumsubSubmitted = 'sumsub submited',
  KYCRejectPageOpened = 'KYC reject page opened',
  RetryPopupViewed = 'retry popup viewed',
  SuccessVerificationPopupViewed = 'success verification popup viewed',
  APIPageViewed = 'API page viewed',
  TwoFAQRViewed = '2FA QR viewed',
}
