import { Fragment, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TransactionsAPI, WithdrawPayload } from '@/backend/api'
import { getCompoundTransactionAmount, useAppSelector } from '@/utils'
import { formatDate } from '@/utils/lib/formatDate'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { GoogleAuth } from '../GoogleAuth'
import { GoogleCodeVerification } from '../GoogleCodeVerification'
import { t } from '@lingui/macro'
import { useGoogleAuthCode, useGoogleAuthSettings, useMobileSizeDetect } from '@/utils/hooks'
import { FieldErrorType } from '@/types'
import { getCryproErrors } from '@/backend/services/crypto'
import { v1 } from 'uuid'
import { urls } from '@/router/urls'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { Status } from '@/backend/types'

/** Heart of user screen switching logic! Number is used in the code, value is just the explanation */
const steps = {
  1: t({
    message: `initial state: user should confirm transaction with google auth`,
    id: 'withdrawConfirmation.step-1',
  }),
  2: t({ message: "user doesn't have google authenticator and should install it", id: 'withdrawConfirmation.step-2' }),
  3: t({
    message: 'user have google authenticator and should go to the enter code screen',
    id: 'withdrawConfirmation.step-3',
  }),
  4: t({ message: 'user is entering the code', id: 'withdrawConfirmation.step-4' }),
  5: t({ message: 'user sent transaction', id: 'withdrawConfirmation.step-5' }),
} as const

type WithdrawConfirmationProps = {
  /** withdrawal amount */
  amount: string

  /** symbol of the transferred token */
  tokenSymbol: string

  /** wallet address to receive coins */
  walletAddress: string

  /** network where withdrawal is processing */
  network: string

  /** walletId is address id */
  walletId?: string

  /** callback to fire for closing this component */
  closeModal: () => void

  subExchange?: string

  isTigerX: boolean
}

/** Google Authenticator usage */
export const WithdrawConfirmation = memo<WithdrawConfirmationProps>(
  ({ amount, network, tokenSymbol, walletAddress, closeModal, walletId, subExchange, isTigerX }) => {
    const [isMobile] = useMobileSizeDetect()
    const navigate = useNavigate()
    const { tigerTradeId } = useAppSelector(state => state.profile)
    const [step, setStep] = useState<keyof typeof steps>(2)
    const { fetchGoogleAuthSettings, isAccepted: isUserInstalledAuthenticator, settings } = useGoogleAuthSettings()
    const {
      isCorrectCodeLength,
      verify,
      cleanCode,
      isError: googleVerifyError,
      isSuccess,
      code,
      isSending,
      setCode,
    } = useGoogleAuthCode()
    const stringifiedCode = useMemo(() => {
      return code.join('')
    }, [code])
    const [errorResponseCode, setErrorResponseCode] = useState<string>()
    const errorMessage = useMemo(() => {
      return googleVerifyError
        ? t({ message: 'Wrong code', id: 'withdrawConfirmation.wrongCode' })
        : errorResponseCode
        ? getCryproErrors()[errorResponseCode] || void 0
        : void 0
    }, [googleVerifyError, errorResponseCode])

    useEffect(() => {
      if (errorResponseCode === '40009' && !isCorrectCodeLength) return setErrorResponseCode(undefined)
    }, [isCorrectCodeLength, errorResponseCode])
    const successMessage = isSuccess ? t({ message: `Correct code`, id: 'withdrawConfirmation.correctCode' }) : void 0
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [uid] = useState(v1())

    useEffect(() => {
      if (!isCorrectCodeLength || step !== 2) {
        return
      }

      verify()
    }, [isCorrectCodeLength, step])

    useEffect(() => {
      if (!isSuccess) return
      if (step === 2) {
        fetchGoogleAuthSettings()
        setStep(3)
        cleanCode()
      }
    }, [isSuccess, step])

    useEffect(() => {
      if (isUserInstalledAuthenticator) {
        setStep(3)
      }
    }, [isUserInstalledAuthenticator])

    const sendWithdrawal = useCallback(() => {
      if (googleVerifyError) {
        return
      }

      setIsSubmitDisabled(true)

      const withdrawAssetPayload: WithdrawPayload = {
        address: walletAddress,
        coin: tokenSymbol,
        amount: Number(amount),
        googleCode: stringifiedCode,
        network,
        type: 'CRYPTO',
        transactionId: uid,
        subExchange: isTigerX ? subExchange : undefined,
      }

      TransactionsAPI.withdrawAsset(
        walletId
          ? {
              ...withdrawAssetPayload,
              walletId,
            }
          : withdrawAssetPayload
      )
        .then(() => setStep(5))
        .catch(res => {
          setIsSubmitDisabled(false)
          if ('details' in res?.response?.data) {
            ;(res.response.data.details as FieldErrorType[]).forEach(error => {
              if (error.code) {
                setErrorResponseCode(error.code + '')
              }
            })
          }
        })
    }, [amount, googleVerifyError, walletId, network, stringifiedCode, tokenSymbol, walletAddress, uid])

    const isValidGoogleCode = useMemo(() => {
      if (!isMobile) return undefined
      if (errorMessage) return false
      if (successMessage) return true
      return undefined
    }, [successMessage, errorMessage, isMobile])

    return (
      <>
        {step === 1 && (
          <CommonModal
            amount={amount}
            tokenSymbol={tokenSymbol}
            walletAddress={walletAddress}
            network={network}
            closeModal={closeModal}
          >
            <InteractiveModalParts.SubHeader
              text={t({ message: 'Confirm transaction ', id: 'withdrawConfirmation.confirmTransaction' })}
            />
            <InteractiveModalParts.SubExplanation
              text={t({
                message:
                  'Before you can complete the transaction, you’ll need to confirm your account with Google Authenticator app',
                id: 'withdrawConfirmation.beforeComplete',
              })}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Confirm with Google Authenticator', id: 'withdrawConfirmation.confirmGoogleAuth' })}
              variant={ButtonVariant.Accent}
              onClick={() => setStep(2)}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Cancel', id: 'core.cancel' })}
              variant={isMobile ? ButtonVariant.Clean : ButtonVariant.White}
              onClick={closeModal}
            />
          </CommonModal>
        )}

        {step === 2 && (
          <>
            <GoogleAuth
              isOpen={true}
              header={
                <Fragment>
                  {t({ message: 'Confirm your account', id: 'withdrawConfirmation.confirm-1' })} <wbr />
                  {t({ message: 'to make the transaction', id: 'withdrawConfirmation.confirm-2' })}
                </Fragment>
              }
              secret={settings.secret || ''}
              code={code}
              setCode={setCode}
              isDisabled={isSending || false}
              errorMessage={errorMessage}
              successMessage={successMessage}
              onCloseClick={closeModal}
              isValid={isValidGoogleCode}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Cancel', id: 'core.cancel' })}
              variant={isMobile ? ButtonVariant.Clean : ButtonVariant.White}
              onClick={closeModal}
            />
          </>
        )}

        {step === 3 && (
          <CommonModal
            amount={amount}
            tokenSymbol={tokenSymbol}
            walletAddress={walletAddress}
            network={network}
            closeModal={closeModal}
          >
            <InteractiveModalParts.SubHeader
              text={t({ message: 'Confirm transaction ', id: 'withdrawConfirmation.confirmTransaction' })}
            />
            <InteractiveModalParts.SubExplanation
              text={t({
                message:
                  'Before you can complete the transaction, you’ll need to confirm your account with Google Authenticator app',
                id: 'withdrawConfirmation.beforeComplete',
              })}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Confirm with Google Authenticator', id: 'withdrawConfirmation.confirmGoogleAuth' })}
              variant={ButtonVariant.Accent}
              onClick={() => setStep(4)}
              dataTestId={DataTestIds.ModalConfirmButton}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Cancel', id: 'core.cancel' })}
              variant={isMobile ? ButtonVariant.Clean : ButtonVariant.White}
              onClick={closeModal}
              dataTestId={DataTestIds.ModalCancelButton}
            />
          </CommonModal>
        )}

        {step === 4 && (
          <CommonModal
            amount={amount}
            tokenSymbol={tokenSymbol}
            walletAddress={walletAddress}
            network={network}
            closeModal={closeModal}
          >
            <GoogleCodeVerification
              header={t({
                message: 'Enter Google Authenticator code',
                id: 'withdrawConfirmation.confirmGoogleAuth.enterCode',
              })}
              text={`${t({
                message: 'If you’re having issues, please contact support:',
                id: 'withdrawConfirmation.confirmGoogleAuth.contactSupport',
              })} ${t({
                id: 'core.supportEmail',
                message: 'broker.support@tiger.trade',
              })}`}
              code={code}
              setCode={setCode}
              isDisabled={isSending || false}
              errorMessage={errorMessage}
              successMessage={successMessage}
              isValid={isValidGoogleCode}
            />
            <InteractiveModalParts.Button
              text={`${t({ message: 'Send', id: 'core.send' })} ${getCompoundTransactionAmount(amount, tokenSymbol)}`}
              variant={ButtonVariant.Accent}
              onClick={sendWithdrawal}
              isDisabled={isSubmitDisabled}
              dataTestId={DataTestIds.ModalConfirmButton}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Cancel', id: 'core.cancel' })}
              variant={isMobile ? ButtonVariant.Clean : ButtonVariant.White}
              onClick={closeModal}
              dataTestId={DataTestIds.ModalCancelButton}
            />
          </CommonModal>
        )}

        {step === 5 && (
          <CommonModal
            amount={amount}
            tokenSymbol={tokenSymbol}
            walletAddress={walletAddress}
            network={network}
            closeModal={closeModal}
            {...(isMobile && { date: formatDate(new Date()), status: 'loading' })}
          >
            {!isMobile && <InteractiveModalParts.Status status="loading" date={formatDate(new Date())} />}

            <InteractiveModalParts.TableCopy
              left={t({ id: 'core.modal.id', message: `Tiger.Trade ID` })}
              right={tigerTradeId || ''}
              isShortenView
              dataTestId={DataTestIds.ModalTigerId}
            />

            <InteractiveModalParts.Button
              text={t({ message: 'Go back to main page', id: 'core.gobackMainPage' })}
              variant={ButtonVariant.White}
              onClick={() => navigate(urls.root)}
              dataTestId={DataTestIds.ModalReturnButton}
            />
          </CommonModal>
        )}
      </>
    )
  }
)

type CommonModalProps = {
  amount: string
  tokenSymbol: string
  walletAddress: string
  network: string
  closeModal: () => void
  children: ReactNode
  date?: string
  status?: Status
}

const CommonModal = memo<CommonModalProps>(
  ({ children, amount, tokenSymbol, walletAddress, network, closeModal, date, status }) => (
    <InteractiveModal isOpen={true}>
      <InteractiveModalParts.Icon icon="withdrawal" />
      <InteractiveModalParts.Header text={t({ message: 'Withdrawal', id: 'core.withdrawal' })} />
      <InteractiveModalParts.MainInfo text={getCompoundTransactionAmount(amount, tokenSymbol)} />

      {status && <InteractiveModalParts.Status status="loading" date={date ?? formatDate(new Date())} />}

      <InteractiveModalParts.Table
        left={t({ id: 'core.transaction.from', message: 'From' })}
        right="Spot"
        dataTestId={DataTestIds.HistoryModalFrom}
      />
      <InteractiveModalParts.TableCopy
        left={t({ id: 'core.transaction.to', message: 'To' })}
        right={walletAddress}
        isShortenView
        dataTestId={DataTestIds.HistoryModalAddrTo}
      />
      <InteractiveModalParts.Table
        left={t({ id: 'core.transaction.network', message: 'Network' })}
        right={network}
        dataTestId={DataTestIds.HistoryModalNetwork}
      />
      {children}
    </InteractiveModal>
  )
)
