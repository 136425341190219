import { SVG } from '@/assets/svg'
import { TExchangesAvailable } from '@/core/constants'
import { Dropdown } from '@/ui/kit'
import { DropDownItem } from '@/ui/kit/Dropdown/Dropdown'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { FC, Fragment, useCallback, useState } from 'react'
import style from './style.module.scss'
import { useActions } from '@/utils'
import { appActions } from '@/redux/app/app.slice'
import { useExchangesSource } from '@/utils/hooks/useExchangesSource'

type TOptionExchange = DropDownItem<TExchangesAvailable>

export const HeaderExchange: FC = () => {
  const { exchangeType } = useExchageType()
  const { setExchangeType } = useActions(appActions)

  const options = useExchangesSource()

  const [selectedOption, setSelectedOption] = useState<TOptionExchange | null>(
    options.find(option => {
      return option.value === exchangeType
    }) || null
  )
  const setSelectedCallback = useCallback((value: TOptionExchange) => {
    setSelectedOption(value)
    setExchangeType(value.value)
  }, [])

  return (
    <Fragment>
      <SVG.Additional.Union className={style.union} />
      <Dropdown
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedCallback}
        position="center"
        showSelectedItemIcon
      />
    </Fragment>
  )
}
