import { useRef, useEffect } from 'react'
import { useActions, useAppSelector } from '@/utils'
import { overviewActions } from '@/redux/overview/overview.slice'
import { tigerXActions } from '@/redux/tigerX/tigerX.slice'
import { AccountInfo, tigerxRestService } from '@/services/tigerx'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { IBalanceAsset } from '@tigertrade/binance-ts'
import BigNumber from 'bignumber.js'
import { IExchangeProviderDataPoller } from '../types/types'

export const TIGER_X_POLLING_INTERVAL = 3000

function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
async function sleep(ms: number) {
  await timeout(ms)
}

export const useWalletPageService = (): IExchangeProviderDataPoller => {
  const pollingInterval = useRef<ReturnType<typeof setInterval> | null>(null)
  const accountPollingInterval = useRef<ReturnType<typeof setInterval> | null>(null)
  const { setBalanceUpdate } = useActions(overviewActions)
  const { GetTigerXAccount } = useActions(tigerXActions)
  const { account, isTokenLoaded } = useAppSelector(state => state.tigerX)
  const accountRef = useRef<AccountInfo[] | null>()
  accountRef.current = account

  useEffect(() => {
    if (!isTokenLoaded) return
    GetTigerXAccount()

    accountPollingInterval.current = setInterval(() => {
      GetTigerXAccount()
    }, TIGER_X_POLLING_INTERVAL)

    return () => {
      if (accountPollingInterval.current) {
        clearInterval(accountPollingInterval.current)
      }
    }
  }, [isTokenLoaded])

  const loadWalletData = async () => {
    if (!accountRef.current) return
    try {
      const assetsData = await tigerxRestService.getAssets()

      const [binanceEquity, okxEquity] = accountRef.current.reduce<[number, number]>(
        /*binanceEquity, okxEquity*/ (sum, item) => {
          if (item.exchangeType === 'BINANCE') {
            return [sum[0] + parseFloat(item.equity), sum[1]]
          } else if (item.exchangeType === 'OKX') {
            return [sum[0], sum[1] + parseFloat(item.equity)]
          }
          return sum
        },
        [0, 0]
      )
      const [assetsBinance, assetsOkx] = assetsData.data.data.list.reduce<[IBalanceAsset[], IBalanceAsset[]]>(
        (acc, item) => {
          const itemFormatted: IBalanceAsset = {
            assetId: item.coin,
            assetBalance: Number(item.equity),
            quoteBalance: {
              USDT: BigNumber(item.equityValue).dp(8, 1).toNumber(),
            },
          }
          if (item.exchangeType === 'BINANCE') {
            return [[...acc[0], itemFormatted], acc[1]]
          } else if (item.exchangeType === 'OKX') {
            return [acc[0], [...acc[1], itemFormatted]]
          }
          return acc
        },
        [[], []]
      )

      setBalanceUpdate({
        blancesData: [
          [
            ETigerXInstruments.BINANCE_X,
            {
              assetsDataArray: assetsBinance,
              totalAssetsOutput: {
                USDT: Number(binanceEquity),
              },
            },
          ],
          [
            ETigerXInstruments.OKX_X,
            {
              assetsDataArray: assetsOkx,
              totalAssetsOutput: {
                USDT: Number(okxEquity),
              },
            },
          ],
        ],
      })
    } catch (error) {
      console.error('error get Wallet data:', error)
    }
  }

  const startLoadDataPolling = () => {
    if (pollingInterval.current) {
      return
    }

    loadWalletData()
    pollingInterval.current = setInterval(() => {
      loadWalletData()
    }, TIGER_X_POLLING_INTERVAL)
  }

  const stopLoadDataPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current)
      pollingInterval.current = null
    }
  }

  useEffect(() => {
    return () => stopLoadDataPolling()
  }, [])

  return { loadWalletData, startDataPolling: startLoadDataPolling, stopDataPolling: stopLoadDataPolling }
}
