import { EPositionSide } from '@tigertrade/binance-ts'

import { Order, Position } from './types'
import { getAlgoOrderPrice } from './utils'

const POSITION_SIDE_MAP: Record<string, EPositionSide> = {
  BUY: EPositionSide.LONG,
  SELL: EPositionSide.SHORT,
  NONE: '' as EPositionSide,
}
export const tigerxDataMappers = {
  // balanceMapper: (balanceCoin: WalletBalanceV5Coin): IBalanceAsset => {
  //   return {
  //     assetId: balanceCoin.coin,
  //     assetBalance: Number(balanceCoin.walletBalance),
  //     quoteBalance: {
  //       USD: Number(balanceCoin.usdValue),
  //     },
  //   }
  // },
  positionMapper: (positionSource: Position, instrumentType: any, sym: string | null) => {
    // const adlRankIndicator = (positionSource as Position & { adlRankIndicator?: number }).adlRankIndicator ?? 0
    const positionSide = Number(positionSource.positionQty) > 0 ? POSITION_SIDE_MAP['BUY'] : POSITION_SIDE_MAP['SELL']
    return {
      instrumentType: instrumentType,
      positionIdx: '',
      positionBalance: '',
      positionValue: positionSource.positionValue,
      positionMM: positionSource.positionMM,
      positionIM: '',
      size: positionSource.positionQty,
      uid: `${sym ?? positionSource.sym}-${positionSource.positionId}`,
      unrealizedPnl: positionSource.unrealizedPNL,
      quoteAsset: '',
      baseAsset: '',
      unrealizedPnlPercent: '',
      positionSideOutput: positionSide,
      // adl: adlRankIndicator,
      symbol: sym ?? positionSource.sym,
      entryPrice: positionSource.avgPrice,
      positionAmt: positionSource.positionQty,
      positionSide: positionSide,
      updateTime: Number(positionSource.updateAt),
      marginType: 'crossed',
      liquidationPrice: positionSource.liqPrice,
      exchangeType: positionSource.exchangeType,
      sourcePositionSide: positionSource.positionSide,
      sourceSymbol: positionSource.sym,
    }
  },
  orderMapper: (orderSource: Order, instrumentType: any, sym: string | null) => {
    return {
      type: '',
      status: '',
      instrumentType,
      isAlgo: orderSource.algoOrderId !== null,
      stopOrderType: 'Order',
      priceOutput: orderSource.algoOrderId ? getAlgoOrderPrice(orderSource) ?? '0' : orderSource.limitPrice,
      symbol: sym ?? orderSource.sym,
      orderId: orderSource.orderId || String(orderSource.algoOrderId),
      price: orderSource.limitPrice,
      origQty: orderSource.orderQty,
      executedQty: orderSource.executedQty,
      side: orderSource.side,
      time: Number(orderSource.createAt),
      updateTime: Number(orderSource.updateAt),
      stopPrice: '',
      businessType: orderSource.businessType,
      orderTypeOutput: orderSource.exchangeOrderType || orderSource.algoOrderType,
      sourceSymbol: orderSource.sym,
    }
  },
  // calculateMargin: (coins: WalletBalanceV5Coin[]): string => {
  //   return coins
  //     .reduce((acc, coin) => {
  //       const price = new BigNumber(coin.usdValue).dividedBy(coin.walletBalance)
  //       return acc.plus(new BigNumber(coin.availableToWithdraw).multipliedBy(price))
  //     }, BN_ZERO)
  //     .toString()
  // },
}
