import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Button, InteractiveModal, usePopperRef } from '@/ui/kit'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { Waiting } from '@/ui/atoms/Waiting'
import { OrdersTable } from '@/ui/organisms/OrdersTable'
import { PositionsTable } from '@/ui/organisms/PositionsTable'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'
import { PopupSequence } from '@/ui/organisms/PopupSequence'
import { useActions, useAppSelector, useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
// import { OrdersPageWidget } from '@/ui/organisms/OrdersPageWidget'
import { OrdersStatisticsWidget } from '@/ui/organisms/OrdersStatisticsWidget'
import clsx from 'clsx'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { IsolatedMargin } from './components/IsolatedMargin/IsolatedMargin'
import { leverageAsyncActions } from '@/redux/leverage/leverage.actions'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'
import { DangerWithTooltip } from '@/ui/molecules'
import { OrdersPageWidget } from '@/ui/organisms/OrdersPageWidget'
import { OrdersPageWidgetTigerX, OrdersTigerXTabs } from '@/ui/organisms'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { QuestionButton } from '@/ui/atoms/QuestionButton'
import { Trans } from '@lingui/react'
import { isExchangeTigerX } from '@/utils/lib/exchange'
import { isExchangeBybit } from '@/utils/lib/exchange'

const OrdersAndPositionsPC: FC = () => {
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const { exchangeType } = useExchageType()
  const {
    metadata: { isOrdersPageBalancesEnabled },
  } = useAppSelector(state => state.profile)
  const { bybitMarginType } = useAppSelector(state => state.leverage)
  const { accountStatus, ordersStatus, positionsStatus } = useAppSelector(state => state.tigerX)
  const { GetBybitAccountInfoTC } = useActions(leverageAsyncActions)
  const isTigerXExchangeType = isExchangeTigerX(exchangeType)
  const hasErrorModal = accountStatus === 'failed' || ordersStatus === 'failed' || positionsStatus === 'failed'

  useEffect(() => {
    if (isExchangeBybit(exchangeType)) GetBybitAccountInfoTC()
  }, [exchangeType])

  const {
    allOpenOrders,
    allPositions,
    isClosingOrders,
    isClosingPositions,
    cancelAllOrders,
    cancelAllPositions,
    cancelAllOrdersAndPositions,
    tigerXCloseAllLoading,
    instrumentType,
  } = useOverview()

  const [isolatedMargin, setIsolatedMargin] = useState(false)

  const hasOrders = useMemo(() => !!allOpenOrders?.length, [allOpenOrders])
  const hasPositions = useMemo(() => !!allPositions?.length, [allPositions])

  const handleCancelOrders = useCallback(
    () => (isExchangeTigerX(exchangeType) ? cancelAllOrders(instrumentType) : cancelAllOrders()),
    [cancelAllOrders, exchangeType, instrumentType]
  )

  const handleCancelPositions = useCallback(() => cancelAllPositions(), [cancelAllPositions])

  const handleCancelOrdersAndPositions = useCallback(() => cancelAllOrdersAndPositions(), [cancelAllOrdersAndPositions])

  function openIsolatedMargin() {
    setIsolatedMargin(true)
  }

  function closeIsolatedMargin() {
    setIsolatedMargin(false)
  }

  const isHasNegativeMargin = allPositions.some(item => {
    return parseFloat(item.positionBalance || '0') < 0 || parseFloat(item.isolatedMargin || '0') < 0
  })

  const isHasMarginType = exchangeType === EXCHANGES_TYPE.BYBIT_BROKER ? bybitMarginType === 'ISOLATED_MARGIN' : true
  const isHasMarginPosition =
    exchangeType === EXCHANGES_TYPE.BYBIT_BROKER
      ? isHasMarginType
      : allPositions.some(item => item.marginType === 'isolated')

  return (
    <>
      <div className={s.root}>
        <PopupSequence
          googleAuthSettings={googleAuthSettings}
          googleAuthCode={googleAuthCode}
          allowedPopups={['promomessage']}
        />

        <div className={s.heading}>
          <TitleH1 label={t({ message: 'Orders and positions', id: 'orders.heading' })} />
          {hasOrders || hasPositions ? (
            <div className={s.closeButtonContainer}>
              {isExchangeTigerX(exchangeType) && (
                <QuestionButton placement="bottom" text={<Trans id="ordersAndPositions.tigerX.closeButton.hint" />} />
              )}
              <Button.White
                label={
                  isTigerXExchangeType
                    ? t({ message: 'Close all', id: 'core.button.closeAllTigerX' })
                    : t({ message: 'Close all', id: 'core.button.closeAll' })
                }
                disabled={isClosingOrders || isClosingPositions || tigerXCloseAllLoading}
                loading={tigerXCloseAllLoading}
                onClick={handleCancelOrdersAndPositions}
                dataTestId={DataTestIds.OPCloseAllButton}
              />
            </div>
          ) : null}
        </div>
        {isTigerXExchangeType && <OrdersTigerXTabs />}
        {isOrdersPageBalancesEnabled && (
          <div
            className={clsx(s.columns, s.statistics, {
              [s.columnsBig]: isTigerXExchangeType,
            })}
          >
            <div className={s.columnLeft}>
              {isTigerXExchangeType ? <OrdersPageWidgetTigerX /> : <OrdersPageWidget />}
            </div>
            {!isTigerXExchangeType && (
              <div className={s.columnRight}>
                <OrdersStatisticsWidget />
              </div>
            )}
          </div>
        )}

        <div className={s.columns}>
          <div className={s.columnLeft}>
            <div className={s.columnHeading}>
              <TitleH3 label={t({ message: 'Orders', id: 'core.orders' })} />
              {isClosingOrders && (
                <Waiting
                  label={t({ message: 'Closing orders', id: 'orders.closingOrders' })}
                  dataTestId={DataTestIds.OPClosingOrders}
                />
              )}
              {hasOrders && !isClosingOrders && (
                <Button.Primary
                  label={t({ message: 'Close all', id: 'orders.closeAll' })}
                  rightIcon={<SVG.Additional.Close />}
                  onClick={handleCancelOrders}
                  dataTestId={DataTestIds.OPCloseAllOrdersButton}
                />
              )}
            </div>

            <OrdersTable />
          </div>
          <div className={s.columnRight}>
            <div className={s.columnHeading}>
              <div className={s.headerWrapp}>
                <TitleH3 label={t({ message: 'Positions', id: 'core.positions' })} />
                {allPositions.length > 0 && isHasMarginPosition && (
                  <div
                    className={clsx(s.margin, {
                      [s.isolatedMargin]: isHasNegativeMargin,
                    })}
                    onClick={openIsolatedMargin}
                  >
                    {isHasNegativeMargin && (
                      <DangerWithTooltip
                        text={t({
                          id: 'isolatedMargin.marginItem.dangerTooltip',
                          comment: 'Danger Tooltip',
                        })}
                      />
                    )}
                    <span>{t({ id: 'positions.isolatedMargin', comment: 'Isolated margin' })}</span>
                  </div>
                )}
              </div>
              {isClosingPositions && (
                <Waiting
                  label={t({ message: 'Closing positions', id: 'orders.closingPositions' })}
                  dataTestId={DataTestIds.OPClosingPositions}
                />
              )}
              {hasPositions && !isClosingPositions && !isTigerXExchangeType && (
                <Button.Primary
                  label={t({ comment: 'Close positions', id: 'positions.closeAll' })}
                  rightIcon={<SVG.Additional.Close />}
                  onClick={handleCancelPositions}
                  dataTestId={DataTestIds.OPCloseAllPositionsButton}
                />
              )}
            </div>
            <PositionsTable />
          </div>
        </div>
      </div>
      <InteractiveModal isOpen={isolatedMargin} className={s.modal}>
        <IsolatedMargin onClose={closeIsolatedMargin} />
      </InteractiveModal>
      <InteractiveModal isOpen={hasErrorModal}>
        <ErrorBase
          customHeader={t({ message: `Couldn’t load data`, id: 'core.notLoadData' })}
          isRefreshButtonVisible={true}
          isSupportMessage={true}
        />
      </InteractiveModal>
    </>
  )
}

export { OrdersAndPositionsPC }
