import {
  ExchangeInfoResponseDTO,
  LeverageBracketResponseDTO,
  Margin,
  PositionRiskCoinMResponseDTO,
  PositionRiskUsdMResponseDTO,
} from '@/backend/models/LeverageDTO'
import { MARGIN_TO_OKX_MARGIN } from '@/core/mappers/values'
import { LeverageBracket, LeverageBrackets, LeverageTickers, TLeverageTicker } from '@/redux/leverage/leverage.types'
import { EOkxInstruments, EOkxPositionSide, IOkxGetLeverageEstimatedInfoParams } from '@/services/okx'

export const normalizeBrackets = (leverages: LeverageBracketResponseDTO): LeverageBrackets =>
  leverages.reduce((acc, leverage) => {
    return {
      ...acc,
      [leverage.symbol]: leverage.brackets.reduce((acc: LeverageBracket[], bracket, index, brackets) => {
        const newBracket = {
          minLeverage: brackets[index + 1]?.initialLeverage + 1 || bracket.initialLeverage,
          maxLeverage: bracket.initialLeverage,
          maxPosition: bracket.notionalCap ?? bracket.qtyCap ?? 0,
        }
        acc.push(newBracket)
        return acc
      }, []),
    }
  }, {})

const findMaxPositionInBrackets = (leverage: number, brackets: LeverageBracket[] = []) =>
  brackets.find(bracket => bracket.minLeverage <= leverage && leverage <= bracket.maxLeverage)?.maxPosition

export const findMaxPosition = (max: number | string | undefined, leverage: number, brackets: LeverageBracket[]) => {
  return Number(max) || findMaxPositionInBrackets(leverage, brackets) || 0
}

export const normalizeLeverages = (
  risks: PositionRiskUsdMResponseDTO | PositionRiskCoinMResponseDTO,
  leverages: LeverageBracketResponseDTO,
  brackets: LeverageBrackets,
  exchange: ExchangeInfoResponseDTO
): LeverageTickers => {
  const state = {} as LeverageTickers

  for (const symbol of exchange?.symbols || []) {
    state[symbol.symbol] = {
      symbol: symbol.symbol,
    }
  }

  for (const risk of risks) {
    if (state[risk.symbol]) {
      const leverage = Number(risk.leverage)
      const maxPosition = Number('maxNotionalValue' in risk ? risk.maxNotionalValue : risk.maxQty)

      state[risk.symbol] = {
        ...(state[risk.symbol] || {}),
        ...{
          symbol: risk.symbol,
          leverage: leverage,
          margin: risk.marginType === Margin.Isolated ? Margin.Isolated : Margin.Crossed, // to fix difference cross / crossed
          maxPosition: findMaxPosition(maxPosition, leverage, brackets[risk.symbol]),
        },
      }
    }
  }

  for (const leverage of leverages) {
    if (state[leverage.symbol]) {
      const maxLeverage = Math.max(...leverage.brackets?.map?.(l => l.initialLeverage ?? 0))

      state[leverage.symbol] = {
        ...state[leverage.symbol],
        ...{
          symbol: leverage.symbol,
          maxLeverage,
        },
      }
    }
  }

  return state
}

export const findMaxLeverage = (tickers: TLeverageTicker[]) => {
  return Math.min(...tickers.map(t => t.maxLeverage || Infinity))
}

export const toggleActiveTickers = (current: string[], next: string[]) => {
  let newActive = [...current]

  for (const ticker of next) {
    if (newActive.includes(ticker)) {
      newActive = newActive.filter(t => t !== ticker)
    } else {
      newActive.push(ticker)
    }
  }

  return newActive
}

export const concatOkxInstruments = (instruments: string[]): string => {
  return instruments.reduce((acc, inst) => {
    return `${acc},${inst}`
  }, '')
}

export const getEstimatedLeverageParamsCreator = (ticker: TLeverageTicker): IOkxGetLeverageEstimatedInfoParams => {
  return {
    instId: ticker.symbol,
    instType: EOkxInstruments.SWAP,
    lever: ticker.leverage?.toString() || '',
    mgnMode: MARGIN_TO_OKX_MARGIN[ticker.margin || 'isolated'],
    posSide: ticker.side || EOkxPositionSide.long,
  }
}

export const getTargetLeverageForTigerX = (arr: number[], target: number) => {
  return arr.reduce((prev, curr) => {
    if (curr >= target && curr < prev) {
      return curr
    }
    return prev
  }, Infinity)
}
