import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC } from 'react'
import { SVG } from '@/assets/svg'
import { AccountOverviewPositionsView } from '@/backend/models/OverviewDTO'
import { Button } from '@/ui/kit'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { formatDate } from '@/utils/lib/formatDate'
import ADL from './ADL/ADL'
import { usePosition } from './hooks/usePosition'
import s from './style.module.scss'
import { SideIndicator } from '@/ui/kit/SideIndicator'
import { EPositionSide } from '@tigertrade/binance-ts'
import { FormatNumberDotWithTooltip } from '@/ui/molecules/FormatNumberDotWithTooltip/FormatNumberWithTooltip'
import { isExchangeTigerX } from '@/utils/lib/exchange'
import { useExchageType } from '@/utils/hooks/useExchangeType'

interface Props {
  position: AccountOverviewPositionsView
}

const PositionTab: FC<Props> = ({ position }) => {
  const { symbol, positionAmt, positionSideOutput, updateTime, adl } = position

  const { deleting, unrealizedPnl, entryPrice, closePositionHandler, liquidationPriceOutput } = usePosition(position)

  const { exchangeType } = useExchageType()

  const isTigerXExchangeType = isExchangeTigerX(exchangeType)

  return (
    <div className={clsx(s.position, s.tab, { [s.deleted]: deleting })} data-testid={DataTestIds.PositionListItem}>
      <div className={s.item}>
        <div className={s.currency}>
          <div data-testid={`${DataTestIds.PositionSymbol}-${symbol}`}>{symbol}</div>
          <SideIndicator profit={positionSideOutput === EPositionSide.LONG} label={positionSideOutput} />
        </div>

        <div className={s.description} data-testid={`${DataTestIds.PositionDate}-${symbol}`}>
          {formatDate(updateTime)}
        </div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.PositionEntryPrice}-${symbol}`}>
          <FormatNumberDotWithTooltip value={entryPrice} symbol={symbol} qtyChars={5} />
        </div>
        <div className={s.description}>{t({ message: 'Open price', id: 'core.openPrice' })}</div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.PositionAmount}-${symbol}`}>{positionAmt}</div>
        <div className={s.description}>{t({ message: 'Size', id: 'core.size' })}</div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.PositionLiquidationPrice}-${symbol}`}>
          <FormatNumberDotWithTooltip value={liquidationPriceOutput} symbol={symbol} qtyChars={5} />
        </div>
        <div className={s.description}>{t({ message: 'Liquidation price', id: 'core.liquidationPrice' })}</div>
      </div>

      {!isTigerXExchangeType ? <ADL indicator={adl ?? 0} /> : <div />}

      <div
        className={clsx(s.item, {
          [s.green]: unrealizedPnl.unPnlNumber > 0,
          [s.red]: unrealizedPnl.unPnlNumber < 0,
        })}
        data-testid={`${DataTestIds.PositionUnrealizedPnl}-${symbol}`}
      >
        {unrealizedPnl.unPnlNumberFormatted} ({unrealizedPnl.unPnlPercentFromatted})
      </div>

      <Button.Primary
        onClick={closePositionHandler}
        className={s.closeButton}
        leftIcon={<SVG.Additional.Close />}
        dataTestId={`${DataTestIds.ClosePositionButton}-${symbol}`}
      />
    </div>
  )
}

export { PositionTab }
