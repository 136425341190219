import { Status } from '@/backend/types'
import { STATISTICS_START_MOMENT } from '@/core/config/overview'
import { TExchangesAvailable } from '@/core/constants'
import { TAccountType, TInstrumentType } from '@/core/types/overview'

export const OVERVIEW_ACCOUNT_SYMBOLS_MAP: Record<
  TAccountType,
  {
    symbol: string
    selectorOutput: string
    selectorStable: string
  }
> = {
  BINANCE_SPOT: {
    symbol: 'USDT',
    selectorOutput: 'USDT',
    selectorStable: 'USDT',
  },
  BINANCE_FUTURE: {
    symbol: 'USDT',
    selectorOutput: 'USDT',
    selectorStable: 'USDT',
  },
  BINANCE_FUTURE_COIN: {
    symbol: 'BTC',
    selectorOutput: 'BTC',
    selectorStable: 'USD',
  },
  OKX_TRADING: {
    symbol: 'USD',
    selectorOutput: 'USD',
    selectorStable: 'USD',
  },
  BYBIT_UNIFIED: {
    symbol: 'USD',
    selectorOutput: 'USD',
    selectorStable: 'USD',
  },
  TIGER_X_BINANCE: {
    symbol: 'USDT',
    selectorOutput: 'USDT',
    selectorStable: 'USDT',
  },
  TIGER_X_OKX: {
    symbol: 'USDT',
    selectorOutput: 'USDT',
    selectorStable: 'USDT',
  },
}

export const OVEVIEW_EXCHANGE_SYMBOLS_MAP: Record<TExchangesAvailable, { symbol: string }> = {
  BINANCE_BROKER_FUTURE: {
    symbol: 'USDT',
  },
  BINANCE_VIP: {
    symbol: 'USDT',
  },
  BYBIT_BROKER: {
    symbol: 'USD',
  },
  OKX_BROKER: {
    symbol: 'USD',
  },
  OKX_AFFILIATE: {
    symbol: 'USD',
  },
  TIGER_X: {
    symbol: 'USDT',
  },
  TIGER_X_BINANCE: {
    symbol: 'USDT',
  },
  TIGER_X_OKX: {
    symbol: 'USDT',
  },
}

export const ACCOUNTS_BY_INSTRUMENTS: Record<TExchangesAvailable, Partial<Record<TInstrumentType, TAccountType>>> = {
  BINANCE_BROKER_FUTURE: {
    SPOT: 'BINANCE_SPOT',
    USDT_FUTURE: 'BINANCE_FUTURE',
    COIN_FUTURE: 'BINANCE_FUTURE_COIN',
  },
  OKX_BROKER: {
    SPOT: 'OKX_TRADING',
    SWAP: 'OKX_TRADING',
  },
  BYBIT_BROKER: {
    spot: 'BYBIT_UNIFIED',
    linear: 'BYBIT_UNIFIED',
  },
  BINANCE_VIP: {
    SPOT: 'BINANCE_SPOT',
    USDT_FUTURE: 'BINANCE_FUTURE',
    COIN_FUTURE: 'BINANCE_FUTURE_COIN',
  },
  OKX_AFFILIATE: {
    SPOT: 'OKX_TRADING',
    SWAP: 'OKX_TRADING',
  },
  TIGER_X: {
    BINANCE_X: 'TIGER_X_BINANCE',
    OKX_X: 'TIGER_X_OKX',
  },
}

export const INCOME_DEFAULT = {
  history: [],
  status: 'idle' as Status,
  dailyIncome: undefined,
  startMoment: STATISTICS_START_MOMENT.toString(),
}
