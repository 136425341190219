import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  Input,
  InputAndChips,
  InputSize,
  InteractiveModal,
  InteractiveModalParts,
} from '@/ui/kit'
import { useActions, useAppSelector, useBeforeunload, useGoogleAuthCode } from '@/utils/hooks'
import { hasOnlySpaces, isValidIP, useToggle } from '@/utils'

import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useCreateApiForm } from './ApiKeyCreateForm.hooks'
import { ApiKeyCreateFormProps, State } from './ApiKeyCreateForm.types'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { GoogleCodeVerification } from '@/ui/organisms/GoogleCodeVerification'
import { FieldErrorType } from '@/types'
import { apiKeyActions } from '@/redux/apiKey/apiKey.slice'
import { checkIpForAllowed } from '@/utils/lib/checkIpForAllowed'
import { DISALLOWED_IP_LIST } from '@/core/config/apiKey'
import { isExchangeBinance, isExchangeOkx, isExchangeTigerX } from '@/utils/lib/exchange'
import { errorTranslations } from '@/core/constants/errors/errorsTranslations'

export const ApiKeyCreateForm: FC<ApiKeyCreateFormProps> = props => {
  const { onSubmit, onCancel, isSubmitDisabled } = props
  const { exchangeType } = useExchageType()
  const { errors } = useAppSelector(state => state.apiKeys)
  const { setErrors } = useActions(apiKeyActions)
  const errorsTrans = errorTranslations()

  const errosMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc, error) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  const initialState = useMemo<State>(
    () => ({
      name: '',
      passphrase: '',
      ipAddresses: [] as string[],
      spotEnabled: false,
      marginEnabled: false,
      googleCode: '',
    }),
    []
  )

  const [state, setState] = useState<State>(initialState)

  const [modalIsOpen, toggleModal] = useToggle(false)

  const isDirty = useMemo(() => {
    return JSON.stringify(initialState) !== JSON.stringify(state)
  }, [initialState, state])

  useBeforeunload(isDirty)

  const { permissions, handleFieldChange, isPassphraseValid } = useCreateApiForm(state, setState)

  const googleCode = useGoogleAuthCode()

  useEffect(() => {
    handleFieldChange('googleCode')(googleCode.code.join(''))
  }, [googleCode.code])

  useEffect(() => {
    return () => {
      setErrors([])
    }
  }, [])

  const setIPAddressesValue = (val?: string[]) => {
    setState(prev => {
      if (val?.length === prev.ipAddresses?.length) return prev
      return { ...prev, ipAddresses: val || [] }
    })
  }

  const submit = useCallback(() => {
    const errors: FieldErrorType[] = []

    const { marginEnabled, ...restState } = state

    if (!state.name || hasOnlySpaces(state.name)) {
      errors.push({
        code: '',
        field: 'name',
        message: t({
          id: 'apiPage.formFields.name.error',
          message: `Come up with a name for your key`,
        }),
      })
    }

    if (isExchangeOkx(exchangeType) && !isPassphraseValid(state.passphrase)) {
      errors.push({
        code: '',
        field: 'passphrase',
        message: t({
          id: 'apiPage.formFields.passphrase.error',
          message: `At least 8 letter, an uppercase letter, a digit, a special symbol !@#$%^&+=`,
        }),
      })
    }

    if (!googleCode.isCorrectCodeLength) {
      errors.push({
        code: '',
        field: 'googleCode',
        message: t({
          id: 'services.gAuth.enter.title',
          message: 'Enter the 6-digit code below',
        }),
      })
    }

    if (errors.length > 0) {
      setErrors(errors)
      return
    }

    setErrors([])

    onSubmit?.({
      ...(isExchangeTigerX(exchangeType) ? restState : state),
      ...(isExchangeBinance(exchangeType) ? { futuresEnabled: state.spotEnabled } : {}),
      restrictionIps: state.ipAddresses,
    })
  }, [state, exchangeType, googleCode.isCorrectCodeLength, onSubmit])

  const handleNameInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submit()
    }
  }

  const handleValidate = useCallback((value: string) => {
    if (!checkIpForAllowed(value))
      return `${t({
        id: 'apiPage.formFields.ip.error.incompatible.msg-1',
        message: `Addresses`,
      })} ${DISALLOWED_IP_LIST.reduce<string>((acc, ip) => {
        return acc.length > 0 ? acc + `, ${ip}` : ip
      }, '')} ${t({
        id: 'apiPage.formFields.ip.error.incompatible.msg-2',
        message: `are not allowed`,
      })}`
    if (!isValidIP(value))
      return t({
        id: 'apiPage.formFields.ip.error.incorrect',
        message: `Incorrect IP address format`,
      })
    return null
  }, [])

  const handleCancel = useCallback(() => {
    if (isDirty) {
      toggleModal()
    } else {
      onCancel?.()
    }
  }, [isDirty, toggleModal, onCancel])

  return (
    <div>
      <label>
        <Input
          size={InputSize.Large}
          label={t({
            id: 'apiPage.formFields.name.label',
            message: `Name`,
          })}
          value={state.name}
          setValue={handleFieldChange('name')}
          errorMessage={errosMapped.name}
          placeholder={t({
            id: 'apiPage.formFields.name.placeholder',
            message: `Label your API key`,
          })}
          inputAttrs={{ onKeyDown: handleNameInputKeyDown, maxLength: 50 }}
          dataTestId={DataTestIds.ApiKeyNameTextField}
        />
      </label>

      {isExchangeOkx(exchangeType) && (
        <label>
          <Input
            size={InputSize.Large}
            label={t({
              id: 'apiPage.formFields.passphrase.label',
              message: `Passphrase`,
            })}
            value={state.passphrase}
            setValue={handleFieldChange('passphrase')}
            errorMessage={errosMapped.passphrase}
            placeholder={t({
              id: 'apiPage.formFields.passphrase.placeholder',
              message: `Enter your passphrase`,
            })}
            inputAttrs={{ onKeyDown: handleNameInputKeyDown }}
            dataTestId={DataTestIds.ApiKeyNameTextField}
          />
        </label>
      )}

      <div className={style.checkboxesWrapper}>
        {permissions.map(permissionCheckbox => {
          return (
            <Checkbox key={permissionCheckbox.selector} {...permissionCheckbox.checkbox}>
              {permissionCheckbox.children}
            </Checkbox>
          )
        })}
      </div>

      <div className={style.inputAndChipsWrapper}>
        {!isExchangeTigerX(exchangeType) && (
          <InputAndChips
            inputLabel={t({
              id: 'apiPage.formFields.ip.label',
              message: `IP addresses`,
            })}
            inputLabelHintText={
              <>
                {t({
                  id: 'apiPage.formFields.ip.message-1',
                  message: 'Add trusted IP addresses',
                })}
                <br />
                {t({
                  id: 'apiPage.formFields.ip.message-2',
                  message: 'for better security',
                })}
              </>
            }
            inputPlaceholder={t({
              id: 'apiPage.formFields.ip.add',
              message: `Add IP address (optional)`,
            })}
            values={state.ipAddresses}
            onInputChange={setIPAddressesValue}
            validate={handleValidate}
            dataTestId={DataTestIds.ApiKeyIpAddressesTextField}
          />
        )}
      </div>

      <label>
        <GoogleCodeVerification
          header={t({
            id: 'services.gAuth.enter.title',
            message: `Enter the 6-digit code below`,
          })}
          text={t({
            id: 'services.gAuth.enter.subtitle',
            message: `Input code from Google Authenticator`,
          })}
          code={googleCode.code}
          setCode={googleCode.setCode}
          isDisabled={isSubmitDisabled}
          errorMessage={errosMapped.googleCode ? errorsTrans.googleCode : undefined}
          successMessage={''}
        />
      </label>

      <div className={style.buttonsWrapper}>
        <Button.Accent
          size={ButtonSize.Medium}
          label={t({
            id: 'apiPage.create.submit',
            message: `Create API`,
          })}
          className={style.button}
          onClick={submit}
          disabled={isSubmitDisabled}
          dataTestId={DataTestIds.CreateApiCreateButton}
        />
        <Button.Clean
          label={t({
            id: 'core.cancel',
            message: `Cancel`,
          })}
          className={style.button}
          onClick={handleCancel}
          dataTestId={DataTestIds.CreateApiCancelButton}
        />
      </div>

      <InteractiveModal isOpen={modalIsOpen}>
        <InteractiveModalParts.SubHeader
          text={t({
            id: 'apiPage.create.wouldYouCancel',
            message: `Would you like to close creating a new API key?`,
          })}
        />
        <InteractiveModalParts.Explanation
          text={t({
            id: 'core.dataLost',
            message: `Data will be lost`,
          })}
        />
        <InteractiveModalParts.Button
          text={t({
            id: 'core.return',
            message: `Return`,
          })}
          variant={ButtonVariant.White}
          onClick={toggleModal}
        />
        <InteractiveModalParts.Button
          text={t({
            id: 'apiPage.create.close',
            message: `Close creating`,
          })}
          variant={ButtonVariant.Primary}
          onClick={onCancel}
        />
      </InteractiveModal>
    </div>
  )
}
