import { AccountInfo, ApiResponse, tigerxRestService } from '@/services/tigerx'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import { SymInfo } from './tigerX.types'

export const GetTigerXAccount = createAsyncThunk<ApiResponse<AccountInfo[]>, void, ThunkError>(
  nameof(tigerxRestService.getAccount),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: tigerxRestService.getAccount, payload }, thunkAPI)
  }
)

export const GetSym = createAsyncThunk<SymInfo[], void, ThunkError>(
  nameof(tigerxRestService.getSym),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: tigerxRestService.getSym, payload }, thunkAPI)
  }
)
