import { FC } from 'react'

type FeaturesProps = {
  className?: string
}

export const Perp: FC<FeaturesProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={20} fill="none" className={className}>
    <g clipPath="url(#a)">
      <path
        fill="#FAFAFA"
        d="M8.91 5.601c1.549 0 2.713 1.164 2.713 2.7 0 1.524-1.164 2.7-2.712 2.7H6.979v3H5.863v-8.4H8.91Zm0 4.356c.925 0 1.609-.708 1.609-1.656 0-.96-.684-1.656-1.608-1.656H6.979v3.312H8.91Z"
      />
    </g>
    <rect width={16} height={19} x={0.5} y={0.5} stroke="#37363E" rx={3.5} />
    <defs>
      <clipPath id="a">
        <rect width={17} height={20} fill="#fff" rx={4} />
      </clipPath>
    </defs>
  </svg>
)
