import { t } from '@lingui/macro'
import { FC, useEffect, useMemo, useState } from 'react'
import { Loader } from '@/ui/kit/Loader'
import { Position } from '@/ui/organisms'
import { useAppSelector } from '@/utils'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { TInstrumentType } from '@/core/types/overview'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { isExchangeTigerX } from '@/utils/lib/exchange'

interface IPositionsTableProps {}

const PositionsTable: FC<IPositionsTableProps> = props => {
  const [isInstrumentTypeChanging, setIsInstrumentTypeChanging] = useState(false)
  const [previousInstrumentType, setPreviousInstrumentType] = useState<TInstrumentType>()
  const { statusPositions } = useAppSelector(state => state.overview)
  const { allPositions, instrumentType } = useOverview()
  const { symInfoStatus } = useAppSelector(state => state.tigerX)
  const [isEmptyState, setIsEmptyState] = useState(false)
  const { exchangeType } = useExchageType()

  const isTigerX = isExchangeTigerX(exchangeType)
  useEffect(() => {
    if (isTigerX && !instrumentType === undefined) {
      if (previousInstrumentType && previousInstrumentType !== instrumentType) {
        setIsInstrumentTypeChanging(true)
        const timeout = setTimeout(() => {
          setIsInstrumentTypeChanging(false)
        }, 500) // Optional delay to mimic loading state
        return () => clearTimeout(timeout)
      }
      setPreviousInstrumentType(instrumentType)
    }
  }, [instrumentType, previousInstrumentType])
  const HEADERS = useMemo(
    () => [
      t({ message: 'Ticker', id: 'core.ticker' }),
      t({ message: 'Open price', id: 'core.openPrice' }),
      t({ message: 'Size', id: 'core.size' }),
      t({ message: 'Liquidation price', id: 'core.liquidationPrice' }),
      // t({ message: '% of margin', id: 'core.percentOfMargin' }),
      '', // ADL
      t({ message: 'PNL', id: 'core.pnl' }),
      '',
    ],
    []
  )
  useEffect(() => {
    if (!isTigerX) return
    const timer = setTimeout(() => setIsEmptyState(true), 2000) // Adjust delay as needed
    return () => clearTimeout(timer)
  }, [instrumentType])

  const showLoadingState =
    (symInfoStatus === 'loading' && statusPositions === 'succeeded') ||
    (symInfoStatus === 'loading' && statusPositions === 'loading') ||
    (symInfoStatus === 'succeeded' && statusPositions === 'succeeded' && !isEmptyState)

  if ((showLoadingState && isTigerX) || statusPositions === 'loading' || isInstrumentTypeChanging) {
    return <Loader className={s.loader} dataTestId={DataTestIds.LoaderPositions} />
  }

  if (!allPositions.length) {
    return (
      <div className={s.empty} data-testid={DataTestIds.OPEmptyPositions}>
        {t({ message: 'You have no submitted positions', id: 'orders.noPositions' })}
      </div>
    )
  }

  return (
    <div className={s.root}>
      <div className={s.header}>
        {HEADERS.map((header, index) => (
          <div key={index}>{header}</div>
        ))}
      </div>
      {allPositions?.map(position => (
        <Position.Table key={position.uid + position.updateTime} position={position} />
      ))}
    </div>
  )
}

export { PositionsTable }
