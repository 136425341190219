import { InitialState } from './tigerX.types'

export const initialState: InitialState = {
  account: null,
  accountStatus: 'idle',
  ordersStatus: 'idle',
  positionsStatus: 'idle',
  symInfoStatus: 'idle',
  symData: null,
  isTokenLoaded: false,
}
