import { useActions, useAppSelector } from '@/utils'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { BinanceProvider } from '../BinanceProvider'
import { OkxProvider } from '../OkxProvider'
import { overviewActions } from '@/redux/overview/overview.slice'
import {
  ACCOUNTS_BY_EXCHANGE,
  EXCHANGES_AVAILABLE,
  EXCHANGES_TYPE,
  EXCHANGE_TYPE_SELECTOR,
  INSTRUMENTS_BY_EXCHANGE,
  TExchangesAvailable,
} from '@/core/constants'
import { setupAxiosMainService } from '@/services/axios'
import { appActions } from '@/redux/app/app.slice'
import { usePrevious } from '@/utils/hooks/usePrevious'
import { InternalApi } from '@/services/rest/tigerGateway'
import { Loader } from '@/ui/molecules/Loader'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { BybitProvider } from '../BybitProvider'
import { INIT_SEARCH_PARAM } from '@/router/urls'
import { useExchangeFallbackLoad } from '@/utils/hooks/useExchangeFallbackLoad'
import { useExchangeBlockedByKycTier, useExchangeBlockedOverlay } from '@/utils/hooks'
import {
  isExchangeBinance,
  isExchangeBybit,
  isExchangeOkx,
  isExchangeOkxAffiliate,
  isExchangeTigerX,
} from '@/utils/lib/exchange'
import { useExchangeBlockedByFirstSession, useExchangeBlockedByReadOnlyKey } from '@/utils/hooks'
import { apiKeyActions } from '@/redux/apiKey/apiKey.slice'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { useBinanceAccountsConfig } from '@/services/binance/config'
import { binanceRestClients } from '@/services/axios/instances/binance_clients'
import { RestBinanceCoinmClient, RestBinanceSpotClient, RestBinanceUsdmClient } from '@tigertrade/binance-ts'
import { OkxAffiliateProvider } from '../OkxAffiliateProvider'
import { exchangeApi } from '@/backend/api/exchange'
import { profileAsyncActions } from '@/redux/profile/profile.actions'
import { defineMetadata } from '@/utils/lib/metadata/mapper'
import { TigerXProvider } from '../TigerXProvider'
import { TigerXConfig } from '@/exchanges/tigerx/tigerx.config'

interface IExchangeProvider {
  children: React.ReactNode
}

export const ExchangeProvider: FC<IExchangeProvider> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { GetReadOnlyKeyStatus } = useActions(apiKeyActions)
  const { setIsLoadedOverviewFallback } = useExchangeFallbackLoad()
  const isExchangeProviderInitializationBlocked = useExchangeBlockedByKycTier()
  const isExchangeBlockedByFirstSession = useExchangeBlockedByFirstSession()
  const isExchangeBlockedByReadOnlyKey = useExchangeBlockedByReadOnlyKey()
  const { isCurrentExchangeDisabledInit } = useExchangesDisabled()
  const { UpdateMetadata, GetProfileTC } = useActions(profileAsyncActions)

  const { isRestClientInitialized } = useAppSelector(state => state.app)

  const {
    metadata: { exchangeType: exchangeTypeProfile },
    metadata,
    tigerXAccess,
  } = useAppSelector(state => state.profile)
  const { createdApiReadOnlyStatusInitial } = useAppSelector(state => state.apiKeys)

  const { setRestClientInitialized, setIsExchangeProviderInitialized, setExchangeStatus } = useActions(appActions)
  const { setOverviewReset } = useActions(overviewActions)
  const [searchParams, setSearchParams] = useSearchParams()

  const exchangeTypeSearch = useMemo(() => {
    const exchangeTypeSeachSource = searchParams.get(EXCHANGE_TYPE_SELECTOR)
    return EXCHANGES_AVAILABLE.includes(exchangeTypeSeachSource as TExchangesAvailable)
      ? (exchangeTypeSeachSource as TExchangesAvailable)
      : undefined
  }, [searchParams])

  const { exchangeType } = useAppSelector(state => state.app)
  const { setExchangeType } = useActions(appActions)

  const config = useBinanceAccountsConfig(exchangeType === 'BINANCE_VIP')

  const [isExchangeChanging, setIsExchangeChanging] = useState(false)

  const { isExchangeBlockedByOverlay } = useExchangeBlockedOverlay(exchangeType)

  const previousExchangeType = usePrevious<TExchangesAvailable | undefined>(exchangeType)

  // Exchanges without readOnlyKeys
  const isExchangesWithoutReadOnlyKey = (exchangeType: TExchangesAvailable | undefined): boolean => {
    const EXCHANGES_WITH_EXCEPTIONS = new Set<TExchangesAvailable>([
      EXCHANGES_TYPE.TIGER_X, // Добавьте другие биржи с исключениями
    ])
    return exchangeType !== undefined && EXCHANGES_WITH_EXCEPTIONS.has(exchangeType)
  }

  useEffect(() => {
    const exchangeToSet = exchangeTypeSearch || exchangeType || exchangeTypeProfile || 'OKX_BROKER'

    if (!exchangeType) {
      setExchangeType(exchangeToSet)
      sessionStorage.setItem(EXCHANGE_TYPE_SELECTOR, exchangeToSet)
      return
    }

    if (previousExchangeType !== exchangeType && previousExchangeType !== undefined) {
      // if exchage changed
      // set search param to display...
      setSearchParams(
        new URLSearchParams({
          exchangeType: exchangeType,
          [INIT_SEARCH_PARAM]: INIT_SEARCH_PARAM,
        })
      )
      setIsExchangeChanging(true)
      sessionStorage.setItem(EXCHANGE_TYPE_SELECTOR, exchangeType)

      // Handle redirect
      const currentSearchParams = window.location.search

      if (exchangeType === EXCHANGES_TYPE.OKX_AFFILIATE) {
        navigate(`/okx-affiliate/${currentSearchParams}`)
      } else {
        if (window.location.pathname === '/okx-affiliate/' || window.location.pathname === '/okx-affiliate') {
          navigate(`/orders/${currentSearchParams}`)
        }
      }
      window.location.reload()
      return
    }
  }, [previousExchangeType, exchangeTypeProfile, exchangeTypeSearch, exchangeType])

  useEffect(() => {
    if (exchangeType === undefined) {
      return
    }

    setupAxiosMainService({
      exchangeHeader: exchangeType,
    })

    setRestClientInitialized(true)
    InternalApi.setExchange(exchangeType)
  }, [exchangeType])

  useEffect(() => {
    if (!isStatusFinal(createdApiReadOnlyStatusInitial) && exchangeType !== EXCHANGES_TYPE.TIGER_X) return

    if (exchangeType !== undefined) {
      setOverviewReset({
        exchangeInstruments: INSTRUMENTS_BY_EXCHANGE[exchangeType], // TODO: move to reducer itself
        exchangeAccounts: ACCOUNTS_BY_EXCHANGE[exchangeType],
        exchangeType: exchangeType,
        isRewriteDefaultInstrument:
          isExchangeProviderInitializationBlocked ||
          isExchangeBlockedByFirstSession ||
          isExchangeBlockedByReadOnlyKey ||
          isCurrentExchangeDisabledInit,
      })
    }

    if (
      isExchangeProviderInitializationBlocked ||
      isExchangeBlockedByFirstSession ||
      isExchangeBlockedByReadOnlyKey ||
      isCurrentExchangeDisabledInit
    ) {
      setIsLoadedOverviewFallback()
      setIsExchangeProviderInitialized(true)
      setExchangeStatus('succeeded')
      return
    }
  }, [
    isExchangeProviderInitializationBlocked,
    isExchangeBlockedByFirstSession,
    isExchangeBlockedByReadOnlyKey,
    exchangeType,
    createdApiReadOnlyStatusInitial,
    isCurrentExchangeDisabledInit,
  ])

  useEffect(() => {
    if (!isRestClientInitialized || createdApiReadOnlyStatusInitial !== 'idle') return
    if (exchangeType === EXCHANGES_TYPE.TIGER_X) return
    GetReadOnlyKeyStatus()
  }, [createdApiReadOnlyStatusInitial, isRestClientInitialized])

  // handleRedirectExchange
  useEffect(() => {
    if (exchangeType !== EXCHANGES_TYPE.TIGER_X) return

    const currentPath = location.pathname

    // Проверяем, находится ли текущий путь в списке заблокированных
    const isBlocked = TigerXConfig.redirect.blockedPath.some(blockedPath => currentPath.startsWith(blockedPath))

    if (isBlocked) {
      navigate(TigerXConfig.redirect.redirectURL, { replace: true }) //  redirect
    }
  }, [location.pathname, navigate, exchangeType])

  // Check api key for OKX
  useEffect(() => {
    if (metadata.isHasOKXApiKey !== undefined) return

    const fetchApiKey = async () => {
      try {
        const apiKey = await exchangeApi.checkReadOnlyApiKeyByType(EXCHANGES_TYPE.OKX_BROKER)

        await UpdateMetadata(defineMetadata({ isHasOKXApiKey: apiKey }))
        GetProfileTC()
      } catch (error) {
        console.error('Ошибка при получении API-ключа:', error)
      }
    }

    fetchApiKey()
  }, [metadata.isHasOKXApiKey])

  // Check whitelist for tigerX
  useEffect(() => {
    if (tigerXAccess) return

    if (isExchangeTigerX(exchangeType)) {
      setExchangeType(EXCHANGES_TYPE.BINANCE_BROKER_FUTURE)
    }
  }, [tigerXAccess, exchangeType])

  useEffect(() => {
    if (!config) return

    binanceRestClients.spot = new RestBinanceSpotClient(config.SPOT.restClientParams)
    binanceRestClients.usdm = new RestBinanceUsdmClient(config.USDT_FUTURE.restClientParams)
    binanceRestClients.coinm = new RestBinanceCoinmClient(config.COIN_FUTURE.restClientParams)
  }, [config])

  if (
    config === undefined ||
    exchangeType === undefined ||
    isExchangeChanging ||
    (!isExchangesWithoutReadOnlyKey(exchangeType) && !isRestClientInitialized) ||
    (!isExchangesWithoutReadOnlyKey(exchangeType) && !isStatusFinal(createdApiReadOnlyStatusInitial))
  ) {
    return (
      <Loader.Overlay>
        <Loader.Content />
      </Loader.Overlay>
    )
  }

  /**
   * If the "KYC" tier is not suitable
   * Then there is no need to initialize the exchange provider
   */
  if (
    isExchangeProviderInitializationBlocked ||
    isExchangeBlockedByOverlay ||
    isExchangeBlockedByFirstSession ||
    (!isExchangesWithoutReadOnlyKey(exchangeType) && isExchangeBlockedByReadOnlyKey) ||
    isCurrentExchangeDisabledInit
  ) {
    return <>{children}</>
  }

  if (isExchangeBinance(exchangeType)) {
    return <BinanceProvider config={config}>{children}</BinanceProvider>
  }

  if (isExchangeOkx(exchangeType)) {
    return <OkxProvider>{children}</OkxProvider>
  }

  if (isExchangeBybit(exchangeType)) {
    return <BybitProvider>{children}</BybitProvider>
  }

  if (isExchangeOkxAffiliate(exchangeType)) {
    return <OkxAffiliateProvider>{children}</OkxAffiliateProvider>
  }

  if (isExchangeTigerX(exchangeType)) {
    return <TigerXProvider>{children}</TigerXProvider>
  }

  return <>{children}</>
}
