import { t } from '@lingui/macro'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { Rights } from '@/ui/atoms/Rights'
import { SupportButton } from '@/ui/atoms/SupportButton'
import FooterLinks from '@/ui/molecules/FooterLinks'
import { useAppSelector, useMobileSizeDetect } from '@/utils'
import { MobileFooter } from './components/MobileFooter/MobileFooter'
import style from './style.module.scss'
import { BinanceLinkAlert } from '@/ui/molecules/BinanceLinkAlert'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { isExchangeBinance } from '@/utils/lib/exchange'
import clsx from 'clsx'
import { E_OKX_SUSPENSION_WIDGET_LAYOUT, OkxSuspensionWidget } from '@/ui/molecules/OkxSuspensionWidget'
import { SVG } from '@/assets/svg'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { OKX_DISABLE_DATE } from '@/core/constants'
import { useLocation } from 'react-router'
import { urls } from '@/router/urls'
import { ContainerSizes } from '@/types/layout'

const Footer: FC<{ size?: ContainerSizes }> = ({ size = 'default' }) => {
  const { exchangeType } = useExchageType()
  const { userKycLinkState } = useAppSelector(state => state.kyc)
  const {
    metadata: { firstSesstionTrackedTimestamp },
  } = useAppSelector(state => state.profile)
  // const {createdApiReadOnlyStatusInitial} = useAppSelector(state => state.apiKeys)
  const [isMobile] = useMobileSizeDetect()
  const [isOpenBinanceLink, setIsOpenBinanceLink] = useState(true)
  const isShowBinanceLinkAlert = useMemo(() => {
    return (
      isExchangeBinance(exchangeType) &&
      (userKycLinkState.status === 'INIT' ||
        userKycLinkState.status === 'FINAL_REJECT' ||
        userKycLinkState.status === 'REJECT')
    ) // && createdApiReadOnlyStatusInitial !== 'succeeded'
  }, [exchangeType, userKycLinkState])

  const [isShowOkxAlert, setIsShowOkxAlert] = useState(exchangeType === 'OKX_BROKER')

  const onCloseBinanceLink = useCallback(() => {
    setIsOpenBinanceLink(false)
  }, [])

  const { pathname } = useLocation()

  const { isOkxDisabled } = useExchangesDisabled()

  if (isMobile) {
    return <MobileFooter />
  }

  return (
    <>
      {isShowOkxAlert && pathname !== urls.chooseExchange && (
        <div className={style.okxAlert}>
          <OkxSuspensionWidget
            layout={E_OKX_SUSPENSION_WIDGET_LAYOUT.ROW}
            postContent={
              (!isOkxDisabled || Number(firstSesstionTrackedTimestamp) < OKX_DISABLE_DATE.getTime()) && (
                <div className={style.okxAlertClose} onClick={() => setIsShowOkxAlert(false)}>
                  <SVG.Additional.Close />
                </div>
              )
            }
          />
        </div>
      )}
      <div
        className={clsx(style.footer, {
          [style.footerFixHeight]: isOpenBinanceLink && isShowBinanceLinkAlert,
          [style.footerXl]: size === 'xl',
        })}
      >
        {isOpenBinanceLink && isShowBinanceLinkAlert ? (
          <BinanceLinkAlert onCloseBinanceLink={onCloseBinanceLink} />
        ) : (
          <>
            <Rights />
            <FooterLinks />
            <SupportButton
              label={t({
                id: 'core.supportEmail',
                message: 'broker.support@tiger.trade',
              })}
            />
          </>
        )}
      </div>
    </>
  )
}

export default memo(Footer)
