import axios, { AxiosResponse } from 'axios'
import { APIResponseV3WithTime, WalletBalanceV5 } from 'bybit-api'
import { AccountInfo, AssetInfo, Order, Position, QueryStatementParams, Statement } from './types'
import { GLOBAL_REQUEST_TIMEOUT } from '@/core/config/exchange'
import { TIGER_X_API_URL } from '@/exchanges/tigerx/tegerx.api'
import { SymInfo } from '@/redux/tigerX/tigerX.types'
// import { APIResponse } from 'okx-api'

let accessToken: string | null = null

export interface ApiResponse<T> {
  code: number
  message: string
  data: T
}

export interface AssetListResponse {
  page: number
  pageSize: number
  pageNum: number
  totalSize: number
  list: AssetInfo[]
}

// Функция для установки токена
export const setAuthToken = (token: string) => {
  accessToken = token
}

const tigerxAxiosClient = axios.create({
  baseURL: TIGER_X_API_URL,
  timeout: GLOBAL_REQUEST_TIMEOUT,
  headers: {
    Accept: 'application/json',
  },
})

// Интерсептор для добавления токена из Auth0
tigerxAxiosClient.interceptors.request.use(
  config => {
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error)
)

export const tigerxRestService = {
  async getAccount(): Promise<AxiosResponse<ApiResponse<AccountInfo[]>>> {
    return await tigerxAxiosClient.get('/api/v1/trading/account')
  },

  async getOrders(
    params: {
      page?: number
    },
    instrumentType: 'OKX_X' | 'BINANCE_X'
  ) {
    const exchangeFormatedType = instrumentType === 'BINANCE_X' ? 'BINANCE' : 'OKX'
    return await tigerxAxiosClient.get(`/api/v1/trading/orders?exchange=${exchangeFormatedType}`, {
      params: { ...params, pageSize: 1000 },
    })
  },

  async getAlgoOrders(
    params: {
      page?: number
    },
    instrumentType: 'OKX_X' | 'BINANCE_X'
  ) {
    const exchangeFormatedType = instrumentType === 'BINANCE_X' ? 'BINANCE' : 'OKX'
    return await tigerxAxiosClient.get(`api/v1/algo/openOrders?exchange=${exchangeFormatedType}`, {
      params: { ...params, pageSize: 1000 },
    })
  },
  async getOrdersRecursive(
    params: {
      page: number
    },
    instrumentType: 'OKX_X' | 'BINANCE_X',
    orders: Order[] = []
  ): Promise<Order[]> {
    const response = await tigerxRestService.getOrders(params, instrumentType)
    const currentPageOrders = response.data.data.list || []

    orders.push(...currentPageOrders)
    if (response.data.data.page !== response.data.data.pageNum && response.data.data.pageNum !== 0) {
      const nextParams = { ...params, page: response.data.data.page + 1 }
      return await tigerxRestService.getOrdersRecursive(nextParams, instrumentType, orders)
    }

    return orders
  },

  async getAlgoOrdersRecursive(
    params: {
      page: number
    },
    instrumentType: 'OKX_X' | 'BINANCE_X',
    orders: Order[] = []
  ): Promise<Order[]> {
    const response = await tigerxRestService.getAlgoOrders(params, instrumentType)
    const currentPageOrders = response.data.data.list || []

    orders.push(...currentPageOrders)
    if (response.data.data.page !== response.data.data.pageNum && response.data.data.pageNum !== 0) {
      const nextParams = { ...params, page: response.data.data.page + 1 }
      return await tigerxRestService.getAlgoOrdersRecursive(nextParams, instrumentType, orders)
    }

    return orders
  },
  async getPositions(params: { symbol?: string; limit?: string; settleCoin?: string }) {
    try {
      const data = await tigerxAxiosClient.get(`/api/v1/trading/position?zeroFilter=true`, {
        params: {
          ...params,
        },
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getPositionsRecursive(params: { symbol?: string; limit?: string; settleCoin?: string }): Promise<Position[]> {
    const response = await tigerxRestService.getPositions(params)
    const positions = response.data.data || []
    return positions
  },

  async getAssets(): Promise<AxiosResponse<ApiResponse<AssetListResponse>>> {
    return await tigerxAxiosClient.get('/api/v1/trading/portfolio/assets')
  },

  async getStatment(params: QueryStatementParams): Promise<AxiosResponse<ApiResponse<Statement[]>>> {
    return await tigerxAxiosClient.get('/api/v1/trading/statement', {
      params,
    })
  },

  async getWalletBalance(params: {
    accountType: string
  }): Promise<AxiosResponse<APIResponseV3WithTime<{ list?: WalletBalanceV5[] }>>> {
    return await tigerxAxiosClient.get('/v5/account/wallet-balance', {
      params,
    })
  },

  async getLeverageTickerList(params?: {
    sym?: string
  }): Promise<AxiosResponse<APIResponseV3WithTime<{ list?: WalletBalanceV5[] }>>> {
    return await tigerxAxiosClient.get('/api/v1/trading/sym/info', {
      params,
    })
  },

  async getLeverage(params: {
    sym: string
  }): Promise<AxiosResponse<APIResponseV3WithTime<{ data: { sym: string; leverage: string }[] }>>> {
    return await tigerxAxiosClient.get('/api/v1/trading/perp/leverage', {
      params,
    })
  },

  async getPositionTier(params?: {
    sym: string
  }): Promise<
    AxiosResponse<APIResponseV3WithTime<{ data: Record<string, { maxNotionValue: string; maxLeverage: string }[]> }>>
  > {
    return await tigerxAxiosClient.get('/api/v1/trading/broker/positionBracket', { params })
  },

  async getSym() {
    return await tigerxAxiosClient.get<SymInfo[]>('/api/v1/trading/sym/info')
  },
}
