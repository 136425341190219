import styles from './style.module.scss'
import { ResultCard } from './ResultCard/ResultCard'
import { t } from '@lingui/macro'
import { TitleH4 } from '@/ui/atoms/TitleH4'
import { TypePeriod, TypeTrade } from '@/redux/leaderboard/leaderboard.types'
import { PersonalResultUser } from '../../helpers'

type Props = {
  personalResults: {
    [TypeTrade.SPOT]: PersonalResultUser | null
    [TypeTrade.FEATURE]: PersonalResultUser | null
    [TypeTrade.PNL]: PersonalResultUser | null
  }
  interval: TypePeriod
  isManager?: boolean
  type: string
}

export const PersonalResults = ({ personalResults, interval, isManager, type }: Props) => {
  const emptyData: PersonalResultUser = {
    name: '-',
    user_id: 0,
    value: '',
    position: 0,
  }

  const resultSpot = personalResults[TypeTrade.SPOT] ?? emptyData
  const resultFeature = personalResults[TypeTrade.FEATURE] ?? emptyData
  const resultPnl = personalResults[TypeTrade.PNL] ?? emptyData

  const titleResult = t({
    id: 'leaderBoard.personalResults.title',
    comment: 'Your results of the week',
  })

  const INTERVAL_TR = {
    [TypePeriod.TODAY]: t`leaderBoard.personalResult.interval.today`,
    [TypePeriod.WEEK]: t`leaderBoard.personalResult.interval.week`,
    [TypePeriod.MONTH]: t`leaderBoard.personalResult.interval.month`,
  }

  const title = `${titleResult} ${INTERVAL_TR[interval]}`

  if (isManager) {
    return (
      <div className={styles.manager}>
        {t({
          id: 'leaderBoard.personalResults.isManager',
          comment: 'Result not have',
        })}
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <TitleH4 className={styles.header} label={title} />
      <ResultCard position={resultSpot.position} title={TypeTrade.SPOT} amount={resultSpot.value} currency={type} />
      <ResultCard
        position={resultFeature.position}
        title={TypeTrade.FEATURE}
        amount={resultFeature.value}
        currency={type}
      />
      <ResultCard position={resultPnl.position} title={TypeTrade.PNL} amount={resultPnl.value} currency={type} />
    </div>
  )
}
