import { SVG } from '@/assets/svg'
import { ExchangeTypeConfig } from '../exchage.types'
import { urls } from '@/router/urls'

export enum ETigerXInstruments {
  'BINANCE_X' = 'BINANCE_X',
  'OKX_X' = 'OKX_X',
}

export enum ETigerXExchangeAccountOriginal {
  'TIGER_X' = 'TIGER_X',
}

export enum ETigerXAccount {
  'TIGER_X_BINANCE' = 'TIGER_X_BINANCE',
  'TIGER_X_OKX' = 'TIGER_X_OKX',
}

export enum ETigerXAccountOriginal {
  'OKX' = 'OKX',
  'BINANCE' = 'BINANCE',
}

export const TigerXConfig: ExchangeTypeConfig = {
  name: 'Tiger X', // Имя биржи
  type: 'TIGER_X', // Тип биржи
  icon: SVG.OtherIcons.TigerSmall,
  smallIcon: SVG.OtherIcons.TigerSmall,
  instruments: [ETigerXInstruments.BINANCE_X, ETigerXInstruments.OKX_X],
  accounts: ['TIGER_X_BINANCE', 'TIGER_X_OKX'],
  isAvailable: true,
  pages: {
    profile: {
      bnbBurn: false,
      convertSmallBalance: false,
      assetMode: false,
      positionMode: false,
      additionalInfo: true,
    },
    api: {
      availableKeys: 5,
    },
  },
  redirect: {
    redirectURL: urls.wallet,
    blockedPath: [urls.riskManager, '/journal'],
  },
  navigationMenu: [urls.wallet],
}
