import { t } from '@lingui/macro'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { KYCTier } from '@/backend/models/KycDTO'
import { transactionsAsyncActions } from '@/redux/transactions/transactions.actions'
import { transactionsActions } from '@/redux/transactions/transactions.slice'
import { AssetsForWithdrawAndDeposit } from '@/redux/transactions/transactions.types'
import { useActions, useAppDispatch, useAppSelector } from '@/utils'
import { Select, SelectOption, SelectProps } from '@/ui/kit'
import { DepositWalletAddress, Prompt } from '@/ui/molecules'
import { WithdrawUpPopup } from '../WithdrawUpPopup'
import style from './style.module.scss'
import { useKYC, useMobileSizeDetect } from '@/utils/hooks'
import { NetworkSelectOption } from '@/ui/atoms/NetworkSelectOption'
import { CoinSelectOption } from '@/ui/atoms/CoinSelectOption'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import clsx from 'clsx'
import { isExchangeTigerX } from '@/utils/lib/exchange'
import { useExchageType } from '@/utils/hooks/useExchangeType'

export type PropsType = {
  tier: KYCTier
}

export type NetworkOption = SelectOption & { name: string }

export const DepositTransaction = memo<PropsType>(({ tier }) => {
  const [isMobile] = useMobileSizeDetect()
  const { isTierUpperBasic } = useKYC()
  const [selectedCoin, setSelectedCoin] = useState<string | null>('')
  const [networkName, setNetworkName] = useState<string | null>('')
  const [networkOption, setNetworkOption] = useState<NetworkOption | null>(null)
  const [isDepositAttemptDetected, setIsDepositAttemptDetected] = useState(false)
  const { exchangeType } = useExchageType()

  const {
    assetsForWithdrawAndDeposit: { assets },
    depositWalletAddress,
  } = useAppSelector(state => state.transactions)

  const dispatch = useAppDispatch()

  const cleanWalletAddress = useCallback(() => {
    dispatch(transactionsActions.resetWalletAddress())
  }, [dispatch])

  useEffect(() => cleanWalletAddress, [cleanWalletAddress])

  const coins = useMemo(() => getCoins(assets), [assets])
  const networks = useMemo(() => getNetworks(assets, selectedCoin), [assets, selectedCoin])

  const onCoinChange = useCallback(
    value => {
      setSelectedCoin(value)
      setNetworkName(null)
      setNetworkOption(null)
      cleanWalletAddress()
    },
    [cleanWalletAddress]
  )
  const { GetDepositWalletAddressTC } = useActions(transactionsAsyncActions)

  const getWalletAddress = useCallback(
    (networkOption: SelectOption) => {
      setIsDepositAttemptDetected(true)

      if (selectedCoin && networkOption?.value) {
        GetDepositWalletAddressTC({ coin: selectedCoin, network: String(networkOption.value) })
      }
    },
    [GetDepositWalletAddressTC, selectedCoin]
  )

  const onNetworkChange = useCallback<SelectProps<string>['onChange']>(
    value => {
      setNetworkName(value)
      cleanWalletAddress()

      const network = networks.find(item => item.value === value)

      if (network) {
        setNetworkOption(network)
        getWalletAddress(network)
      }
    },
    [cleanWalletAddress, getWalletAddress, networks]
  )

  const address = depositWalletAddress?.address?.address
  const status = depositWalletAddress?.status
  const errorText =
    status === 'failed'
      ? t({
          message: "Can't generate address for selected coin and network",
          id: 'deposittransaction.cantgenerateAddress',
        })
      : ''
  const depositWalletAddressText = address || errorText
  const coinSelectHasError = isDepositAttemptDetected && !selectedCoin
  const networkSelectHasError = isDepositAttemptDetected && !networkName

  return (
    <div className={clsx(isMobile && style.mobile, style.deposit)}>
      <div className={style.top}>
        <Select
          options={coins}
          value={selectedCoin}
          size={isMobile ? Select.Size.Medium : Select.Size.Large}
          onChange={onCoinChange}
          label={t({ message: 'Coin', id: 'deposittransaction.coin' })}
          placeholder={t({ message: 'Select coin', id: 'deposittransaction.selectCoin' })}
          className={style.coinSelect}
          errorMessage={coinSelectHasError && t({ message: 'Select coin', id: 'deposittransaction.selectCoin' })}
          dataTestId={DataTestIds.CoinSelector}
          withSearch
        />
        <Select
          options={networks}
          value={networkName}
          size={isMobile ? Select.Size.Medium : Select.Size.Large}
          onChange={onNetworkChange}
          label={t({ message: 'Network', id: 'deposittransaction.network' })}
          placeholder={t({ message: 'Select Network', id: 'deposittransaction.selectNetwork' })}
          disabled={!selectedCoin}
          className={style.networkSelect}
          dataTestId={DataTestIds.NetworkSelector}
          errorMessage={
            networkSelectHasError && t({ message: 'Select Network', id: 'deposittransaction.selectNetwork' })
          }
        />
      </div>

      <div className={style.promptWrap} data-testid={DataTestIds.DepositAlertDescription}>
        <Prompt
          promptType={'transaction'}
          coin={selectedCoin}
          networkSymbol={networkOption?.value}
          networkType={networkOption?.name}
        />
        {isTierUpperBasic && <WithdrawUpPopup isDeposit={true} tier={tier} />}
      </div>

      <div className={style.description} data-testid={DataTestIds.DepositInfoDescription}>
        {isExchangeTigerX(exchangeType) ? (
          <>{t({ comment: "There's no minimum deposit requirement.", id: 'deposittransaction.minamount.tigerX' })}</>
        ) : (
          <>{t({ message: "There's no minimum deposit requirement.", id: 'deposittransaction.description-1' })}</>
        )}
        <br />
        {t({
          message: 'Deposit any amount, including the blockchain commission.',
          id: 'deposittransaction.description-2',
        })}
      </div>

      {depositWalletAddressText && <DepositWalletAddress address={depositWalletAddressText} />}
    </div>
  )
})

const getCoins = (assets: AssetsForWithdrawAndDeposit['assets']): SelectOption[] => {
  const coinKeys = Object.keys(assets)
  const coinsWithAllowedNetworks = coinKeys.filter(coin => assets[coin].some(network => network.depositEnable))

  const result = coinsWithAllowedNetworks.sort().map(key => ({
    value: key,
    search: key,
    label: <CoinSelectOption asset={key} />,
    selected: key,
  }))

  return result
}

const getNetworks = (assets: AssetsForWithdrawAndDeposit['assets'], coin: string | null) => {
  if (!coin) {
    return []
  }

  const networksByCoin = assets[coin]

  const allowedNetworks = networksByCoin.filter(item => item.depositEnable)

  const result = allowedNetworks.map<NetworkOption>(({ network, name, tokenStandard, alias }) => {
    const desc = `${name}${tokenStandard ? ` (${tokenStandard})` : ''}`

    return {
      value: network,
      name: desc,
      label: <NetworkSelectOption symbol={name} name={alias} />,
      selected: <NetworkSelectOption symbol={name} name={alias} large />,
    }
  })

  return result
}
