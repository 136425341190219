import clsx from 'clsx'
import React, { FC } from 'react'
import { Button, ButtonSize, InteractiveModal } from '../../ui/kit'
import { ErrorBase } from '../../ui/kit/Errors/ErrorBase/ErrorBase.stories'
import Footer from '../../ui/organisms/Footer'
import Header, { HeaderProps } from '../../ui/organisms/Header'
import { useAppSelector, useMobileSizeDetect } from '../../utils'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { AvailableScreenSize } from '@/types'
import TgBotButton from '@/ui/atoms/TgBotButton/TgBotButton'
import { ContainerSizes } from '@/types'

type MainProps = HeaderProps & {
  children: React.ReactNode

  /** Use this flag for blurring layout header. @default = false */
  isPopupMode?: boolean

  /** Use this flag for hide background image. @default = false */
  isBackgroundHidden?: boolean

  enableHeaderAnimation?: boolean

  isTopMenuHidden?: boolean

  isFooterHidden?: boolean

  size?: ContainerSizes
}

const Main: FC<MainProps> = props => {
  const {
    children,
    isPopupMode,
    enableHeaderAnimation,
    isBackgroundHidden,
    isBackButtonHidden,
    isTopMenuHidden,
    isFooterHidden,
    size = 'default',
  } = props

  const { status } = useAppSelector(state => state.app)
  const [isMobile, setMode] = useMobileSizeDetect()

  return (
    <>
      {!isMobile && (
        <div className={style.hiddenWrapper}>
          <Button.Primary
            size={ButtonSize.Medium}
            className={style.versionButton}
            onClick={() => setMode(AvailableScreenSize.Mobile)}
            label={t({ id: 'core.mobileVersion', message: 'Open mobile version' })}
          />
        </div>
      )}

      <div
        className={clsx(
          isMobile && {
            [style.outerMobile]: true,
            [style.outerMobileBackground]: !isBackgroundHidden,
          },
          !isMobile && {
            [style.outer]: size === 'default',
            [style.outerXl]: size === 'xl',
            [style.outerBackground]: !isBackgroundHidden,
          },
          {
            outerBlur: isPopupMode,
            mainOuterAnimation: enableHeaderAnimation,
          }
        )}
      >
        <div className={clsx(style.bgLayer, isMobile && style.bgLayerMobile)}></div>
        <div
          className={clsx({
            [style.innerLayout]: true,
            [style.inner]: size === 'default',
            [style.innerXl]: size === 'xl',
          })}
        >
          <Header
            isBackButtonHidden={isBackButtonHidden}
            isTopMenuHidden={isTopMenuHidden}
            isBackgroundHidden={isBackgroundHidden}
          />
          <div className={style.content}>{children}</div>
          {!isFooterHidden && <Footer size={size} />}
        </div>

        <InteractiveModal isOpen={status === 'failed'}>
          <ErrorBase
            customHeader={t({ message: `Couldn’t load data`, id: 'core.notLoadData' })}
            className={style.customError}
            isRefreshButtonVisible={true}
            isSupportMessage={true}
          />
        </InteractiveModal>
        {/* <TgBotButton /> hide tg button*/}
      </div>
    </>
  )
}

export default Main
