import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'
import { ITableProps } from '@/ui/atoms/Table'
import { useAppSelector } from '@/utils'
import { useCashbackData } from '@/utils/hooks/data/useCashbackData'
import { t } from '@lingui/macro'
import { useMemo } from 'react'
import style from '../style.module.scss'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { getCashbackLeftBarTranslations } from '../CashbackLeftBar.Translations'
import { isExchangeBinance, isExchangeOkx } from '@/utils/lib/exchange'
import styles from '@/ui/molecules/SystemNotifications/presets/style.module.scss'
import { Trans } from '@lingui/react'
import { EXCHANGES_TYPE } from '@/core/constants'

export const useContent = () => {
  const { cashback } = useAppSelector(state => state.cashback)
  const { exchangeType } = useExchageType()
  const cashbackData = useCashbackData()

  const { feesHeading, feesSubheading } = useMemo(() => {
    if (exchangeType === undefined)
      return {
        feesHeading: '',
        feesSubheading: '',
      }
    return getCashbackLeftBarTranslations()[exchangeType]
  }, [exchangeType])

  const balanceSheetData = useMemo(
    () => [
      ...(isExchangeBinance(exchangeType)
        ? [
            {
              label: t({
                id: 'cashbackPage.leftBar.tradingVolume',
                message: 'Trading volume today',
              }),
              amount: cashback.volumeToday,
              symbol: 'USDT',
            },
            {
              label: t({
                id: 'cashbackPage.leftBar.paidComission',
                message: 'Paid commission today',
              }),
              amount: cashback.commissionToday,
              symbol: 'USDT',
            },
          ]
        : []),
      ...(cashback.calculationRule === ECashbackCalculationRule.customReferralCode
        ? [
            {
              label: (
                <>
                  {t({
                    id: 'cashbackPage.leftBar.tradingVolumeMonthly',
                    message: 'Traded volume during the',
                  })}{' '}
                  <span className={style._nowrap}>
                    {t({
                      id: 'cashbackPage.last30Days',
                      message: 'last 30 days',
                    })}
                  </span>
                </>
              ),
              amount: cashbackData.cashbackVolumeLast30DaysRounded,
              symbol: 'USDT',
            },
          ]
        : []),
    ],
    [cashback, cashbackData, exchangeType]
  )

  const getCommissionTableData = (exchangeType: EXCHANGES_TYPE, columns: any) => {
    switch (exchangeType) {
      case EXCHANGES_TYPE.BINANCE_BROKER_FUTURE || EXCHANGES_TYPE.BINANCE_VIP:
        return [
          {
            heading: t({ id: 'core.accounts.spot', message: 'Spot' }),
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: t({ id: 'commissions.spot.makerValue', message: '0.1%' }),
                    taker: t({ id: 'commissions.spot.takerValue', message: '0.1%' }),
                  },
                ],
              },
            },
          },
          {
            heading: t({ id: 'core.accounts.coinm', message: 'Futures COIN-M' }),
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: t({ id: 'commissions.coinm.makerValue', message: '0.1%' }),
                    taker: t({ id: 'commissions.coinm.takerValue', message: '0.1%' }),
                  },
                ],
              },
            },
          },
          {
            heading: t({ id: 'core.accounts.usdm', message: 'Futures USDM' }),
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: t({ id: 'commissions.usdm.makerValue', message: '0.1%' }),
                    taker: t({ id: 'commissions.usdm.takerValue', message: '0.1%' }),
                  },
                ],
              },
            },
          },
        ]

      case EXCHANGES_TYPE.BYBIT_BROKER:
        return [
          {
            heading: t({ id: 'core.accounts.spot', message: 'Spot' }),
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: (
                      <Trans
                        id="commissions.bybit.spot.makerValue"
                        message="0.03%"
                        components={{
                          colored: <span className={styles.colored}></span>,
                        }}
                      />
                    ),
                    taker: (
                      <Trans
                        id="commissions.bybit.spot.takerValue"
                        message="0.045%"
                        components={{
                          colored: <span className={styles.colored}></span>,
                        }}
                      />
                    ),
                  },
                ],
              },
            },
          },
          {
            heading: t({ id: 'core.accounts.derivatives', message: 'Derivatives' }),
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: (
                      <Trans
                        id="commissions.bybit.derivatives.makerValue"
                        message="0.03%"
                        components={{
                          colored: <span className={styles.colored}></span>,
                        }}
                      />
                    ),
                    taker: (
                      <Trans
                        id="commissions.bybit.derivatives.takerValue"
                        message="0.045%"
                        components={{
                          colored: <span className={styles.colored}></span>,
                        }}
                      />
                    ),
                  },
                ],
              },
            },
          },
        ]

      case EXCHANGES_TYPE.TIGER_X:
        return [
          {
            heading: 'X Binance: Spot',
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: '0.1%',
                    taker: '0.1%',
                  },
                ],
              },
            },
          },
          {
            heading: 'Х Binanace Perpetual',
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: '0.01%',
                    taker: '0.05%',
                  },
                ],
              },
            },
          },
          {
            heading: 'X OKX Spot',
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: '0.08%',
                    taker: '0.1%',
                  },
                ],
              },
            },
          },
          {
            heading: 'X OKX Perpetual',
            table: {
              content: {
                columns,
                rows: [
                  {
                    maker: '0.02%',
                    taker: '0.05%',
                  },
                ],
              },
            },
          },
        ]

      default:
        return []
    }
  }

  const commissionTableData = useMemo(() => {
    const columns = [
      { id: 'maker', element: t({ id: 'core.commissions.maker', message: 'Maker' }) },
      { id: 'taker', element: t({ id: 'core.commissions.taker', message: 'Taker' }) },
    ]
    return getCommissionTableData(exchangeType, columns)
  }, [exchangeType])

  return {
    balanceSheetData,
    commissionTableData,
    feesHeading,
    feesSubheading,
  }
}
