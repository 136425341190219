import { FC, memo } from 'react'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import AccountInfo from '../AccountInfo'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { urls } from '@/router/urls'
import { CashbackWidget } from '@/ui/organisms/CashbackWidget'
import { useAppSelector } from '@/utils'
import { SkeletonWalletLeftBar } from '@/ui/kit/Skeletons/SkeletonWallet/SkeletonWallet'
import { useExchageType } from '@/utils/hooks/useExchangeType'

type LeftBarPropsType = {}

const WalletLeftBar: FC<LeftBarPropsType> = () => {
  const { statusBalances } = useAppSelector(state => state.overview)
  const { teamUserInfo, userMode } = useAppSelector(state => state.team)
  const { exchangeType } = useExchageType()

  return (
    <div className={style.leftBar}>
      {teamUserInfo?.showLogoToReferral && userMode === 'user' ? (
        <div className={style.logoPartner}>
          <img src={teamUserInfo?.teamLogoUrl} className={style.img} alt="logo" />
          <TitleH1 label={teamUserInfo?.teamTitle} />
        </div>
      ) : (
        <TitleH1 label={t({ id: 'mainPage.title', message: 'Your Tiger.Trade Broker account' })} />
      )}
      {statusBalances !== 'succeeded' && <SkeletonWalletLeftBar />}
      {statusBalances === 'succeeded' && (
        <>
          <AccountInfo />
          <CashbackWidget
            customOptions={
              exchangeType !== 'BINANCE_VIP'
                ? {
                    button: {
                      text: t({ id: 'tigerToken.cashbackDetails', message: 'Cashback details' }),
                      link: urls.cashback,
                    },
                  }
                : undefined
            }
          />
        </>
      )}
    </div>
  )
}

export default memo(WalletLeftBar)
