import { useCallback, useMemo } from 'react'
import { t } from '@lingui/macro'
import { OverviewOpenOrder } from '@/backend/models/OverviewDTO'
import { useOverview } from '@/utils/hooks/useOverview'
import { formatNumber, useAppSelector } from '@/utils'
import { OVERVIEW_SHOW_NEW } from '@/core/config/overview'
import { getAccountTypeForInstrument } from '@/redux/overview/overview.utils'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EOkxOrderType } from '@/services/okx'
import { OVERVIEW_OKX_ORDER_SOURCE_MAP } from '@/core/constants/overview'
import { EBybitInstruments } from '@/services/bybit'
import { isExchangeBybit, isExchangeOkx, isExchangeTigerX } from '@/utils/lib/exchange'

const useOrder = (order: OverviewOpenOrder) => {
  const statusToName: Record<OverviewOpenOrder['status'], string> = {
    FILLED: t({ message: 'Executed', id: 'order.status.executed' }),
    NEW: t({ message: 'New', id: 'order.status.new' }),
    live: t({ message: 'New', id: 'order.status.new' }),
    PARTIALLY_FILLED: t({ message: 'Partially executed', id: 'order.status.partiallyExecuted' }),
    partially_filled: t({ message: 'Partially executed', id: 'order.status.partiallyExecuted' }),
    Created: t({ message: 'Created', id: 'order.status.Created' }),
    New: t({ message: 'New', id: 'order.status.new' }),
    Untriggered: t({ message: 'Untriggered', id: 'order.status.Untriggered' }),
    CANCELED: '',
    EXPIRED: '',
    EXPIRED_IN_MATCH: '',
    PENDING_CANCEL: '',
    REJECTED: '',
  }

  const { deletingOrders } = useAppSelector(state => state.overview)
  const { exchangeType } = useExchageType()

  const { orderId, origQty, symbol, orderTypeOutput, instrumentType, status } = order
  const { cancelOrder } = useOverview()

  const deleting = useMemo(() => {
    return deletingOrders[instrumentType][orderId]?.isDeleting
  }, [orderId, deletingOrders, instrumentType])

  const amount = useMemo(() => formatNumber(origQty), [origQty])

  const typeToName = useMemo(() => orderTypeOutput?.replaceAll('_', ' '), [orderTypeOutput])

  const tigerXInstrumentType = isExchangeTigerX(exchangeType)
  const accountTypeName = useMemo(() => {
    switch (order.instrumentType) {
      case 'SPOT':
        return 'Spot'
      case 'USDT_FUTURE':
      case 'COIN_FUTURE':
        return 'Futures'
      case 'SWAP':
        return 'Swap'
      case 'spot':
        return 'Spot'
      case 'linear':
        return 'linear'
      default:
        return ''
    }
  }, [order])
  const accountTigerXTypeName = useMemo(() => {
    switch (order.businessType) {
      case 'SPOT':
        return 'Spot'
      case 'USDT_FUTURE':
      case 'COIN_FUTURE':
        return 'Futures'
      case 'SWAP':
        return 'Swap'
      case 'spot':
        return 'Spot'
      case 'linear':
        return 'linear'
      case 'PERP':
        return 'Perp'
      case 'MARGIN':
        return 'Margin'
      default:
        return ''
    }
  }, [order])

  const isStatusNew = useMemo(() => {
    return OVERVIEW_SHOW_NEW.includes(status)
  }, [status])

  const closeOrderHandler = useCallback(() => {
    if (exchangeType === undefined) return

    const orderAccount = getAccountTypeForInstrument(order.instrumentType, exchangeType)

    if (orderAccount === undefined) return

    cancelOrder({
      orderId,
      symbol,
      accountType: orderAccount,
      instrumentType: order.instrumentType,
      ...(isExchangeOkx(exchangeType) && Object.values(EOkxOrderType).includes(order.type as EOkxOrderType)
        ? {
            orderSourceType: OVERVIEW_OKX_ORDER_SOURCE_MAP[order.type as EOkxOrderType],
          }
        : {}),
      ...(isExchangeBybit(exchangeType) && order.instrumentType === EBybitInstruments.spot
        ? {
            orderFilter: order.stopOrderType,
          }
        : {}),
      orderTypeOutput: order.orderTypeOutput,
      isAlgo: order.isAlgo,
    })
  }, [
    cancelOrder,
    orderId,
    symbol,
    exchangeType,
    order.instrumentType,
    order.type,
    order.stopOrderType,
    order.orderTypeOutput,
    order.isAlgo,
  ])

  return {
    amount,
    deleting,
    typeToName,
    statusToName,
    accountTypeName: tigerXInstrumentType ? accountTigerXTypeName : accountTypeName,
    closeOrderHandler,
    isStatusNew,
  }
}

export { useOrder }
