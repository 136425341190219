import clsx from 'clsx'
import { FC } from 'react'
import { SVG } from '@/assets/svg'
import { AccountOverviewPositionsView } from '@/backend/models/OverviewDTO'
import { Button } from '@/ui/kit'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import ADL from './ADL/ADL'
import { usePosition } from './hooks/usePosition'
import s from './style.module.scss'
import { EPositionSide } from '@tigertrade/binance-ts'
import { SideIndicator } from '@/ui/kit/SideIndicator'
import { FormatNumberDotWithTooltip } from '@/ui/molecules/FormatNumberDotWithTooltip/FormatNumberWithTooltip'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'
import { isExchangeTigerX } from '@/utils/lib/exchange'

interface Props {
  position: AccountOverviewPositionsView
}

const PositionTable: FC<Props> = ({ position }) => {
  const { symbol, positionAmt, positionSideOutput, adl } = position
  const { deleting, unrealizedPnl, entryPrice, closePositionHandler, liquidationPriceOutput } = usePosition(position)
  const { exchangeType } = useExchageType()
  const isTigerXExchangeType = isExchangeTigerX(exchangeType)

  return (
    <div className={clsx(s.position, s.table, { [s.deleted]: deleting })} data-testid={DataTestIds.PositionListItem}>
      <div className={s.item}>
        <div className={s.currency}>
          <div
            className={clsx({ [s.smallFont]: symbol.length >= 15 })}
            data-testid={`${DataTestIds.PositionSymbol}-${symbol}`}
          >
            {symbol}
          </div>
          <SideIndicator profit={positionSideOutput === EPositionSide.LONG} label={positionSideOutput} />
        </div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.PositionEntryPrice}-${symbol}`}>
          <FormatNumberDotWithTooltip value={entryPrice} symbol={symbol} qtyChars={5} />
        </div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.PositionAmount}-${symbol}`}>{positionAmt}</div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.PositionLiquidationPrice}-${symbol}`}>
          <FormatNumberDotWithTooltip value={liquidationPriceOutput} symbol={symbol} qtyChars={5} />
        </div>
      </div>

      {!isTigerXExchangeType ? <ADL indicator={adl ?? 0} smallSize /> : <div />}

      <div
        className={clsx(s.item, {
          [s.green]: unrealizedPnl.unPnlNumber > 0,
          [s.red]: unrealizedPnl.unPnlNumber < 0,
        })}
        data-testid={`${DataTestIds.PositionUnrealizedPnl}-${symbol}`}
      >
        {unrealizedPnl.unPnlNumberFormatted} ({unrealizedPnl.unPnlPercentFromatted})
      </div>

      <Button.Primary
        onClick={closePositionHandler}
        className={s.closeButton}
        leftIcon={<SVG.Additional.Close />}
        dataTestId={`${DataTestIds.ClosePositionButton}-${symbol}`}
      />
    </div>
  )
}

export { PositionTable }
