import {
  GetUsdMLeveragesTC,
  GetCoinMLeveragesTC,
  UpdateTickersTC,
  GetOkxAccountConfigurationTC,
  GetOkxLeverageForInstrumentTC,
  GetOkxLeverageEstimatedInfoTC,
  SetLeverageTC,
  SetMarginTypeTC,
  GetBybitMarketTickers,
  GetBybitAccountInfoTC,
  getBybitLeverageInfo,
  GetTigerXMarketTickers,
  GetTigerXTickerLeverage,
  GetTigerXTickerPositionTier,
} from './leverage.thunk'

export const leverageAsyncActions = {
  GetUsdMLeveragesTC,
  GetCoinMLeveragesTC,
  UpdateTickersTC,
  GetOkxAccountConfigurationTC,
  GetOkxLeverageForInstrumentTC,
  GetOkxLeverageEstimatedInfoTC,
  SetLeverageTC,
  SetMarginTypeTC,
  GetBybitMarketTickers,
  GetBybitAccountInfoTC,
  getBybitLeverageInfo,
  GetTigerXMarketTickers,
  GetTigerXTickerLeverage,
  GetTigerXTickerPositionTier,
}
