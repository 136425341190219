import { EAccountType, EPositionSide } from '@tigertrade/binance-ts'
import { useEffect, useMemo, useState } from 'react'
import {
  AccountOverviewPositionsView,
  OverviewOpenOrder,
  TCancelOpenOrderPayloadUnion,
  TCloseAllPositionsPayload,
  TOpenOrder,
} from '@/backend/models/OverviewDTO'
import { OVERVIEW_OKX_ORDER_SOURCE_MAP } from '@/core/constants/overview'
import { TAccountBalance, TAccountBalancesOutputView, TInstrumentType } from '@/core/types/overview'
import { OVERVIEW_ACCOUNT_SYMBOLS_MAP } from '@/redux/overview/overview.defaults'
import { overviewActions } from '@/redux/overview/overview.slice'
import { getAccountTypeForInstrument } from '@/redux/overview/overview.utils'
import { EBybitInstruments } from '@/services/bybit'
import { EOkxOrderType } from '@/services/okx'
import { accountNames, useActions, useAppSelector } from '@/utils'
import { useExchageType } from './useExchangeType'
import { truncateNumber } from '../lib/truncateNumber'
import { isExchangeBybit, isExchangeOkx, isExchangeTigerX } from '../lib/exchange'
import { TExchangesAvailable } from '@/core/constants'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { tigerxRestService } from '@/services/tigerx'
import { TIGER_X_POLLING_INTERVAL } from '@/providers/TigerXProvider/services/useWalletPageService'

const useOverview = () => {
  const {
    positions,
    balances,
    openOrders,
    instrumentType,
    accountTypeForInstrument,
    tabId,
    deletingPositions,
    deletingOrders,
    exchangeInstruments,
    exchangeAccounts,
    accountType,
    incomeStatistics,
  } = useAppSelector(state => state.overview)
  const { exchangeType } = useExchageType()

  const [tigerXCloseAllLoading, setTigerXCloseAllLoading] = useState(false)

  const {
    setInstrumentType,
    setTabId,
    CancelOverviewOpenOrderTC,
    CancelAllOverviewOpenOrdersTC,
    CloseOverviewPositionTC,
    CloseAllOverviewPositionsTC,
  } = useActions(overviewActions)
  const isTigerX = instrumentType === ETigerXInstruments.BINANCE_X || instrumentType === ETigerXInstruments.OKX_X
  const overviewByAccount = useMemo(() => {
    return positions[instrumentType || 'SPOT'] || {}
  }, [positions, instrumentType])

  const balanceByInstrument = useMemo<TAccountBalance>(() => {
    return accountTypeForInstrument ? balances[accountTypeForInstrument] : balances.BINANCE_SPOT
  }, [balances, accountTypeForInstrument])

  const balanceByAccount = useMemo<TAccountBalance>(() => {
    return accountType ? balances[accountType] : balances.BINANCE_SPOT
  }, [balances, accountType])

  const openOrdersByAccount = useMemo<OverviewOpenOrder[]>(() => {
    return openOrders[instrumentType || 'SPOT'].orders || []
  }, [openOrders, instrumentType])
  const filteredExchangeInstruments = isTigerX
    ? exchangeInstruments.filter(instrument => instrument === instrumentType)
    : exchangeInstruments

  const allOpenOrders = useMemo(() => {
    return filteredExchangeInstruments.reduce((orders, account) => {
      const ordersByAccount = openOrders[account].orders
      return [...orders, ...ordersByAccount]
    }, [] as TOpenOrder[])
  }, [openOrders])

  const allPositions = useMemo<AccountOverviewPositionsView[]>(() => {
    return filteredExchangeInstruments.reduce((positionsAccumulator, account) => {
      const positionsByAccount = positions[account].positions
      return [...positionsAccumulator, ...positionsByAccount]
    }, [] as AccountOverviewPositionsView[])
  }, [positions])

  const balancesArray = useMemo<TAccountBalancesOutputView[]>(
    () =>
      exchangeAccounts.map(variant => {
        return {
          id: accountNames[variant].id,
          label: accountNames[variant].name,
          amount: balances?.[variant]?.accountBalance,
          symbol: OVERVIEW_ACCOUNT_SYMBOLS_MAP[variant]?.symbol,
          total: balances?.[variant]?.assets?.length,
          ...(incomeStatistics[exchangeType as TExchangesAvailable][variant]?.dailyIncome === undefined
            ? {}
            : {
                profit: truncateNumber(
                  Number(incomeStatistics[exchangeType as TExchangesAvailable][variant]?.dailyIncome) || 0,
                  2
                ).toString(),
              }),
        }
      }),
    [positions, balances, incomeStatistics]
  )

  const shownOrdersOrPositionsLength = useMemo(() => {
    if (tabId === 1) return openOrdersByAccount.length
    return overviewByAccount.positions?.length || 0
  }, [tabId, openOrdersByAccount, overviewByAccount.positions])

  const isClosingOrders = useMemo(() => {
    return exchangeInstruments.reduce<boolean>((acc, account) => {
      if (acc) return acc
      return (
        Object.values(deletingOrders[account]).find(deletingEntity => {
          return deletingEntity.isDeleting
        }) !== undefined
      )
    }, false)
  }, [deletingOrders])

  const isClosingPositions = useMemo(() => {
    return exchangeInstruments.reduce<boolean>((acc, account) => {
      if (acc) return acc
      return (
        Object.values(deletingPositions[account]).find(deletingEntity => {
          return deletingEntity.isDeleting
        }) !== undefined
      )
    }, false)
  }, [deletingPositions])

  useEffect(() => {
    if ((instrumentType === 'SPOT' || instrumentType === 'spot') && tabId === 2) {
      setTabId(1)
    }
  }, [instrumentType, tabId])

  const cancelOrder = async (payload: TCancelOpenOrderPayloadUnion) => {
    await CancelOverviewOpenOrderTC({
      ...payload,
      symbol: isExchangeTigerX(exchangeType) ? String(payload.sourceSymbol) : payload.symbol,
    })
  }

  const cancelAllOrders = async (instrumentType?: TInstrumentType) => {
    const instruments = (instrumentType ? [instrumentType] : exchangeInstruments).filter(
      instr => !!openOrders[instr].orders.length
    )

    await Promise.all(
      instruments.map(instrument => {
        if (exchangeType === undefined) return
        const accountType = getAccountTypeForInstrument(instrument, exchangeType)

        const filteredOrders = isExchangeTigerX(exchangeType)
          ? openOrders[instrument].orders
          : openOrders[instrument].orders.filter(item => !item.isAlgo)

        if (accountType)
          return CancelAllOverviewOpenOrdersTC({
            accountType: accountType,
            instrumentType: instrument,
            orders: Array.from(
              new Set(
                filteredOrders.map(order => ({
                  symbol: isExchangeTigerX(exchangeType) ? String(order.sourceSymbol) : order.symbol,
                  orderId: order.orderId,
                  ...(isExchangeOkx(exchangeType) && Object.values(EOkxOrderType).includes(order.type as EOkxOrderType)
                    ? {
                        orderSourceType: OVERVIEW_OKX_ORDER_SOURCE_MAP[order.type as EOkxOrderType],
                      }
                    : {}),
                  ...(isExchangeBybit(exchangeType) && order.instrumentType === EBybitInstruments.spot
                    ? {
                        orderFilter: order.stopOrderType,
                      }
                    : {}),
                }))
              )
            ),
          })
      })
    )

    if (isExchangeTigerX(exchangeType)) {
      instruments.map(instrument => {
        if (exchangeType === undefined) return
        cancelAllAlgoOrdersTigerX(instrument)
      })
    }
  }

  const cancelPosition = async (position: AccountOverviewPositionsView) => {
    if (!exchangeType) return

    const accountType = getAccountTypeForInstrument(position.instrumentType, exchangeType)
    if (!accountType) return

    await CloseOverviewPositionTC({
      accountType: accountType,
      instrumentType: position.instrumentType,
      symbol: isExchangeTigerX(exchangeType) ? String(position.sourceSymbol) : position.symbol,
      positionId: position.uid,
      positionAmt: Number(position.positionAmt),
      positionSide: isExchangeTigerX(exchangeType)
        ? (position.sourcePositionSide as EPositionSide)
        : position.positionSide,
      marginType: position.marginType,
      ccy: position.ccy,
      positionIdx: position.positionIdx,
    })
  }

  const cancelAllPositions = async (instrumentType?: TInstrumentType) => {
    if (exchangeType === undefined) return

    const instruments = (instrumentType ? [instrumentType] : exchangeInstruments).filter(instrument => {
      return instrument !== EAccountType.SPOT && instrument !== EBybitInstruments.spot
    })

    await Promise.all(
      instruments
        .reduce<TCloseAllPositionsPayload[]>((acc, instrument) => {
          const accountType = getAccountTypeForInstrument(instrument, exchangeType)

          if (accountType && positions[instrument].positions.length > 0)
            return [
              ...acc,
              {
                accountType: accountType,
                instrumentType: instrument,
                positions: positions[instrument].positions.map(p => ({
                  symbol: isExchangeTigerX(exchangeType) ? String(p.sourceSymbol) : p.symbol,
                  positionId: p.uid,
                  positionAmt: Number(p.positionAmt),
                  positionSide: isExchangeTigerX(exchangeType)
                    ? (p.sourcePositionSide as EPositionSide)
                    : p.positionSide,
                  marginType: p.marginType,
                  ccy: p.ccy,
                  positionIdx: p.positionIdx,
                })),
              },
            ]

          return acc
        }, [])
        .map(CloseAllOverviewPositionsTC)
    )
  }

  const cancelAllAlgoOrdersTigerX = async (instrumentType: TInstrumentType) => {
    const filteredOrders = openOrders[instrumentType].orders
      .filter(order => order.isAlgo)
      .map(order => {
        return {
          ...order,
          accountType: getAccountTypeForInstrument(instrumentType, exchangeType),
        }
      })

    for (const order of filteredOrders) {
      await cancelOrder(order as any)
    }
  }

  const cancelAllOrdersAndPositions = async () => {
    if (isExchangeTigerX(exchangeType)) {
      cancelAllOrderAndPositionsTigerX()
      return
    }

    await cancelAllOrders()
    await cancelAllPositions()
  }

  const cancelAllOrderAndPositionsTigerX = async () => {
    setTigerXCloseAllLoading(true)
    let numberOfAttemps = 0

    await cancelAllOrders(instrumentType)

    const interval = setInterval(async () => {
      numberOfAttemps++

      const orders = await tigerxRestService.getOrdersRecursive({ page: 1 }, instrumentType as any)
      const algoOrders = await tigerxRestService.getAlgoOrdersRecursive({ page: 1 }, instrumentType as any)

      const positions = await tigerxRestService.getPositionsRecursive({})

      const filteredPositions =
        positions?.filter(position => position.sym.includes(String(instrumentType).split('_')[0])) || []

      if (!orders.length && !algoOrders.length && filteredPositions.length) {
        await cancelAllPositions(instrumentType)
        setTigerXCloseAllLoading(false)
        clearInterval(interval)
        return
      }

      if (numberOfAttemps > 3) {
        setTigerXCloseAllLoading(false)
        clearInterval(interval)
      }
    }, TIGER_X_POLLING_INTERVAL)
  }

  return {
    tabId,
    accountType,
    instrumentType,
    accountTypeForInstrument,
    overviewByAccount,
    balanceByInstrument,
    balanceByAccount,
    openOrdersByAccount,
    allOpenOrders,
    allPositions,
    balancesArray,
    shownOrdersOrPositionsLength,
    isClosingOrders,
    isClosingPositions,
    setTabId,
    positions,
    setInstrumentType,
    cancelOrder,
    cancelAllOrders,
    cancelPosition,
    cancelAllPositions,
    cancelAllOrdersAndPositions,
    cancelAllOrderAndPositionsTigerX,
    tigerXCloseAllLoading,
  }
}

export { useOverview }
