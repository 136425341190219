import clsx from 'clsx'
import { FC, memo } from 'react'
import { floorNumber, formatNumber } from '@/utils'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { OVERVIEW_ACCOUNT_SYMBOLS_MAP } from '@/redux/overview/overview.defaults'

export type AssetsAmountProps = {
  label: string
  amount: string
  symbol: string
  total: number
  active: boolean
  onClick: () => void
}

const AssetsAmount: FC<AssetsAmountProps> = props => {
  const { label, amount, symbol, total, active, onClick } = props

  return (
    <div className={style.itemWrap} onClick={onClick} data-testid={`${DataTestIds.AssetLeftItem}-${label}`}>
      <div className={style.left}>
        <span className={style.label}>{label}</span>
        <span className={style.total} data-testid={`${DataTestIds.AssetLeftItemCount}-${label}`}>
          {total}
        </span>
      </div>
      <div
        className={clsx(style.amount, {
          [style.active]: active,
        })}
        data-testid={`${DataTestIds.AssetLeftItemAmount}-${label}`}
      >
        {symbol === OVERVIEW_ACCOUNT_SYMBOLS_MAP.BINANCE_FUTURE_COIN.symbol
          ? formatNumber(amount)
          : floorNumber(amount)}{' '}
        {symbol}
      </div>
    </div>
  )
}

export default memo(AssetsAmount)
